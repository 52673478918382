import { uniqueArray } from 'utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { archivedLeadsSaga } from './saga';
import { ArchivedLeadsState } from './types';

export const initialState: ArchivedLeadsState = {
  isLoading: false,
  archivedLeads: [],
  error: '',
  meta: {
    total_count: 1,
    total_pages: 10,
  },
  pageNo: 1,
  per_page: 500,
  sortParams: {
    field: '',
    order: '',
    filters: 'completed',
  },
};

const slice = createSlice({
  name: 'archivedLeads',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    fetchArchivedLeadsSuccess(state, action: PayloadAction<any>) {
      const { archivedLeads, pageNo, filters, is_filter } = action.payload;
      const data = archivedLeads.data.map((lead, index) => {
        return {
          id: `${lead.id}`,
          full_name: `${lead.attributes.full_name}`,
          last_name: `${lead.attributes.last_name}`,
          leads_generate_date: lead.attributes.leads_generate_date,
          created_at: lead.attributes.created_at,
          email: lead.attributes.email,
          phone: lead.attributes.phone ?? '---',
          category: lead.attributes.category ?? '---',
          status: lead.attributes.status ?? '---',
          revenue: lead.attributes.revenue ?? '---',
          country: lead.attributes?.country,
        };
      });
      state.pageNo = pageNo;

      if (is_filter) {
        state.sortParams.filters = filters;
      }
      if (archivedLeads.meta?.total_count > state.per_page) {
        state.archivedLeads = uniqueArray([...state.archivedLeads, ...data]);
      } else {
        state.archivedLeads = data;
      }
      state.meta = archivedLeads.meta;
      state.isLoading = false;
    },
    fetchArchivedLeadsFailed(state) {
      state.archivedLeads = [];
      state.isLoading = false;
    },
    clearArchivedLeads(state) {
      state.archivedLeads = [];
      state.isLoading = false;
    },
    setSortParams(state, action: PayloadAction<any>) {
      const { order, field, filters } = action.payload;
      state.sortParams.field = field;
      state.sortParams.order = order;
      state.sortParams.filters = filters;
    },
  },
});

export const { actions: archivedLeadsActions } = slice;

export const useArchivedLeadsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: archivedLeadsSaga });
  return { actions: slice.actions };
};
