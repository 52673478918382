import { reportsSaga } from './saga';
import { ReportsState } from './types';
import { endOfDay } from 'date-fns';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

export const initialState: ReportsState = {
  isLoading: false,
  exportLoading: false,
  graphData: [],
  totalStatsData: {
    id: '',
    total_new_leads: 0,
    booked_leads: 0,
    total_dead_leads: 0,
    total_completed_leads: 0,
    lead_revenue: 0,
    total_leads: 0,
    conversion_percentage: 0,
  },
  filter: 'day',
  dateRange: {
    date: [
      {
        startDate: endOfDay(new Date().setDate(new Date().getDate() - 29)),
        endDate: endOfDay(new Date()),
        key: 'selection',
      },
    ],
  },
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<any>) {
      if (action.payload.type === 'graph') {
        state.isLoading = action.payload.loading;
      } else {
        state.exportLoading = action.payload.loading;
      }
    },
    fetchGraphSuccess(state, action: PayloadAction<any>) {
      state.totalStatsData = action?.payload?.data?.total_stats;
      const data = action?.payload?.data?.other_stats.map((item, index) => {
        return {
          not_booked: item?.total_leads - item?.booked_leads,
          ...item,
        };
      });
      state.graphData = data;
      state.filter = action?.payload?.filter;
      state.isLoading = false;
      state.dateRange = action?.payload.dateRange;
    },
    fetchGraphFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.graphData = [];
      state.totalStatsData = {
        id: '',
        total_new_leads: 0,
        booked_leads: 0,
        total_dead_leads: 0,
        total_completed_leads: 0,
        lead_revenue: 0,
        total_leads: 0,
        conversion_percentage: 0,
      };
      state.filter = action?.payload;
    },
    setDateRange(state, action: PayloadAction<any>) {
      state.dateRange = action.payload;
    },
  },
});

export const { actions: reportsActions } = slice;

export const useReportsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: reportsSaga });
  return { actions: slice.actions };
};
