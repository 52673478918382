/**
 *
 * BackButton
 *
 */
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  isImpersonate: boolean;
  userId: string;
  path: string;
}

export const BackButton = memo(({ isImpersonate, userId, path }: Props) => {
  if (window.location.pathname.includes('archived')) {
    if (isImpersonate) {
      return (
        <NavLink to={`/impersonate-archived-leads/${userId}`}>
          (back to archived)
        </NavLink>
      );
    } else {
      return <NavLink to={'/archived-leads/'}>(back to archived)</NavLink>;
    }
  } else {
    if (isImpersonate) {
      return <NavLink to={path}>(back to dashboard)</NavLink>;
    } else {
      return <NavLink to={'/'}>(back to dashboard)</NavLink>;
    }
  }
});
