//Custom Hook for applying deboucing
import { useEffect, useState } from 'react';
export const useDebounce = (value: string, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<string>(value);
  useEffect(
    () => {
      if (value !== debouncedValue) {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      }
    },
    [value, delay, debouncedValue], // Only re-call effect if value or delay or debouncedValue changes
  );
  return debouncedValue;
};
