import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { subscriptionHistorySaga } from './saga';
import { SubscriptionHistoryState } from './types';

const currentCardInitialState = {
  card_brand: '',
  country: '',
  exp_month: 0,
  exp_year: 0,
  last4: '',
};

const subscriptionHistoryInitialValues = {
  card_brand: '',
  charged_amount: 0,
  created_at: '',
  discounted_amount: 0,
  exp_month: 0,
  exp_year: 0,
  id: '',
  last4: '',
  name: '',
  pm_id: '',
  service_period: '',
  subscription_type: '',
  total_amount: 0,
  plan_description: '',
  to_date: '',
  status: '',
  expires_at: '',
  impersonated_user: ''
};
export const initialState: SubscriptionHistoryState = {
  isLoading: false,
  subscriptionHistory: [subscriptionHistoryInitialValues],
  currentCard: currentCardInitialState,
  error: '',
  meta: {
    total_pages: 0,
    total_count: 0,
  },
  pageNo: 1,
  plan_amount: 0,
  userSubscriptions: '',
  id: '',
  status: '',
  expires_at: '',
  starts_at: '',
  impersonated_user: '',
  attributes: {}
};

const slice = createSlice({
  name: 'subscriptionHistory',
  initialState,
  reducers: {
    setLoadingTrue(state) {
      state.isLoading = true;
    },
    setLoadingFalse(state) {
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = '';
    },
    setSubscriptionHistory(state, action: PayloadAction<any>) {
      state.subscriptionHistory = action.payload;
    },
    setSubscriptionHistoryMeta(state, action: PayloadAction<any>) {
      state.meta = action.payload;
    },
    setCurrentCard(state, action: PayloadAction<any>) {
      state.currentCard = action.payload;
    },
    clearCurrentCard(state) {
      state.currentCard = currentCardInitialState;
    },
    pageNo(state, action: PayloadAction<any>) {
      state.pageNo = action.payload;
    },
    clearSubscriptionHistory(state) {
      state.subscriptionHistory = [subscriptionHistoryInitialValues];
    },
    setPlan(state, action: PayloadAction<any>) {
      state.plan_amount = action.payload;
    },
    setUserSubscriptions(state, action: PayloadAction<any>) {
      state.userSubscriptions = action.payload;
    },
    setImpersonatedUser(state, action: PayloadAction<any>) {
      state.impersonated_user = action.payload;
    },
  },
});

export const { actions: subscriptionHistoryActions } = slice;

export const useSubscriptionHistorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: subscriptionHistorySaga });
  return { actions: slice.actions };
};
