import { handleError } from 'utils';
import { getAuth } from 'utils/Auth';
import { getReq, postReq } from 'config/Requests';
import { reportsActions as actions } from '.';
import { reportsConstants } from './constants';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { selectisImpersonate, selectuserId } from 'app/slice/selectors';
import {
  API_DATE_RANGE_FORMAT,
  LEAD_STATUSES,
} from 'utils/constants/constants';
import { format } from 'date-fns';
import { downloadCSV } from 'utils/DownloadCsv';
function* fetchGraphData(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { filter, dateRange } = obj?.payload;
  yield put(actions.setLoading({ type: 'graph', loading: true }));
  try {
    const response = yield call(
      getReq,
      `/stats/user_stats?filter_by=${filter}&start_date=${format(
        dateRange.date[0]?.startDate,
        API_DATE_RANGE_FORMAT,
      )}&end_date=${format(dateRange.date[0]?.endDate, API_DATE_RANGE_FORMAT)}`,
      auth,
    );
    yield put(
      actions.fetchGraphSuccess({
        data: response?.data?.data,
        filter,
        dateRange: dateRange,
      }),
    );
  } catch (error) {
    yield put(actions.fetchGraphFailed(filter));
    handleError(error);
  }
}
function* fetchExportLeads(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { setIsExport, export_all, dateRange, status_list } = obj?.payload;
  yield put(actions.setLoading({ type: 'export', loading: true }));
  try {
    let response: any = '';
    let params: {
      statuses: any;
      date_to: string | null;
      date_from: string | null;
      export_all: boolean;
    } = {
      statuses: [],
      date_from: null,
      date_to: null,
      export_all: export_all,
    };
    if (status_list.length > 0) {
      status_list.forEach(element => {
        params?.statuses.push(
          LEAD_STATUSES.find(item => item.name === element)?.id,
        );
      });
    }
    if (export_all) {
      params.date_from = null;
      params.date_to = null;
      response = yield call(postReq, `/leads/export`, params, auth);
    } else {
      params.date_from = dateRange.length > 0 && dateRange[0]?.startDate;
      params.date_to = dateRange.length > 0 && dateRange[0]?.endDate;
      response = yield call(postReq, `/leads/export`, params, auth);
    }
    downloadCSV(response?.data);
    yield put(actions.setLoading({ type: 'export', loading: false }));
    setIsExport(false);
  } catch (error) {
    yield put(actions.setLoading({ type: 'export', loading: false }));
    handleError(error);
  }
}
export function* reportsSaga() {
  yield takeLatest(reportsConstants.FETCH_GRAPH_DATA, fetchGraphData);
  yield takeLatest(reportsConstants.FETCH_EXPORT_LEAD, fetchExportLeads);
}
