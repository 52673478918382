import React from 'react';
import { useAppSlice } from 'app/slice';
import { Route } from 'react-router-dom';
import { ImPersonateRoute } from './ImpersonationRoute';

export const ImPersonateProtected = ({ component: Component, ...rest }) => {
  useAppSlice();
  return (
    <Route
      {...rest}
      render={props => {
        return <ImPersonateRoute component={Component} />;
      }}
    />
  );
};
