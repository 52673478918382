/**
 *
 * ConfirmationGreeting
 *
 */
import * as React from 'react';
import Logo from 'images/logo.png';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
interface Props {}

export function ConfirmationGreeting(props: Props) {
  return (
    <div style={{ height: '100%' }}>
      <div className="row landing-wrapper">
        <div className="col-4 col-md-4 col-lg-3 col-xl-4 col-xxl-4"></div>
        <div className="col-4 col-md-4 col-lg-6 col-xl-4 col-xxl-4 landing-msg">
          {' '}
          <div className="brand-logo">
            <div>
              <img src={Logo} alt="" />
            </div>
          </div>
          <div>
            <p className="text-center">
              Thank you for verifying your email address! We’ll review and
              activate your account soon and be in touch with further
              instructions.
            </p>
            <div className="col-12">
              <div className="d-flex justify-content-center mt-xxl-4  mt-4 xl-margin">
                <div className="custom-green-btn px-5">
                  <NavLink
                    to={'/sign-in'}
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                    }}
                  >
                    Head to Mission Control
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 col-md-4 col-lg-3  col-xl-4 col-xxl-4"></div>
      </div>
    </div>
  );
}

const Div = styled.div``;
