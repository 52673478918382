/**
 *
 * NoteForm
 *
 */
import './NoteForm.scss';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import React, { memo, SetStateAction } from 'react';
import { Input, Form, Button, message } from 'antd';
import { leadDetailConstants } from 'app/pages/LeadsDetailsPage/slice/constants';

const NoteForm = memo((props: Props) => {
  const { notes, setNotes, setShowModal, leadStatus, addNote } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = event => {
    setShowModal(false);
    if (setNotes) {
      if (leadStatus) {
        let tempArr: NoteType = [...notes];
        let filterarr: NoteType = [];
        tempArr.forEach(
          (item: { revenue: string; notes: string; leadStatus: string }) => {
            if (item?.leadStatus === leadStatus) {
              filterarr.push(item);
            }
            if (!item?.leadStatus) {
              filterarr.push(item);
            }
          },
        );
        setNotes([
          ...filterarr,
          {
            revenue: null,
            notes: event?.notes,
            leadStatus,
          },
        ]);
      } else {
        setNotes([
          ...notes,
          {
            revenue: null,
            notes: event?.notes,
            leadStatus,
          },
        ]);
      }
    }

    if (props.onSubmit) props.onSubmit(event.notes);
    else {
      const attrs = {
        lead_revenue: {
          lead_id: props.leadId,
          notes: event.notes,
          revenue: event.lead_revenue ?? null,
        },
      };
      if (props.leadId) {
        dispatch({
          type: leadDetailConstants.ADD_LEAD_NOTES,
          payload: attrs,
          callback: response => {
            if (response.status === 200) {
              setShowModal(false);
              dispatch({
                type: leadDetailConstants.FETCH_LEAD_NOTES,
                payload: props.leadId,
              });
              message.success('Note/Revenue Added successfully');
              if (props.addRevenue) {
                dispatch({
                  type: leadDetailConstants.FETCH_LEAD,
                  payload: props.leadId,
                  callback: () => {},
                });
              }
            }
          },
        });
        form.setFieldsValue({
          notes: '',
          lead_revenue: '',
        });
      }
    }
  };
  form.setFieldsValue({
    note_title: '',
    notes: '',
    lead_revenue: null,
  });
  let noteTitle = '';
  let noteClassName = 'custom-form-input-wrap2 custom-required-after';
  let rules = {
    required: true,
    message: 'Note is required',
  };
  if (!addNote) {
    noteTitle = '(optional)';
    noteClassName = 'custom-form-input-wrap2';
    rules = {
      required: false,
      message: '',
    };
  }
  return (
    <Div>
      <Form form={form} onFinish={onFinish}>
        <div className="row">
          <div className="col-md-12">
            {props.addRevenue && (
              <div className="col-md-12">
                <div className="custom-form-input-wrap2 custom-required-after">
                  <label htmlFor="">Revenue</label>
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      name="lead_revenue"
                      rules={[
                        {
                          required: true,
                          message: 'Lead revenue is required',
                        },
                        {
                          pattern: new RegExp(
                            '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$',
                          ),
                          message: 'Lead revenue should be in numbers',
                        },
                      ]}
                    >
                      <Input type={'text'} placeholder={'Lead Revenue'} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12">
              <div className={noteClassName}>
                <label htmlFor="">Note{noteTitle}</label>
              </div>
            </div>
            <div className="custom-form-input-wrap2">
              <Form.Item name="notes" rules={[rules]}>
                <textarea
                  className="note-text-area"
                  placeholder="Enter note here"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end mt-5">
              <Button
                onClick={e => {
                  e.preventDefault();
                  setShowModal(false);
                  if (props.handleCancel) props.handleCancel();
                }}
                htmlType="submit"
                className="custom-white-btn me-4"
              >
                Cancel
              </Button>
              <Button htmlType="submit" className="custom-green-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Div>
  );
});

const Div = styled.div``;

interface Props {
  addRevenue: boolean;
  setShowModal: (boolean) => void;
  leadId?: string;
  onSubmit?: (note) => void;
  handleCancel?: () => void;
  setNotes?: SetStateAction<any>;
  notes?: any;
  leadStatus?: string;
  addNote?: boolean;
}

export default NoteForm;
type NoteType = {
  revenue: string;
  notes: string;
  leadStatus: string;
}[];
