/**
 *
 * Reports
 *
 */
import { Button, Grid, Spin } from 'antd';
import { CustomModal } from 'app/components/CustomModal';
import { selectSignOutLoading } from 'app/pages/SignIn/slice/selectors';
import { selectisImpersonate } from 'app/slice/selectors';
import React, { memo, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from 'utils';
import Chart from './chart';
import { reportsActions, useReportsSlice } from './slice';
import { reportsConstants } from './slice/constants';
import {
  selectDateRange,
  selectfilter,
  selectgraphData,
  selectisLoading,
  selecttotalData,
} from './slice/selectors';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { customDefaultRanges, renderStaticLabel } from './helper/helper';
import { DateRangeType, toalStatsTypes } from './slice/types';
import { removeDecimal } from 'utils/StringUtils/stringUtils';
import Export from 'app/components/Export';
interface Props {}
const { useBreakpoint } = Grid;
export const Reports = memo((props: Props) => {
  const filter: string = useSelector(selectfilter);
  const isLoading: boolean = useSelector(selectisLoading);
  const signoutLoading: boolean = useSelector(selectSignOutLoading);
  const graphData: [] = useSelector(selectgraphData);
  const totalStatsData: toalStatsTypes = useSelector(selecttotalData);
  const isImpersonate: boolean = useSelector(selectisImpersonate);
  const storedDateRange: DateRangeType = useSelector(selectDateRange);
  const [dateRange, setDateRange] = useState<DateRangeType>(storedDateRange);
  const [open, setOpen] = useState<boolean>(false);

  const { lg } = useBreakpoint();
  const dispatch = useDispatch();
  useReportsSlice();
  useEffect(() => {
    dispatch({
      type: reportsConstants.FETCH_GRAPH_DATA,
      payload: { filter, dateRange },
    });
    // eslint-disable-next-line
  }, [dispatch, isImpersonate, storedDateRange]);
  let countClass = 'custom-count';
  if (lg) {
    countClass = `${countClass}-lg`;
  }
  const handleSelect = ranges => {
    setDateRange({
      date: [
        {
          startDate: new Date(ranges?.selection?.startDate),
          endDate: new Date(ranges?.selection?.endDate),
          key: 'selection',
        },
      ],
    });
  };

  const handleClosePicker = () => {
    dispatch(reportsActions.setDateRange(dateRange));
    setOpen(false);
  };
  return (
    <>
      <Spin
        tip={signoutLoading ? 'Signing Out...' : 'Fetching Graph Data'}
        size="large"
        spinning={isLoading || signoutLoading}
      >
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10 d-flex justify-content-between ">
            <div className="text-center custom-card">
              <div>Leads Received</div>
              <h2 className={countClass}>
                {removeDecimal(totalStatsData?.total_leads ?? '0')}
              </h2>
            </div>
            <div className="text-center custom-card">
              <div>Booked</div>
              <h2 className={countClass}>
                {' '}
                {removeDecimal(totalStatsData?.booked_leads ?? '0')}
              </h2>
            </div>
            <div className="text-center custom-card">
              <div>Conversion</div>
              <h2
                className={countClass}
              >{`${totalStatsData?.conversion_percentage}%`}</h2>
            </div>
            <div className="text-center custom-card">
              <div>Total Revenue</div>
              <h2 className={countClass}>
                {' '}
                {formatCurrency(totalStatsData?.lead_revenue ?? 0, {
                  precision: 2,
                  symbol: '$',
                })}
              </h2>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10   mt-3">
            <div className="row">
              <div className="col-md-6 col-xl-4 col-lg-2 col-md-1"></div>
              <div className="col-md-6  col-xl-8 col-lg-10 col-md-12 d-flex justify-content-between">
                <div className="date-range-text" onClick={() => setOpen(true)}>
                  <b>Date range - {renderStaticLabel(dateRange)}</b>
                </div>
                <span className="date-range-wrapper">
                  <CustomModal
                    title=""
                    isVisible={open}
                    handleCancel={handleClosePicker}
                    width="600px"
                  >
                    <div className="d-flex date-range-picker">
                      <DateRangePicker
                        ranges={dateRange?.date}
                        onChange={handleSelect}
                        staticRanges={customDefaultRanges}
                        inputRanges={[]}
                        editableDateInputs={true}
                        rangeColors={['rgb(44 161 90)']}
                      />
                      <Button
                        className="picker-btn"
                        onClick={handleClosePicker}
                      >
                        Apply
                      </Button>
                    </div>
                  </CustomModal>
                  <Export
                    title="Export Lead Database"
                    okLabel="Export Leads"
                    cancelLabel="Cancel"
                    storedDateRange={storedDateRange}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>

        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 chart-height">
            <Chart
              noDataChart={graphData.length === 0}
              data={graphData.length > 0 ? graphData : []}
              filter={filter}
            />
          </div>
          <div className="col-1"></div>
        </div>
      </Spin>
    </>
  );
});
