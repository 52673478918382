/**
 *
 * ResetPassword
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import Logo from '../../../../src/images/logo.png';
import { Form, Input, message } from 'antd';
import './ResetPassword.scss';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { resetPasswordConstants } from './slice/constants';
import { useResetPasswordSlice } from './slice';
import { useHistory, useParams } from 'react-router-dom';

interface Props {
  location: {
    search: string;
  };
}

export const ResetPassword = memo((props: Props) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  useResetPasswordSlice();
  const { reset_password_token }: { reset_password_token: string } =
    useParams();
  const onFinish = event => {
    const body = {
      user: {
        password: event.new_password,
        password_confirmation: event.repeated_password,
        reset_password_token: reset_password_token,
      },
    };
    dispatch({
      type: resetPasswordConstants.RESET_PASSWORD,
      payload: body,
      callback: response => {
        history.push('/sign-in');
        message.success(response?.data?.meta?.message);
      },
    });
  };
  return (
    <Div className="sign-in-wrapper">
      <div className="register-form">
        <div className="row w-100">
          <div className="col-md-6">
            <div className="reset-form">
              <div className="add-lead-table-wrap">
                <div>
                  <h1 className="main-heading">CHANGE PASSWORD</h1>
                </div>
                <div className="row">
                  <Form form={form} onFinish={onFinish}>
                    <div className="col-12">
                      <div className="custom-form-input-wrap">
                        <label htmlFor="">NEW PASSWORD</label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'New Password is required',
                            },
                          ]}
                          name={'new_password'}
                          hasFeedback
                        >
                          <Input.Password placeholder={'Enter password'} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="custom-form-input-wrap">
                        <label htmlFor="">CONFIRM PASSWORD</label>
                        <Form.Item
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Password is required',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('new_password') === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error(
                                    'The two passwords that you entered do not match!',
                                  ),
                                );
                              },
                            }),
                          ]}
                          name={'repeated_password'}
                          dependencies={['new_password']}
                        >
                          <Input.Password
                            type={'password'}
                            placeholder={'Re-enter password'}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex justify-content-center">
                        <button type="submit" className="custom-green-btn px-5">
                          Update
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div className="right-box">
              <div className="brand-logo">
                <div>
                  <img src={Logo} alt="" />
                </div>
              </div>
              <div className="logo-details">
                <p>
                  LiftOff Leads & SEO is an internet marketing company that will
                  serve professional photographers who utilize the business
                  practices taught by Megan DiPiero Coaching.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div>
  );
});

const Div = styled.div``;
