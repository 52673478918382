import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { isLogin } from 'utils/Auth/index';
import Logout from 'app/components/Logout';
import { selectpath } from 'app/slice/selectors';
import { Redirect, Route } from 'react-router-dom';
import { selectIsLoading } from 'app/pages/SignIn/slice/selectors';
export const PublicRoute = ({ component: Component, ...rest }) => {
  const path = useSelector(selectpath);
  const isLoading = useSelector(selectIsLoading);
  return (
    <Spin size="large" tip="Loading..." spinning={isLoading}>
      <Route
        {...rest}
        render={props => {
          if (path) return <Logout />;
          if (isLogin(rest?.path) === 'loggedIn') return <Redirect to="/" />;
          else return <Component {...props} />;
        }}
      />
    </Spin>
  );
};
