/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import 'antd/dist/antd.min.css';
import Profile from './pages/Profile';
import { useAppSlice } from 'app/slice';
import { SignIn } from './pages/SignIn';
import { Reports } from './pages/Reports';
import 'bootstrap/dist/css/bootstrap.min.css';
import { loadStripe } from '@stripe/stripe-js';
import { SignUp } from './pages/SignUp/Loadable';
import { Elements } from '@stripe/react-stripe-js';
import { GlobalStyle } from 'styles/global-styles';
import { HomePage } from './pages/HomePage/Loadable';
import { ArchivedLeads } from './pages/ArchivedLeads';
import { ResetPassword } from './pages/ResetPassword';
import { AddLeadForm } from './components/AddLeadForm';
import { PaymentForm } from './components/PaymentForm';
import { ForgotPassword } from './pages/ForgotPassword';
import { PublicRoute } from './components/HOC/PublicRoute';
import { LeadsDetailsPage } from './pages/LeadsDetailsPage';
import { VerificationPage } from './pages/VerificationPage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from './components/HOC/ProtectedRoute';
import { SubscriptionPlan } from './components/SubscriptionPlan';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { SubscriptionHistory } from './components/SubscriptionHistory';
import { ImPersonateProtected } from './components/HOC/ProtectedRoute/ImPersonate';
import { LandingPage } from './components/LandingPage';
import { ConfirmationGreeting } from './pages/ConfirmationGreeting'
declare global {
  interface Window {
    __config__: any;
  }
}
export function App() {
  const stripePromise = loadStripe(window.__config__.STRIPE_KEY);
  useAppSlice();
  return (
    <>
      {' '}
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Switch>
            <ImPersonateProtected
              exact
              path="/impersonate-home/:userId/:adminAuth"
              component={HomePage}
            />
            <ImPersonateProtected
              exact
              path="/impersonate/user-profile/:userId"
              component={Profile}
            />
            <ImPersonateProtected
              exact
              path="/impersonate-lead-detail/:id/:userId"
              component={LeadsDetailsPage}
            />
            <ImPersonateProtected
              exact
              path="/impersonate-lead-detail-archived/:id/:userId"
              component={LeadsDetailsPage}
            />
            <ImPersonateProtected
              exact
              path="/impersonate-archived-leads/:userId"
              component={ArchivedLeads}
            />
            <ImPersonateProtected
              exact
              path="/impersonate-add-lead/:userId"
              component={AddLeadForm}
            />
            <ImPersonateProtected
              exact
              path="/subscription-history/:userId"
              component={SubscriptionHistory}
            />
            <ImPersonateProtected
              exact
              path="/impersonate-reports/:userId"
              component={Reports}
            />
            <ProtectedRoute
              exact
              path="/lead-detail/:id"
              component={LeadsDetailsPage}
            />
            <ProtectedRoute
              exact
              path="/lead-detail-archived/:id"
              component={LeadsDetailsPage}
            />
            <ProtectedRoute
              exact
              path="/user-profile/:userId"
              component={Profile}
            />
            <ProtectedRoute exact path="/" component={HomePage} />
            <ProtectedRoute
              exact
              path="/archived-leads"
              component={ArchivedLeads}
            />
            <ProtectedRoute exact path="/add-lead" component={AddLeadForm} />
            <ProtectedRoute
              exact
              path="/subscription-history"
              component={SubscriptionHistory}
            />
            <ProtectedRoute
              exact
              path="/subscription-plan"
              component={SubscriptionPlan}
            />
            <ProtectedRoute
              exact
              path="/new-payment-method"
              component={PaymentForm}
            />
            <ProtectedRoute exact path="/reports" component={Reports} />

            <PublicRoute exact path="/sign-up" component={SignUp} />
            <PublicRoute exact path="/sign-in" component={SignIn} />
            <PublicRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <PublicRoute
              path="/forgot-password/:msg"
              component={ForgotPassword}
            />
            <PublicRoute
              path="/confirmation-greetings"
              component={ConfirmationGreeting}
            />
            <PublicRoute
              exact
              path="/reset-password/:reset_password_token"
              component={ResetPassword}
            />
            <PublicRoute
              exact
              path="/reset-password"
              component={ResetPassword}
            />
            <PublicRoute
              exact
              path="/verification"
              component={VerificationPage}
            />
            <PublicRoute exact path="/landing-page" component={LandingPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </Elements>
      <GlobalStyle />
    </>
  );
}
