import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { homePageSaga } from './saga';
import { HomePageState } from './types';
import _ from 'lodash';
import moment from 'moment';

export const initialState: HomePageState = {
  isLoading: false,
  leads: [],
  error: '',
  meta: {
    total_count: 1,
    total_pages: 10,
  },
  pageNo: 1,
  per_page: 500,
  user_categories: {
    stats: [{}],
    platform: '',
    market: '',
    months: [],
  },
  sortParams: { field: '', order: '', filters: null, categoryFilters: null },
  contactTimings: [],
};
const handleStatusColor1 = (record, contactTimings) => {
  if (contactTimings !== null) {
    if (contactTimings.length <= 0) return;
    const selectedContactTiming = contactTimings?.find(
      contactTiming => contactTiming?.status === record?.status,
    );
    if (!selectedContactTiming) return;
    if (record?.status === 'booked') return 'ant-tag-geekblue';
    if (record?.status === 'dead') return 'ant-tag-red';

    const currentDate = moment();
    const contactTimingHours = selectedContactTiming?.no_of_days * 24;

    let hours;
    if (record?.future_contact_date != null) {
      hours = moment
        .duration(currentDate.diff(moment(record?.future_contact_date)))
        .asHours();
      return hours <= 0
        ? record?.status === 'new_lead'
          ? 'ant-tag-green'
          : 'ant-tag-light-green'
        : hours < contactTimingHours
        ? 'ant-tag-yellow'
        : 'ant-tag-red';
    } else {
      hours = moment
        .duration(currentDate.diff(moment(record?.lead_status_date)))
        .asHours();
      return hours < contactTimingHours
        ? record?.status === 'new_lead'
          ? 'ant-tag-green'
          : 'ant-tag-light-green'
        : hours < contactTimingHours * 2
        ? 'ant-tag-yellow'
        : 'ant-tag-red';
    }
  }
};

const slice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    fetchLeadsSuccess(state, action: PayloadAction<any>) {
      const {
        leads,
        pageNo,
        is_search,
        field,
        order,
        is_status_changed,
        is_revenue_changed,
        contact_timings_response,
      } = action.payload;
      state.isLoading = false;
      const normalized = contact_timings_response.map(
        (contactTiming, index) => {
          return {
            id: `${contactTiming?.attributes?.id}`,
            status: `${contactTiming?.attributes?.status}`,
            no_of_days: contactTiming?.attributes?.no_of_days,
            created_at: `${contactTiming?.attributes?.created_at}`,
            position: contactTiming?.attributes?.position,
          };
        },
      );
      const data = leads.data.map((lead, index) => {
        const record = {
          id: `${lead.id}`,
          full_name: lead?.attributes?.full_name,
          last_name: lead?.attributes?.last_name,
          email: `${lead.attributes?.email}`,
          phone: `${lead.attributes.phone ?? '---'}`,
          category: `${lead.attributes.category ?? '---'}`,
          status: `${lead.attributes.status ?? '---'}`,
          notes: `${lead.attributes.notes ?? '---'}`,
          leads_generate_date: lead.attributes?.leads_generate_date,
          lead_status_date: lead.attributes?.lead_status_date,
          future_contact_date: lead.attributes?.future_contact_date,
          created_at: lead.attributes?.created_at,
          country: lead.attributes?.country,
          revenue: lead.attributes?.revenue,
          old_status: lead.attributes?.old_status,
          source: `${lead.attributes?.source ?? '---'}`,
        };
        const color = handleStatusColor1(record, normalized);
        console.log(color, 'color_Update');
        if (color === 'ant-tag-green') return { ...record, color: 1 };
        if (color === 'ant-tag-red') return { ...record, color: 2 };
        if (color === 'ant-tag-yellow') return { ...record, color: 3 };
        if (color === 'ant-tag-light-green') return { ...record, color: 4 };
        if (color === undefined) return { ...record, color: 5 };

        return record;
      });
      console.log('leads_updated', data);
      state.pageNo = pageNo;
      if (leads.meta?.total_count > state.per_page) {
        state.leads = _.unionBy(state.leads, data, 'id');
      } else {
        state.leads = data;
      }
      state.meta = leads.meta;
      if (is_search && !is_status_changed && !is_revenue_changed) {
        state.sortParams.filters = null;
        state.sortParams.order = order;
        state.sortParams.field = field;
      }
    },
    fetchUserCategorySuccess(state, action: PayloadAction<any>) {
      state.user_categories = action.payload?.data;
    },
    fetchLeadsFailed(state) {
      state.leads = [];
      state.isLoading = false;
    },
    setSortParams(state, action: PayloadAction<any>) {
      const { order, field, filters, categoryFilters } = action.payload;
      state.sortParams.field = field;
      state.sortParams.order = order;
      state.sortParams.filters = filters;
      state.sortParams.categoryFilters = categoryFilters;
    },
    fetchContactTimingSuccess(state, action: PayloadAction<any>) {
      const contactTiming = action.payload;
      const normalized = contactTiming.map((contactTiming, index) => {
        return {
          id: `${contactTiming?.attributes?.id}`,
          status: `${contactTiming?.attributes?.status}`,
          no_of_days: contactTiming?.attributes?.no_of_days,
          created_at: `${contactTiming?.attributes?.created_at}`,
          position: contactTiming?.attributes?.position,
        };
      });
      state.contactTimings = normalized;
      state.isLoading = false;
    },
    fetchContactTimingFailure(state) {
      state.isLoading = false;
      state.contactTimings = null;
    },
  },
});

export const { actions: homePageActions } = slice;

export const useHomePageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: homePageSaga });
  return { actions: slice.actions };
};
