import { putReq } from 'config/Requests';
import { call, put, takeLatest } from 'redux-saga/effects';
import { resetPasswordActions as actions } from '.';
import { resetPasswordConstants } from './constants';
import Cookies from 'js-cookie';
import { handleError } from 'utils';
function* resetPassword(obj) {
  const auth = Cookies.get('authToken');
  const { payload, callback } = obj;
  try {
    yield put(actions.setIsLoadingTrue());
    const response = yield call(putReq, '/auth/password', payload, auth);
    callback(response);
    yield put(actions.setIsLoadingFalse());
  } catch (error) {
    handleError(error);
  }
}

export function* resetPasswordSaga() {
  yield takeLatest(resetPasswordConstants.RESET_PASSWORD, resetPassword);
}
