/**
 *
 * LandingPage
 *
 */
import React, { memo } from 'react';
import Logo from 'images/logo.png';
import '../../../../src/style.scss';

interface Props {}

export const LandingPage = memo((props: Props) => {
  return (
    <div style={{ height: '100%' }}>
      <div className="row landing-wrapper">
        <div className="col-md-4 col-lg-3 col-xl-4 col-xxl-4"></div>
        <div className="col-md-4 col-lg-6 col-xl-4 col-xxl-4 landing-msg">
          {' '}
          <div className="brand-logo brand-logo-responsive">
            <div>
              <img src={Logo} alt="" />
            </div>
          </div>
          <div>
            <p className="text-center heading-paragraph">
              Thanks for applying!
            </p>
            <p className="text-center" style={{ color: '#00722D' }}>
              * Please check your email for an account verification link.
            </p>
            <p className="text-center">
              We will review your info and call you within 48 hours
            </p>
            <p className="text-center last-paragraph">
              to discuss next steps. Talk to you soon!
            </p>
          </div>
        </div>
        <div className="col-md-4 col-lg-3  col-xl-4 col-xxl-4"></div>
      </div>
    </div>
  );
});
