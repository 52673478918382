import { call, put, select, takeLatest } from 'redux-saga/effects';
import { subscriptionPlanConstants } from './constants';
import { getReq } from 'config/Requests';
import { message } from 'antd';
import { subscriptionPlanActions as actions } from '.';
import Cookies from 'js-cookie';
import { selectuserId } from 'app/slice/selectors';

function* newGetSubscriptionPlans() {
  const auth = Cookies.get('authToken');
  try {
    const userId = yield select(selectuserId);
    if (userId) {
      yield put(actions.setLoadingTrue());
      const response = yield call(getReq, `users/${userId}/user_plan`, auth);
      if (response.status === 200) {
        yield put(
          actions.fetchSubscriptionPlansSuccess({
            subscriptionPlans: response.data,
          }),
        );
      } else response?.data?.meta?.messages?.map(m => message.error(m));
      yield put(actions.setLoadingFalse());
    }
  } catch (error) {
    yield put(actions.fetchSubscriptionPlansFailed());
    yield put(actions.setLoadingFalse());
  }
}

function* fetchSubscriptionPlans() {
  const auth = Cookies.get('authToken');
  try {
    yield put(actions.setLoadingTrue());
    const response = yield call(getReq, `/plans`, auth);
    if (response.status === 200) {
      yield put(
        actions.fetchSubscriptionPlansSuccess({
          subscriptionPlans: response.data,
        }),
      );
    } else response?.data?.meta?.messages?.map(m => message.error(m));
    yield put(actions.setLoadingFalse());
  } catch (error) {
    yield put(actions.fetchSubscriptionPlansFailed());
    yield put(actions.setLoadingFalse());
  }
}

function* fetchSubscriptionPlanById(obj) {
  const auth = Cookies.get('authToken');
  const { payload } = obj;
  try {
    yield put(actions.setLoadingTrue());
    const response = yield call(getReq, `/plans/${payload}`, auth);
    if (response.status === 200) {
      yield put(
        actions.fetchSubscriptionPlanSuccess({
          subscriptionPlan: response.data,
        }),
      );
    } else response?.data?.meta?.messages?.map(m => message.error(m));
    yield put(actions.setLoadingFalse());
  } catch (error) {
    yield put(actions.fetchSubscriptionPlanFailed());
    yield put(actions.setLoadingFalse());
  }
}
export function* subscriptionPlanSaga() {
  yield takeLatest(
    subscriptionPlanConstants.FETCH_SUBSCRIPTION_PLAN,
    fetchSubscriptionPlans,
  );
  yield takeLatest(
    subscriptionPlanConstants.FETCH_SUBSCRIPTION_PLAN_BY_ID,
    fetchSubscriptionPlanById,
  );
  yield takeLatest(
    subscriptionPlanConstants.NEW_GET_SUBSCRIPTION_PLANS,
    newGetSubscriptionPlans,
  );
}
