/**
 *
 * SubscriptionHistory
 *
 */
import { Button } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import './SubscriptionHistory.scss';
import moment from 'moment';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import { Spin } from 'antd';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionHistoryConstants } from './slice/constants';
import { useSubscriptionHistorySlice } from '../SubscriptionHistory/slice';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
import { useProfileSlice } from 'app/pages/Profile/slice';
import { paymentFormConstants } from '../PaymentForm/slice/constants';
import { usePaymentSlice } from '../PaymentForm/slice';
import ConfirmModal from '../ConfirmModal';
import { subscriptionPlanConstants } from '../SubscriptionPlan/slice/constants';
import { selectSubscriptionPlans } from '../SubscriptionPlan/slice/selectors';
import { signInConstants } from 'app/pages/SignIn/slice/constants';
import { useSubscriptionPlanSlice } from '../SubscriptionPlan/slice';
import {
  selectSubscriptionHistory,
  selectIsLoading,
  selectMeta,
  selectPageNo,
  selectCurrentCard,
  selectPlan,
  selectUserSubscriptions,
  selectImpersonatedUser,
} from '../SubscriptionHistory/slice/selectors';
import DataTable from '../DataTable';
import {
  SubscriptionHistoryState,
  subscriptionHistoryObj,
} from './slice/types';
import { selectisImpersonate } from 'app/slice/selectors';
import { CustomModal } from '../CustomModal';
import { StripeCardForm } from '../StripeCardForm';
import { selectSignOutLoading } from 'app/pages/SignIn/slice/selectors';
import { dataTableColoumns, getCardBrandIcon } from './Columns';
import { DATE_FORMAT } from 'utils/constants/constants';
import { toTitlecase } from 'utils/StringUtils/stringUtils';
import { DatePicker } from 'antd';
const _ = require('lodash');

const dateFormat = 'M/DD/YYYY';
interface Props {}
export const SubscriptionHistory = memo((props: Props) => {
  useSubscriptionHistorySlice();
  usePaymentSlice();
  useSubscriptionPlanSlice();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const subscriptionPlans = useSelector(selectSubscriptionPlans);
  const subscriptionHistory = useSelector(selectSubscriptionHistory);
  const loading = useSelector(selectIsLoading);
  const signoutLoading = useSelector(selectSignOutLoading);
  const meta = useSelector(selectMeta);
  const pageNo = useSelector(selectPageNo);
  const currentCard = useSelector(selectCurrentCard);
  const isImpersonate = useSelector(selectisImpersonate);
  const ImpersonatedUser = useSelector(selectImpersonatedUser);
  const isModerator = ImpersonatedUser?.data?.attributes?.is_moderator;
  const lastSubscription: subscriptionHistoryObj =
    subscriptionHistory[subscriptionHistory.length - 1];
  const planAmount = useSelector(selectPlan);
  const userSubscription = useSelector(selectUserSubscriptions);
  const userSubscriptionArray: SubscriptionHistoryState =
    userSubscription[userSubscription.length - 1];
  const [cancelSubscription, setCancelSubscription] = useState<boolean>(false);
  const [restartSubscription, setRestartSubscription] =
    useState<boolean>(false);
  const [showStripeCardFormModal, setShowStripeCardFormModal] =
    useState<boolean>(false);
  const [renewalDate, setRenewalData] = useState(moment());
  const [updateRenewal, setUpdateRenewal] = useState(false);
  useProfileSlice();
  const isCancelRequest = currentUser.cancel_subscription_requested;
  const dateObj = new Date(userSubscriptionArray?.attributes?.expires_at);
  const momentObj = moment(dateObj);
  const UserStatus = userSubscriptionArray?.attributes?.status;
  var CamelCaseStatus = _.startCase(_.toLower(UserStatus));

  const paginationHandler = (page, pageSize) => {
    dispatch({
      type: subscriptionHistoryConstants.GET_SUBSCRIPTION_HISTORY_PAGE_WISE,
      payload: { pageNo: page, per_page: pageSize },
    });
  };
  useEffect(() => {
    dispatch({
      type: subscriptionHistoryConstants.GET_SUBSCRIPTION_HISTORY_PAGE_WISE,
      payload: { pageNo: pageNo, per_page: 10 },
    });
    if (currentUser?.id && !isImpersonate) {
      dispatch({
        type: subscriptionHistoryConstants.GET_CURRENT_CARD,
        payload: currentUser?.id,
      });
    }
  }, [dispatch, isImpersonate, currentUser, pageNo]);
  useEffect(() => {
    if (currentUser?.id) {
      dispatch({
        type: subscriptionHistoryConstants.GET_SUBSCRIPTION_PLAN,
        payload: { userId: currentUser?.id },
      });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch({
        type: subscriptionHistoryConstants.GET_USER_SUBSCRIPTIONS,
        payload: { userId: currentUser?.id },
      });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch({
        type: subscriptionHistoryConstants.GET_IMPERSONATED_USER,
        payload: { userId: currentUser?.id },
      });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    dispatch({
      type: subscriptionPlanConstants.FETCH_SUBSCRIPTION_PLAN,
    });
  }, [currentUser, dispatch]);

  const handleCloseSubscription = () => {
    if (restartSubscription) {
      setRestartSubscription(false);
    } else if (updateRenewal) {
      setUpdateRenewal(false);
    } else {
      setCancelSubscription(false);
    }
  };

  const dispatchSubscriptionAction = payload => {
    const attrs = {
      user_subscription: {
        user_id: currentUser?.id,
      },
      is_restart: payload,
    };
    dispatch({
      type: subscriptionHistoryConstants.CANCEL_SUBSCRIPTION,
      payload: attrs,
      successCallback: () => {
        dispatch({
          type: signInConstants.FETCH_USER,
          callback: response => null,
        });
      },
    });
  };
  const dispatchRenewalAction = () => {
    const attrs = {
      user_subscription: {
        user_id: currentUser?.id,
        expires_at: renewalDate,
      },
    };
    dispatch({
      type: subscriptionHistoryConstants.RENEW_SUBSCRIPTION,
      payload: attrs,
      successCallback: () => {
        setUpdateRenewal(false);
        dispatch({
          type: signInConstants.FETCH_USER,
          callback: response => {},
        });
      },
    });
  };
  const handleSubmit = () => {
    if (cancelSubscription) {
      dispatchSubscriptionAction(false);
      setCancelSubscription(false);
    } else if (updateRenewal) {
      dispatchRenewalAction();
    } else {
      dispatchSubscriptionAction(true);
      setRestartSubscription(false);
    }
  };
  const handleRenew = date => {
    setRenewalData(date);
    setUpdateRenewal(true);
  };

  const disabledDate = current => {
    return current && current < moment().add(0, 'days');
  };
  const renderSubscriptionActions = () => {
    if (
      currentUser?.subscribed &&
      !isCancelRequest &&
      !isModerator &&
      userSubscriptionArray?.attributes?.status === 'cancelled_request'
    ) {
      return (
        <div className="subscription-action-btn">
          <span
            onClick={() => {
              setRestartSubscription(true);
            }}
          >
            Reactivate Account
          </span>
        </div>
      );
    }

    if (currentUser?.subscribed && !isCancelRequest && !isModerator) {
      return (
        <div className="subscription-action-btn">
          <span onClick={() => setCancelSubscription(true)}>
            Cancel Subscription
          </span>
        </div>
      );
    }
  };

  const renderRenewsDate = () => {
    if (
      isImpersonate &&
      userSubscriptionArray?.attributes?.status === 'active'
    ) {
      return (
        <>
          <h5 className="title">
            Renews:{' '}
            <span className="renew-date-picker">
              <DatePicker
                onChange={handleRenew}
                className="renew-date-picker"
                value={momentObj}
                format={dateFormat}
                disabledDate={disabledDate}
              />
            </span>
          </h5>
        </>
      );
    } else if (isImpersonate && currentUser?.subscribed) {
      return (
        <>
          <h5 className="title">
            Expires On:{' '}
            <span className="description">
              {moment(userSubscriptionArray?.attributes?.expires_at).format(
                DATE_FORMAT,
              )}
            </span>
          </h5>
        </>
      );
    }
  };

  return (
    <Spin
      spinning={loading || signoutLoading}
      size="large"
      tip={signoutLoading ? 'Signing Out...' : 'Fetching subscriptions...'}
    >
      <Div>
        <CustomModal
          isVisible={showStripeCardFormModal}
          handleCancel={() => {
            dispatch({
              type: paymentFormConstants.CLEAR_CLIENT_SECRET,
            });
            setShowStripeCardFormModal(false);
          }}
          title="Add New Card"
        >
          <StripeCardForm
            setModalVisibility={setShowStripeCardFormModal}
            isUpdateCard={true}
          />
        </CustomModal>
        <ConfirmModal
          isVisible={cancelSubscription || restartSubscription || updateRenewal}
          handleCancel={handleCloseSubscription}
          title={
            restartSubscription
              ? 'Restart subscription'
              : cancelSubscription
              ? 'Cancel subscription'
              : 'Update Recurring Payment Date'
          }
          onCancel={handleCloseSubscription}
          isdelete={updateRenewal ? false : true}
          message={
            restartSubscription
              ? 'Are you sure you want to restart your previous subscription?'
              : cancelSubscription
              ? 'Are you sure you want to cancel current subscription?'
              : `Are you sure you want to update the recurring payment date from ${moment(
                  userSubscriptionArray?.attributes?.expires_at,
                ).format('M/DD/YY')} to ${moment(renewalDate).format(
                  'M/DD/YY',
                )} ?`
          }
          onSubmit={handleSubmit}
        />
        <>
          <div className="row">
            <div className="col-md-6 col-xxl-7">
              <div className="subscription-history">
                <h4 className="heading">Subscription History</h4>
                <h5 className="title">{currentUser?.company_name}</h5>
                <h5 className="title">
                  Plan :{' '}
                  <span className="description">
                    {' '}
                    {subscriptionPlans?.length > 0 ?? ''} (
                    <span style={{ fontWeight: '900' }}>
                      {formatMoney(planAmount / 100 ?? 0, {
                        precision: 2,
                      })}
                    </span>{' '}
                    per month)
                    {userSubscriptionArray?.attributes?.status ===
                    'cancelled_request' ? (
                      <span className={`ms-3 text-danger`}>
                        Cancellation Requested
                      </span>
                    ) : (
                      <span
                        className={`ms-3 ${
                          userSubscriptionArray?.attributes?.status === 'active'
                            ? 'text-success'
                            : 'text-danger'
                        }`}
                      >
                        {toTitlecase(
                          lastSubscription?.status?.replace('_', ' '),
                        )}
                        {CamelCaseStatus}
                      </span>
                    )}
                  </span>
                </h5>
                <h5 className="title">
                  Enrolled Since:{' '}
                  <span className="description">
                    {moment(
                      userSubscriptionArray?.attributes?.starts_at,
                    ).format(DATE_FORMAT)}
                  </span>
                </h5>
                <h5 className="title">
                  Next Billing:{' '}
                  <span className="description">
                    {moment(
                      userSubscriptionArray?.attributes?.expires_at,
                    ).format(DATE_FORMAT)}
                  </span>
                </h5>
                {renderRenewsDate()}
                {renderSubscriptionActions()}
              </div>
            </div>
            <div className="col-md-6 col-xxl-5">
              {!isCancelRequest && !isImpersonate && (
                <div className="payment-tupe-wrap mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="title">Payment Type:</div>
                    </div>
                    <div className="col-md-5">
                      <div className="card-type">
                        <p>
                          {' '}
                          <span>
                            {getCardBrandIcon(currentCard.card_brand)}
                          </span>{' '}
                          **** **** **** {currentCard.last4}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <Button
                        htmlType="submit"
                        className="custom-green-btn me-5 px-5"
                        onClick={() => {
                          dispatch({
                            type: paymentFormConstants.FETCH_CLIENT_SECRET,
                          });
                          setShowStripeCardFormModal(true);
                        }}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DataTable
                columns={dataTableColoumns}
                data={subscriptionHistory}
                pagination={true}
                currentPageNo={pageNo}
                paginationHandler={paginationHandler}
                meta={meta}
              />
            </div>
          </div>
        </>
      </Div>
    </Spin>
  );
});

const Div = styled.div``;
