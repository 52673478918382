import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { signUpSaga } from './saga';
import { SignUpState } from './types';

export const initialState: SignUpState = {
  isLoading: false,
  user: {},
  authToken: '',
};

const slice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    signUpUserSuccess(state, action: PayloadAction<any>) {
      const { user } = action.payload;
      state.user = user;
      state.isLoading = false;
    },
    signUpUserFailed(state) {
      state.isLoading = false;
      state.authToken = '';
      state.user = {};
    },
  },
});

export const { actions: signUpActions } = slice;

export const useSignUpSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: signUpSaga });
  return { actions: slice.actions };
};
