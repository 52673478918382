import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.signIn || initialState;

export const selectUser = createSelector([selectSlice], state => state.user);

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectAuthToken = createSelector(
  [selectSlice],
  state => state.authToken,
);
export const selectSignIn = createSelector([selectSlice], state => state);
export const selectCategories = createSelector(
  [selectSlice],
  state => state.categories,
);
export const selectError = createSelector([selectSlice], state => state.error);

export const selectSignOutLoading = createSelector(
  [selectSlice],
  state => state.signoutLoading,
);
