import Cookies from 'js-cookie';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
export const isLogin = path => {
  const access_token = Cookies.get('authToken');
  if (!access_token) {
    return 'notLoggedIn';
  } else return 'loggedIn';
};

const isExpireToken = token => {
  let decoded: any = {};
  try {
    decoded = jwt_decode(token);
  } catch {
    console.log('Token Decode Error');
    console.log({ decoded, token });
  }

  const expiry_time = moment.unix(decoded?.exp).format('YYYY-MM-DD hh:mm:ss A');
  const current_time = moment().format('YYYY-MM-DD hh:mm:ss A');
  const isAfter = moment(current_time).isAfter(moment(expiry_time));
  if (isAfter) {
    return true;
  } else {
    return false;
  }
};
export const getAuth = (isimpersonate, userId) => {
  if (isimpersonate) {
    if (isExpireToken(Cookies.get(`${userId}`))) {
      Cookies.remove(`${userId}`);
      window.location.href = window.location.origin + '/sign-in';
    }
    if (!Cookies.get(`${userId}`)) {
      window.location.href = window.location.origin + '/sign-in';
    }
    return Cookies.get(`${userId}`);
  } else {
    if (!Cookies.get(`${userId}`)) {
      if (isExpireToken(Cookies.get('authToken'))) {
        Cookies.remove('authToken');
        window.location.href = window.location.origin + '/sign-in';
      }
      return Cookies.get('authToken');
    }
  }
};
export const RedirectCheck = (
  isImpersonate,
  impersonatePath,
  defaultPath,
  history,
) => {
  if (isImpersonate) {
    history.push(impersonatePath);
  } else {
    history.push(defaultPath);
  }
};
