/**
 *
 * StripeCardForm
 *
 */
import React, { memo, useEffect, useState, useRef, useMemo } from 'react';
import { Form, Spin } from 'antd';
import { message } from 'antd';
import { Radio } from 'antd';
import MasterCard from '../../../images/master-card.png';
import VisaCard from '../../../images/Visa.png';
import DiscoverLogo from '../../../images/Discover-logo.png';
import AmericanExpress from '../../../images/American_Express.png';
import { useSubscriptionPlanSlice } from '../SubscriptionPlan/slice';
import { useSelector, useDispatch } from 'react-redux';
import { paymentFormConstants } from '../PaymentForm/slice/constants';
import { subscriptionHistoryConstants } from '../SubscriptionHistory/slice/constants';
import {
  selectClientSecret,
  selectIsLoading,
} from '../PaymentForm/slice/selectors';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
import { usePaymentSlice } from '../PaymentForm/slice';
import './StripeCardForm.scss';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
interface Props {
  isUpdateCard?: boolean;
  setModalVisibility?: (boolean) => void;
}

export const StripeCardForm = memo((props: Props) => {
  useSubscriptionPlanSlice();
  usePaymentSlice();
  const [form] = Form.useForm();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const clientSecret = useSelector(selectClientSecret);
  const currentUser = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const [stripeLoading, setStripeLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [fetchStripeClientSecret, setFetchStripeClientSecret] =
    useState<boolean>(true);
  const appearance: any = useMemo(() => {
    return {
      theme: 'stripe',
    };
  }, []);

  // Pass the appearance object to the Elements instance
  let elements = useRef<StripeElements | undefined>(undefined);
  useEffect(() => {
    if (clientSecret.length > 0) {
      elements.current = stripe?.elements({
        clientSecret: clientSecret,
        appearance: appearance,
        loader: 'auto',
      });
      const paymentElement = elements?.current?.create('payment');
      paymentElement?.mount('#payment-element');
      setStripeLoading(false);
    }
  }, [clientSecret, appearance, stripe]);

  useEffect(() => {
    form.setFieldsValue({
      subscription_type: 0,
      card_holder: '',
      card_number: '',
      card_expiry: '',
      cvc: '',
      discount_code: '',
    });
  }, [form]);

  useEffect(() => {
    if (fetchStripeClientSecret) {
      dispatch({
        type: paymentFormConstants.FETCH_CLIENT_SECRET,
        callback: () => setStripeLoading(false),
      });
      return () => {
        dispatch({
          type: paymentFormConstants.CLEAR_CLIENT_SECRET,
        });
      };
    }
    setFetchStripeClientSecret(false);
  }, [fetchStripeClientSecret, dispatch]);

  const onStripeCardSubmit = async event => {
    setStripeLoading(true);
    event.preventDefault();
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements: { ...elements?.current } as StripeElements,
      confirmParams: {
        return_url: 'http://localhost:3000/new-payment-method',
      },
      redirect: 'if_required',
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      message.error(error.message);
      setErrorMessage(error.message);
      dispatch({
        type: paymentFormConstants.CARD_DECLINE,
      });
    } else {
      setErrorMessage('');
      if (props.isUpdateCard) {
        const attrs = {
          card: {
            user_id: currentUser?.id,
            setup_intent_id: setupIntent?.id ?? '',
          },
        };
        dispatch({
          type: paymentFormConstants.UPDATE_USER_CARD,
          payload: attrs,
          callback: response => {
            if (response.status === 200) {
              props.setModalVisibility && props.setModalVisibility(false);
              if (currentUser?.id) {
                dispatch({
                  type: subscriptionHistoryConstants.GET_CURRENT_CARD,
                  payload: currentUser?.id,
                });
              }
            }
          },
        });
      } else {
        if (setupIntent?.status === 'succeeded') {
          message.success('Card added successfully.');
        }
      }
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setStripeLoading(false);
  };

  return (
    <Spin
      spinning={stripeLoading ?? isLoading ?? false}
      size="large"
      tip="Loading..."
      className="subscription-spinner"
    >
      <Form form={form}>
        <Form.Item
          name="subscription_type"
          rules={[
            {
              required: true,
              message: 'Payment type required',
            },
          ]}
        >
          <Radio.Group value={form.getFieldValue('subscription_type')}>
            <Radio value={0}>
              Credit or Debit card
              <span className="form-img-wrapper">
                <img src={MasterCard} alt="Master card" />
                <img src={VisaCard} alt="visa card" />
                <img src={DiscoverLogo} alt="discover logo" />
                <img src={AmericanExpress} alt="american express" />
              </span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      <form onSubmit={onStripeCardSubmit}>
        <div style={{ minHeight: '350px' }} id="payment-element"></div>
        <button className="custom-green-btn w-100 mt-2" disabled={!stripe}>
          Save
        </button>
        {/* Show error message to your customers */}
        {errorMessage && (
          <div className="mt-2" style={{ color: 'red' }}>
            {errorMessage}
          </div>
        )}
      </form>
    </Spin>
  );
});
