/**
 *
 * SelectInput
 *
 */
import styled from 'styled-components/macro';
import React from 'react';
import { Select } from 'antd';

export const SelectInput = props => {
  const { Option } = Select;
  let {
    label,
    placeholder,
    options,
    value,
    disabled = false,
    fromStates,
    ...rest
  } = props;

  if (!placeholder) placeholder = label;

  return (
    <Div>
      <div className="float-label">
        <Select
          className="custom-float-select"
          optionFilterProp="children"
          onChange={props.onChange}
          value={value}
          disabled={disabled}
          placeholder={placeholder ?? 'Select'}
          {...rest}
        >
          {options &&
            options.map((item, index) => {
              return (
                <Option
                  key={index}
                  className={`${item.className ?? ''} mx-2 my-1 rounded`}
                  value={item.id}
                >
                  {item.name}
                </Option>
              );
            })}
        </Select>
      </div>
    </Div>
  );
};

const Div = styled.div``;
