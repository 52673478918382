/**
 *
 * SignIn
 *
 */
import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Logo from '../../../../src/images/logo.png';
import { Button, Form, Input, message, Grid } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSignInSlice } from './slice';
import { signInConstants } from './slice/constants';
import Cookies from 'js-cookie';
import { signUpConstants } from '../SignUp/slice/constants';
import { useSignUpSlice } from '../SignUp/slice';
import { USER_LOGIN_STATES } from 'utils/constants/constants';
import { CustomModal } from 'app/components/CustomModal';
import './SignIn.scss';
import { selectError } from './slice/selectors';
import { signInActions } from './slice';
import './SignIn.scss';
interface Props {}
const { useBreakpoint } = Grid;

let initialStates = {
  showResendModal: false,
  enteredEmail: '',
};

export const SignIn = memo((props: Props) => {
  const { xxl } = useBreakpoint();
  const [form] = Form.useForm();
  const [properties, setProperties] = useState({ ...initialStates });
  const error = useSelector(selectError);
  const updateValues = (param, value) => {
    setProperties(prevState => ({ ...prevState, [param]: value }));
  };
  const dispatch = useDispatch();
  const history = useHistory();
  useSignInSlice();
  useSignUpSlice();

  const onFinish = event => {
    updateValues('enteredEmail', event.email);
    const body = {
      user: {
        email: event.email,
        password: event.password,
      },
    };
    dispatch({
      type: signInConstants.SIGN_IN_USER,
      payload: body,
      callback: response => {
        if (response.status === 200) {
          if (
            response.data.data.attributes.is_admin ||
            response.data.data.attributes.is_moderator
          ) {
            message.error('Invalid email or password');
          } else {
            const successMessage = response?.data?.meta?.message;
            message.success(successMessage);
            const authToken = response?.headers?.authorization;
            Cookies.set('authToken', authToken);
            history.push('/');
          }
        } else if (response.data.state === USER_LOGIN_STATES.NOT_CONFIRMED) {
          updateValues('showResendModal', true);
          const errorMessgae = response?.data?.message;
          message.error(errorMessgae);
        } else message.error(response?.data?.message);
      },
    });
  };

  const resendVerificationMail = () => {
    dispatch({
      type: signUpConstants.RESEND_CONFIRMATION,
      payload: properties.enteredEmail,
      callback: respnse => {
        if (respnse.status === 200) {
          message.success(respnse.data.message);
          updateValues('showResendModal', false);
        }
      },
    });
  };
  useEffect(() => {
    return () => {
      dispatch(signInActions.clearError());
    };
  }, [dispatch]);
  const conatinerHeight =
    window.innerHeight > 800 ? `row w-100 row-height` : `row w-100`;
  return (
    <Div className="sign-in-wrapper">
      <div className="register-form">
        <div className={conatinerHeight}>
          <div className={xxl ? 'col-md-3' : 'col-md-2'}></div>
          <div className={xxl ? 'col-md-6 mb-5' : 'col-md-8 p-0 mb-5'}>
            <div className=" text-center">
              <div>
                <img className="sign-logo" src={Logo} alt="" />
              </div>
            </div>
            <div className="sign-in-form">
              <div className="add-lead-table-wrap">
                <div>
                  <h1 className="main-heading">SIGN IN</h1>
                </div>
                <div className="row">
                  <Form form={form} onFinish={onFinish}>
                    <div className="col-12 mb-3">
                      <div className="custom-form-input-wrap">
                        <label htmlFor="">Email</label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Email is required',
                            },
                          ]}
                          name={'email'}
                        >
                          <Input
                            type={'email'}
                            placeholder={'Type your email here'}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="custom-form-input-wrap ">
                        <label htmlFor="">Password</label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Password is required',
                            },
                          ]}
                          name={'password'}
                        >
                          <Input
                            type={'password'}
                            placeholder={'Type your password here'}
                          />
                        </Form.Item>
                      </div>
                      <div className="d-flex justify-content-between flex-wrap">
                        <div className="black-clr-link bold-text">
                          <span>
                            {' '}
                            <input type="checkbox" />
                          </span>{' '}
                          Remember me
                        </div>
                        <div className="forgot-link ">
                          <NavLink
                            to="/forgot-password"
                            className="bold-text black-clr-link"
                            style={{
                              color: 'black',
                              textDecoration: 'underline',
                            }}
                          >
                            Forgot password?
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-5">
                      <div className="d-flex justify-content-center">
                        <button type="submit" className="custom-green-btn px-5">
                          Sign In
                        </button>
                      </div>
                      {error?.response?.data ? (
                        <div className="d-flex justify-content-center mt-2">
                          <span className="custom-error">
                            {error?.response?.data.message}{' '}
                            {error.response?.data?.state ===
                            USER_LOGIN_STATES.NOT_ACTIVATED ? (
                              <a
                                style={{ textDecoration: 'underline' }}
                                href="mailto:info@liftoffleads.com"
                              >
                                here
                              </a>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="d-flex justify-content-center mt-4">
                        <p className="black-clr-link bold-text ">
                          Don't have an account?{' '}
                          <NavLink
                            to="/sign-up"
                            style={{
                              color: 'black',
                              textDecoration: 'underline',
                            }}
                            className="black-clr-link bold-text"
                          >
                            Sign Up
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </Form>
                  <CustomModal
                    isVisible={properties.showResendModal}
                    handleCancel={() => updateValues('showResendModal', false)}
                    title={'Resend confirmation email'}
                  >
                    <div>
                      <div className="pb-4">
                        <p>
                          Your account has not been confirmed please resend
                          verification link
                        </p>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="custom-green-btn me-4"
                          onClick={resendVerificationMail}
                        >
                          {' '}
                          Resend{' '}
                        </Button>
                        <Button
                          className="custom-white-btn me-4"
                          onClick={() => updateValues('showResendModal', false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </CustomModal>
                </div>
              </div>
            </div>
          </div>
          <div className={xxl ? 'col-md-3' : 'col-md-2'}></div>
        </div>
      </div>
    </Div>
  );
});

const Div = styled.div``;
