/**
 *
 * Paginator
 *
 */
import React from 'react';
import { Pagination } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

export const Paginator = ({ total, defaultCurrent, onchange }) => {
  return (
    <Div>
      <Pagination
        showQuickJumper
        showSizeChanger
        onChange={(page, pageSize) => onchange(page, pageSize)}
        total={total}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultPageSize={10}
        defaultCurrent={1}
        current={defaultCurrent}
      />
    </Div>
  );
};

const Div = styled.div``;

Paginator.defaultProps = {};

Paginator.propTypes = {
  total: PropTypes.number.isRequired,
  defaultCurrent: PropTypes.number.isRequired,
  onchange: PropTypes.func.isRequired,
};
