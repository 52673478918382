import Cookies from 'js-cookie';
import { postReq } from 'config/Requests';
import { forgotPasswordActions as actions } from '.';
import { forgotPasswordConstants } from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
function* forgotPassword(obj) {
  const auth = Cookies.get('authToken');
  const { payload, callback } = obj;
  try {
    yield put(actions.setIsLoadingTrue());
    const response = yield call(postReq, '/auth/password', payload, auth);
    callback(response);
    yield put(actions.setIsLoadingFalse());
    yield put(actions.forgotPasswordSuccess({ severity: 'success' }));
  } catch (error) {
    yield put(actions.forgotPasswordFailed({ severity: 'faliure', error }));
  }
}

export function* forgotPasswordSaga() {
  yield takeLatest(forgotPasswordConstants.FORGOT_PASSWORD, forgotPassword);
}
