import { message } from 'antd';
import { leadsContants } from './constants';
import { homePageActions as actions } from '.';
import { handleError } from '../../../../../src/utils';
import { getAuth } from '../../../../../src/utils/Auth';
import { getReq, postReq, putReq } from 'config/Requests';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectisImpersonate, selectuserId } from 'app/slice/selectors';
import { signInConstants } from 'app/pages/SignIn/slice/constants';
function* clearLeads() {
  yield put(actions.fetchLeadsFailed());
}

function* postLead(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { payload, callback } = obj;

  try {
    yield put(actions.setLoading(true));
    const response = yield call(postReq, '/leads', payload, auth);
    callback(response);
    yield put(actions.setLoading(false));
  } catch (error) {
    handleError(error);
    yield put(actions.setLoading(false));
  }
}
function* addLeadRevenue(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { lead_revenue } = obj?.payload;
  try {
    if (obj?.payload?.is_status_changed) {
      var body = {
        id: lead_revenue?.lead_revenue?.lead_id,
        status: lead_revenue?.lead_revenue?.lead_status.new_status,
      };
      if (lead_revenue?.lead_revenue?.future_contact_date != null) {
        body['future_contact_date'] =
          lead_revenue?.lead_revenue?.future_contact_date;
      }
      yield call(
        putReq,
        `/leads/${lead_revenue?.lead_revenue?.lead_id}`,
        body,
        auth,
      );
    }
    yield call(postReq, `/lead_revenues`, obj?.payload?.lead_revenue, auth);
    yield put({
      type: leadsContants.FETCH_LEADS_PAGE_WISE,
      payload: obj?.payload,
    });
    yield put({
      type: leadsContants.FETCH_USER_CATEGORY,
    });
  } catch (error) {
    handleError(error);
  }
}

function* fetchLeadsPageWise(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { pageNo, per_page } = obj.payload;
  const order = '';
  const field = '';
  try {
    yield put(actions.setLoading(true));
    let requestUrl = `/leads?page=${pageNo}&per_page=${per_page}&order=${order}&field=${field}`;

    if (obj?.payload?.is_search) {
      requestUrl = `/leads?page=${pageNo}&per_page=${per_page}&full_name=${obj?.payload?.full_name}&order=${order}&field=${field}`;
    }
    if (obj?.payload?.is_sort) {
      requestUrl = `/leads?page=${pageNo}&per_page=${per_page}&order=${order}&field=${field}&full_name=${obj?.payload?.full_name}`;
    }
    const contact_timings_response = yield call(
      getReq,
      '/contact_timings',
      auth,
    );
    const response = yield call(getReq, requestUrl, auth);
    yield put(
      actions.fetchLeadsSuccess({
        leads: response.data,
        contact_timings_response: contact_timings_response?.data?.data,
        pageNo: obj?.payload?.pageNo,
        per_page: obj?.payload?.per_page,
        is_sort: obj?.payload?.is_sort,
        is_search: obj?.payload?.is_search,
        order: obj?.payload?.order,
        field: obj?.payload?.field,
        is_status_changed: obj?.payload?.is_status_changed,
        is_revenue_changed: obj?.payload?.is_revenue_changed,
      }),
    );
  } catch (error) {
    yield put(actions.fetchLeadsFailed());
    handleError(error);
  }
}
function* updateStatsLead(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { params, id } = obj?.payload;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(
      putReq,
      `/users/${id}/update_user_categories`,
      params,
      auth,
    );
    if (response.status === 200) {
      yield put({
        type: leadsContants.FETCH_USER_CATEGORY,
        payload: { id: id },
      });
      yield put({
        type: signInConstants.FETCH_USER,
        callback: response => null,
      });
      // message.success('categories updated successfully');
    } else response?.data?.meta?.messages?.map(m => message.error(m));
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.setLoading(false));
  }
}
function* fetchUserCategory(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setLoading(true));
    const response = yield call(
      getReq,
      `/user_categories/stats
    `,
      auth,
    );
    yield put(actions.fetchUserCategorySuccess(response?.data));
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.setLoading(false));
  }
}
function* fetchContactTiming() {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setLoading(true));
    const response = yield call(getReq, '/contact_timings', auth);
    yield put(actions.fetchContactTimingSuccess(response?.data?.data));
  } catch (error: any) {
    error?.response?.data?.messages?.map(m => message.error(m)) ??
      message.error('Something went wrong');
    yield put(actions.fetchContactTimingFailure());
  }
}
export function* homePageSaga() {
  yield takeLatest(leadsContants.POST_LEAD, postLead);
  yield takeLatest(leadsContants.CLEAR_LEADS, clearLeads);
  yield takeLatest(leadsContants.UPDATE_STATUS_LEAD, updateStatsLead);
  yield takeLatest(leadsContants.FETCH_USER_CATEGORY, fetchUserCategory);
  yield takeLatest(leadsContants.FETCH_LEADS_PAGE_WISE, fetchLeadsPageWise);
  yield takeLatest(leadsContants.ADD_LEAD_REVENUE, addLeadRevenue);
  yield takeLatest(leadsContants.FETCH_CONTACT_TIMING, fetchContactTiming);
}
