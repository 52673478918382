import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.archivedLeads || initialState;

export const selectArchivedLeads = createSelector(
  [selectSlice],
  state => state.archivedLeads,
);

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);
export const selectMeta = createSelector([selectSlice], state => state.meta);

export const selectError = createSelector([selectSlice], state => state.error);
export const selectpageNo = createSelector(
  [selectSlice],
  state => state.pageNo,
);
export const selectperPage = createSelector(
  [selectSlice],
  state => state.per_page,
);
export const selectsortParams = createSelector(
  [selectSlice],
  state => state.sortParams,
);
