import { message } from 'antd';
import { leadDetailActions as actions } from '.';
import { leadDetailConstants } from './constants';
import { handleError } from '../../../../../src/utils';
import { getAuth } from '../../../../../src/utils/Auth';
import { getReq, postReq, putReq, deleteReq } from 'config/Requests';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectisImpersonate, selectuserId } from 'app/slice/selectors';
function* fetchLead(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { payload, callback } = obj;
  const leadId = payload;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(getReq, `/leads/${leadId}`, auth);
    if (callback) callback(response);
    yield put(
      actions.fetchLeadSuccess({
        lead: response?.data?.data,
      }),
    );
  } catch (error) {
    handleError(error);
    yield put(actions.setLoading(true));
    yield put(actions.fetchLeadFailed());
  }
}
function* updateLead(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { payload, errorCallback, callback } = obj;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(
      putReq,
      `/leads/${payload.lead.id}`,
      payload.lead,
      auth,
    );
    callback(response);
    yield put(actions.setLoading(false));
  } catch (error) {
    if (errorCallback) errorCallback();
    handleError(error);
    yield put(actions.setLoading(false));
  }
}

function* fetchLeadNotes(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { payload } = obj;
  const leadId = payload;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(
      getReq,
      `/lead_revenues?lead_id=${leadId}`,
      auth,
    );
    yield put(
      actions.setNotes({
        notes: response?.data?.data,
      }),
    );
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.clearNotes());
    yield put(actions.setLoading(false));
  }
}

function* addLeadNotes(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { payload, callback } = obj;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(postReq, `/lead_revenues`, payload, auth);
    callback(response);
    yield put(actions.setLoading(false));
  } catch (error) {
    // yield put(actions.clearNotes());
    handleError(error);
    yield put(actions.setLoading(false));
  }
}
function* clearLead() {
  try {
    yield put(actions.setLoading(true));
    yield put(actions.clearLead());
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.fetchLeadFailed());
    yield put(actions.setLoading(false));
  }
}
function* deleteLead(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);

  const { recordId, callback } = obj?.payload;
  try {
    const response = yield call(
      deleteReq,
      `/leads/${recordId}`,
      undefined,
      auth,
    );
    if (callback) callback(response);
    message.success('Lead deleted successfully');
  } catch (error) {
    handleError(error);
  }
}
export function* leadDetailSaga() {
  yield takeLatest(leadDetailConstants.FETCH_LEAD, fetchLead);
  yield takeLatest(leadDetailConstants.FETCH_LEAD_NOTES, fetchLeadNotes);
  yield takeLatest(leadDetailConstants.ADD_LEAD_NOTES, addLeadNotes);
  yield takeLatest(leadDetailConstants.UPDATE_LEAD, updateLead);
  yield takeLatest(leadDetailConstants.CLEAR_LEAD, clearLead);
  yield takeLatest(leadDetailConstants.DELETE_LEAD, deleteLead);
}
