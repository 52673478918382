import { forgotPasswordSaga } from './saga';
import { ForgotPasswordState } from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

export const initialState: ForgotPasswordState = {
  isLoading: false,
  severity: '',
  error: '',
};

const slice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setIsLoadingTrue(state) {
      state.isLoading = true;
    },
    setIsLoadingFalse(state) {
      state.isLoading = false;
    },
    forgotPasswordSuccess(state, action) {
      const { severity } = action.payload;
      state.severity = severity;
      state.error = '';
    },
    forgotPasswordFailed(state, action) {
      const { severity } = action.payload;
      state.isLoading = false;
      state.severity = severity;
      state.error = action?.payload?.error;
    },
    clearError(state) {
      state.error = '';
    },
  },
});

export const { actions: forgotPasswordActions } = slice;

export const useForgotPasswordSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: forgotPasswordSaga });
  return { actions: slice.actions };
};
