/**
 *
 * SubscriptionPlan
 *
 */
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import AppHeader from 'app/components/HOC/AppLayout/AppHeader';
import HybridPlanImg from '../../../../src/images/hybrid-subscription.png'
import './SubscriptionPlan.scss';
interface Props {}

export const HybridPlan = memo((subPlanId:any) => {
  const history = useHistory();
  console.log("subPlanIdsubPlanId==>",subPlanId.subPlanId)
  return (
    <>
      {' '}
      <Div className="main-wrapper">
        <AppHeader />
        <div className="row w-75">
          {/* This commented code is for Hybrid plan if incase we need to show prices dynamically */}
          {/* <div
            className="col-md-6 mt-md-0 mt-5"
          >
            <div className="hybrid-card">
              <div className="hybrid-card-header">
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='hybrid-card-icon'></div>
                  <div className='title pe-5'>SETUP</div>
                </div>
              </div>
              <div className="hybrid-card-body">
                <div className='d-flex align-items-center'>
                <div className='initial-price'> 
                {formatMoney(userSubscriptionPlan?.attributes?.initial_amount, {
                    precision: 2,
                  })}{' '} </div>
                  <div className='price-description'>
                      one-time setup fee covers first 2 months
                  </div>
                </div>
                <div className='hybrid-benefits-list'>
                    <ul>
                      <li>Website refresh to maximize your conversion</li>
                      <li>Google ad compains begin, featuring your two top genres</li>
                      <li>Daily behind-the-scenes SEo work boost your organic ranking</li>
                      <li><span className='red bold'>BONUS!</span> <span className='bold'>Pro sales training!</span> 3 one-to-one coaching call with Megan DiPiero</li>
                      <li><span className='red bold'>Ad spend included</span></li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="hybrid-card">
              <div className="hybrid-card-header">
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='hybrid-card-icon'></div>
                  <div className='title pe-5'>LIFTOFF</div>
                </div>
              </div>
              <div className="hybrid-card-body">
                <div className='d-flex align-items-center'>
                <div className='initial-price'> 
                {formatMoney(userSubscriptionPlan?.attributes?.amount, {
                    precision: 2,
                  })}{' '} </div>
                  <div className='price-description'>
                      ongoing subscription starting month 3
                  </div>
                </div>
                <div className='hybrid-benefits-list'>
                    <ul>
                      <li><span className='red bold'>No annual contact!</span> Stay with us as long as you're seeing results</li>
                      <li>Sales toos built for you! Convert more leads with our Mission Control Portal</li>
                      <li>Guarenteed geographic exclusivity for as long as you're a client</li>
                      <li>Monthly progress reports let you track your results and celebrate you wins!</li>
                      <li><span className='red bold'>Ad spend included</span></li>
                    </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12 text-center">
            <img className='hybrid-img' src={HybridPlanImg} alt="" />
          </div>
          <div className="col-12">
          <div className='p-3 text-center'>
            <button
              onClick={() => {
                history.push({
                  pathname: '/new-payment-method',
                  state: { subscriptionPlanId: subPlanId?.subPlanId },
                });
              }}
              className="custom-subscription-btn mb-3"
            >
              Subscribe
            </button>
            </div>
          </div>
        </div>
      </Div>
    </>
  );
});

const Div = styled.div``;
