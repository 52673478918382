/**
 *
 * AppLayout
 *
 */
import React, { memo } from 'react';
import { Layout } from 'antd';
import AppHeader from './AppHeader';
import './AppLayout.scss';
interface Props {
  children: any;
}
const AppLayout = memo((props: Props) => {
  const { Content } = Layout;
  return (
    <Layout>
      <AppHeader />
      <Layout style={{ flexDirection: 'row' }}>
        <Layout className="main-container">
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: '100vh',
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
});
export default AppLayout;
