import Cookies from 'js-cookie';
import { signUpActions as actions } from '.';
import { signUpConstants } from './constants';
import { getReq, postReq } from 'config/Requests';
import { handleError } from '../../../../../src/utils';
import { call, put, takeLatest } from 'redux-saga/effects';
function* signUpUserSaga(obj) {
  const auth = Cookies.get('authToken');
  const { payload, callback } = obj;
  try {
    yield put(actions.setIsLoading(true));
    const response = yield call(postReq, '/auth', payload, auth);
    callback(response);
    yield put(actions.signUpUserSuccess(response));
  } catch (error) {
    handleError(error);
    yield put(actions.signUpUserFailed());
  }
}

function* resendVerificationMail(obj) {
  const auth = Cookies.get('authToken');
  const { payload, callback } = obj;
  try {
    yield put(actions.setIsLoading(true));
    const response = yield call(
      getReq,
      `users/resend_confirmation?email=${payload}`,
      auth,
    );
    callback(response);
    yield put(actions.setIsLoading(false));
  } catch (error) {
    yield put(actions.setIsLoading(false));
    handleError(error);
  }
}

export function* signUpSaga() {
  yield takeLatest(signUpConstants.SIGN_UP_USER, signUpUserSaga);
  yield takeLatest(signUpConstants.RESEND_CONFIRMATION, resendVerificationMail);
}
