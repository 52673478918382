/**
 *
 * AddLeadForm
 *
 */
import './AddLeadForm.scss';
import NoteForm from '../NoteForm';
import { RedirectCheck } from 'utils/Auth';
import { disableFutureDates } from 'utils';
import { CustomModal } from '../CustomModal';
import { NavLink, useHistory } from 'react-router-dom';
import InputPhone from 'app/components/InputPhone';
import { SelectInput } from '../FormUI/SelectInput';
import NotesContent from 'app/components/NotesContent';
import { useDispatch, useSelector } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { useHomePageSlice } from 'app/pages/HomePage/slice';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
import { Input, DatePicker, Form, Button, message } from 'antd';
import { selectCategories } from 'app/pages/SignIn/slice/selectors';
import { selectisImpersonate, selectpath } from 'app/slice/selectors';
import {
  PHONE_TYPES,
  LEAD_STATUSES,
  DETAIL_DATE_FORMAT,
} from '../../../utils/constants/constants';
interface Props {}

export const AddLeadForm = memo((props: Props) => {
  const history = useHistory();
  useHomePageSlice();
  const user = useSelector(selectUser);
  const isImpersonate = useSelector(selectisImpersonate);
  const path = useSelector(selectpath);
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showAddNoteModal, setShowAddNoteModal] = useState<boolean>(false);
  const [notes, setNotes] = useState<NoteType>([]);
  const [leadStatus, setleadStatus] = useState<string>('');
  const [countryCode, setcountryCode] = useState<string>('US');

  const countries = [
    { name: 'United States', id: 'US' },
    { name: 'Canada', id: 'CA' },
    { name: 'Other', id: 'other' },
  ];

  const onFinish = event => {
    const attrs = {
      lead: {
        user_id: user?.id ?? '',
        first_name: event.first_name,
        last_name: event.last_name,
        email: event.email,
        user_category_id: event.category,
        status: event.status,
        notes: event.notes,
        street_address: event.street_address,
        street_address_2: event.street_address_2,
        city: event.city,
        state: event.state,
        postal_code: event.postal_code,
        country: event.country,
        phone_type: event.phone_type,
        phone: event.phone,
        phone_2_type: event.phone_2_type,
        phone_2: event.phone_2,
        leads_generate_date: event.leads_generate_date,
        revenue: event.revenue,
        lead_revenues_attributes: notes,
      },
    };
    dispatch({
      type: 'POST_LEAD',
      payload: attrs,
      callback: response => {
        if (response.status === 200) {
          RedirectCheck(isImpersonate, path, '/', history);

          const successMessage = 'Lead ' + response?.data?.meta?.message;
          message.success(successMessage);
        }
      },
    });
    form.setFieldsValue({
      first_name: '',
      last_name: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      phone_type: '',
      phone: '',
      phone_2_type: '',
      phone_2: '',
      email: '',
      leads_generate_date: '',
      category: undefined,
      status: undefined,
      revenue: '',
      notes: '',
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      first_name: '',
      last_name: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
      phone_type: 1,
      phone: '',
      phone_2_type: 2,
      phone_2: '',
      email: '',
      leads_generate_date: '',
      category: undefined,
      status: undefined,
      revenue: '',
      notes: '',
    });
  }, [form]);
  return (
    <>
      <div className="lead-title">
        <h2>Add Lead</h2>
        <span>
          <NavLink to={isImpersonate ? path : '/'}>(back to dashboard)</NavLink>
        </span>
      </div>
      <Form form={form} onFinish={onFinish}>
        <div className="row">
          <div className="col-md-5">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 custom-required text-end">
                  <label htmlFor="">First Name</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'First Name is required',
                      },
                    ]}
                  >
                    <Input type={'text'} placeholder={'First name'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 custom-required text-end">
                  <label htmlFor="">Last Name</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Last Name is required',
                      },
                    ]}
                  >
                    <Input type={'text'} placeholder={'Last name'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Street Address 1</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    name="street_address"
                  >
                    <Input type={'text'} placeholder={'Street address 1'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Street Address 2</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="street_address_2"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input type={'text'} placeholder={'Street address 2'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">City</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input type={'text'} placeholder={'city'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">
                    {countryCode === 'CA' ? 'Province' : 'State'}
                  </label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="state"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const isWord = /^[a-zA-z.]+$/;
                          if (isWord.test(value) && value)
                            return Promise.resolve();
                          else if (!isWord.test(value) && value)
                            return Promise.reject(
                              new Error('Characters and "." only'),
                            );
                          else return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      type={'text'}
                      placeholder={countryCode === 'CA' ? 'Province' : 'state'}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">
                    {countryCode === 'US' ? 'Zip Code' : 'Postal Code'}
                  </label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="postal_code"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      type={'text'}
                      placeholder={
                        countryCode === 'US' ? 'Zip code' : 'Postal code'
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Country</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <SelectInput
                      options={countries}
                      placeholder="Please select a country"
                      onChange={value => setcountryCode(value)}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-5 d-flex justify-content-between mt-3">
                <Button
                  onClick={() => {
                    RedirectCheck(isImpersonate, path, '/', history);
                  }}
                  className="custom-white-btn lead-detail-delete"
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  className="custom-green-btn lead-detail-submit "
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 custom-required text-end">
                  <label htmlFor="">Phone 1</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="custom-form-input-wrap2">
                      <Form.Item
                        name="phone_type"
                        rules={[
                          {
                            required: true,
                            message: 'Type required',
                          },
                        ]}
                      >
                        <SelectInput
                          value={form.getFieldValue('phone_type')}
                          options={PHONE_TYPES}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="custom-form-input-wrap2 custom-required">
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            type: 'string',
                            min: 10,
                            max: 10,
                            message: 'must be exactly 10 digits',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const isInteger = /^[0-9]+$/;
                              if (isInteger.test(value))
                                return Promise.resolve();
                              else
                                return Promise.reject(
                                  new Error('Must only contain digits'),
                                );
                            },
                          }),
                          {
                            required: true,
                            message: 'Phone number is required',
                          },
                        ]}
                      >
                        {/* <Input
                          type="number"
                          placeholder={'Enter phone number'}
                        /> */}
                        <InputPhone
                          country={'us'}
                          onlyCountries={['us']}
                          disableCountryCode
                          inputProps={{
                            required: true,
                            autoFocus: false,
                            focus: false,
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2  text-end">
                  <label htmlFor="">Phone 2</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="custom-form-input-wrap2">
                      <Form.Item
                        name="phone_2_type"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <SelectInput
                          options={PHONE_TYPES}
                          onChange={value => {
                            form.setFieldsValue({ phone_2: '' });
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="custom-form-input-wrap2">
                      <Form.Item
                        name="phone_2"
                        rules={[
                          {
                            type: 'string',
                            min: 10,
                            max: 10,
                            message: 'must be exactly 10 digits',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const isInteger = /^[0-9]+$/;
                              if (isInteger.test(value) && value)
                                return Promise.resolve();
                              else if (!isInteger.test(value) && value)
                                return Promise.reject(
                                  new Error('Must only contain digits'),
                                );
                              else return Promise.resolve();
                            },
                          }),
                          {
                            required: false,
                          },
                        ]}
                      >
                        <InputPhone
                          country={'us'}
                          onlyCountries={['us']}
                          disableCountryCode
                          inputProps={{
                            autoFocus: false,
                            focus: false,
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 custom-required text-end">
                  <label htmlFor="">Email</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ]}
                  >
                    <Input type={'text'} placeholder={'Email address'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="custom-form-input-wrap2 text-end"></div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 custom-required text-end">
                  <label htmlFor="">Date</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="leads_generate_date"
                    rules={[
                      {
                        required: true,
                        message: 'Date is required.',
                      },
                    ]}
                  >
                    <DatePicker
                      format={DETAIL_DATE_FORMAT}
                      disabledDate={disableFutureDates}
                      placeholder={'Select'}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 custom-required text-end">
                  <label htmlFor="">Category</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: 'Category is required.',
                      },
                    ]}
                  >
                    <SelectInput
                      options={categories}
                      placeholder="please select a category"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 custom-required text-end">
                  <label htmlFor="">Status</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: 'Status is required',
                      },
                    ]}
                  >
                    <SelectInput
                      onChange={e => {
                        setleadStatus(e);
                        setShowAddNoteModal(true);
                      }}
                      placeholder="please select a lead status"
                      options={LEAD_STATUSES}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Revenue</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="revenue"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h6>$0.00</h6>
                      {/* <button className="custom-white-btn">Add Revenue</button> */}
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="notes-wrapper">
          <div className="head-bar">
            <h4>Notes</h4>
            <div>
              <CustomModal
                title="Add Note"
                isVisible={showAddNoteModal}
                handleCancel={() => setShowAddNoteModal(false)}
              >
                <NoteForm
                  addRevenue={false}
                  setShowModal={() => setShowAddNoteModal(false)}
                  setNotes={setNotes}
                  notes={notes}
                  leadStatus={leadStatus}
                  onSubmit={() => setShowAddNoteModal(false)}
                  addNote={!leadStatus}
                  key={Math.floor(Math.random() * 1000)}
                />
              </CustomModal>
              <button
                onClick={e => {
                  e.preventDefault();
                  setleadStatus('');
                  setShowAddNoteModal(true);
                }}
                className="custom-white-btn"
              >
                Add Notes
              </button>
            </div>
          </div>
          <NotesContent
            userName={user.first_name}
            notes={notes.map(item => {
              return { attributes: { ...item } };
            })}
            isUpdate={false}
          />
        </div>
      </Form>
    </>
  );
});
type NoteType = {
  revenue: string;
  notes: string;
  leadStatus: string;
}[];
