/* eslint-disable jsx-a11y/anchor-is-valid */
import { useProfileSlice } from './slice';
import { RedirectCheck } from 'utils/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import '../../components/DataTable/DataTable.scss';
import { profileConstants } from './slice/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Switch,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Avatar,
  Tooltip,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { selectisImpersonate, selectpath } from 'app/slice/selectors';
import {
  selectUser,
  selectIsLoading,
  selectSignOutLoading,
} from '../SignIn/slice/selectors';
import InputPhone from 'app/components/InputPhone';
import { Spin } from 'antd';
import { STATES } from 'utils/constants/constants';
import { getDomainName, validURL } from 'utils';
import { CustomModal } from 'app/components/CustomModal';
const Option = Select.Option;
export const Profile = memo(() => {
  useProfileSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const profileInfo: any = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const signoutLoading = useSelector(selectSignOutLoading);
  const isImpersonate = useSelector(selectisImpersonate);
  const path = useSelector(selectpath);
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [countryStates, setcountryStates] = React.useState<any>([]);
  const [profileImage, setProfileImage] = React.useState<any>('');
  const [showDeleteIcon, setShowDeleteIcon] = React.useState<Boolean>(false);
  const [emailModalOpen, setEmailModalOpen] = React.useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      first_name: profileInfo?.first_name,
      last_name: profileInfo?.last_name,
      email: profileInfo?.email,
      category: profileInfo?.category,
      contact_no: profileInfo?.contact_no,
      company_url: profileInfo?.company_urls,
      company_name: profileInfo?.company_name,
      image: profileInfo?.image,
      leads_sms_enabled: profileInfo?.leads_sms_enabled,
    });
    console.log('profileeeee', profileInfo);
    setcountryStates(STATES[profileInfo?.country]);
    emailForm.setFieldsValue({ newEmail: '' });
  }, [profileInfo, form]);

  const getNumberArray = inputValue =>
    inputValue.length > 0 && typeof inputValue[0] === 'number'
      ? inputValue
      : inputValue.map(item => item?.value);

  const onFinish = event => {
    const attrs = {
      id: profileInfo?.id,
      first_name: event?.first_name,
      last_name: event?.last_name,
      contact_no: event?.contact_no,
      company_urls: getDomainName(event?.company_url),
      company_name: event?.company_name,
      image: profileImage,
      leads_sms_enabled: event.leads_sms_enabled,
      default_lead_sort: event.default_sort,
    };

    dispatch({
      type: profileConstants.UPDATE_PROFILE_INFO,
      payload: attrs,
    });
  };

  const onFinishUpdateEmail = event => {
    const attrs = {
      id: profileInfo?.id,
      email: event?.newEmail,
    };
    dispatch({
      type: profileConstants.UPDATE_PROFILE_INFO,
      payload: attrs,
    });
    setEmailModalOpen(false);
  };
  const deleteProfilePicture = () => {
    setProfileImage('');
    dispatch({
      type: profileConstants.DELETE_PROFILE_PICTURE,
      payload: { id: profileInfo?.id },
    });
  };

  const toBase64 = profileImage => {
    const file = profileImage;
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result?.toString());
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = (e: React.ChangeEvent<any>) => {
    setProfileImage(toBase64(e.target.files[0]));
  };
  const sortByArray = [
    { label: 'By Color Status', value: 'by_color_status' },
    { label: 'By Lead Date', value: 'by_lead_date' },
  ];

  const handleToggle = (e: any) => {
    dispatch({
      type: profileConstants.UPDATE_PROFILE_INFO,
      payload: {
        id: profileInfo?.id,
        leads_sms_enabled: e,
      },
      callback: () => {
        dispatch({
          type: profileConstants.UPDATE_PROFILE_INFO,
          payload: {
            id: profileInfo?.id,
            leads_sms_enabled: profileInfo?.leads_sms_enabled,
          },
        });
      },
    });
  };

  return (
    <Spin
      spinning={isLoading || signoutLoading}
      tip={signoutLoading ? 'Signing Out...' : 'Profile Loading...'}
      size="large"
    >
      <CustomModal
        title="Update Email"
        isVisible={emailModalOpen}
        handleCancel={() => setEmailModalOpen(false)}
      >
        <div>
          <div className="d-flex flex-direction-row align-items-center">
            <div className="custom-form-input-wrap2 text-end col-3 px-2">
              <label htmlFor="">Current Email</label>
            </div>
            <div className="custom-form-input-wrap2 col-8">
              <Input value={profileInfo?.email}></Input>
            </div>
          </div>
          <Form
            form={emailForm}
            onFinish={onFinishUpdateEmail}
            className="mt-2"
          >
            <div className=" d-flex flex-direction-row align-items-center">
              <div className="custom-form-input-wrap2 text-end col-3 px-2">
                <label htmlFor="">New Email</label>
              </div>
              <div className="custom-form-input-wrap2 col-8">
                <Form.Item
                  name="newEmail"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Email is required',
                    },
                  ]}
                >
                  <Input value="" />
                </Form.Item>
              </div>
            </div>
            <div className="mt-4">
              <div className="d-flex justify-content-center">
                <Button
                  htmlType="submit"
                  className="custom-green-btn me-5 px-5"
                >
                  Save
                </Button>
                <Button
                  onClick={() => setEmailModalOpen(false)}
                  className="custom-white-btn px-5"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </CustomModal>
      <h3>My Profile</h3>
      <Form
        form={form}
        initialValues={{ default_sort: 'by_color_status' }}
        onFinish={onFinish}
        className="mt-2"
      >
        <Form.Item name="image">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Tooltip
              className="col-md-3 justify-content-center"
              title={profileImage ? 'Change Image' : 'Upload Image'}
            >
              <Row
                onMouseOver={() => {
                  setShowDeleteIcon(true);
                }}
                onMouseLeave={() => {
                  setShowDeleteIcon(false);
                }}
              >
                <Col className="col-md-1">
                  <Col>
                    {showDeleteIcon && (
                      <CloseCircleOutlined
                        onClick={deleteProfilePicture}
                        className="position-fixed"
                      />
                    )}
                  </Col>
                </Col>
                <Col pull={1}>
                  <Col
                    onClick={() => {
                      document.getElementById('image1')?.click();
                    }}
                  >
                    <Avatar
                      size={150}
                      src={
                        profileImage === ''
                          ? profileInfo.image_url
                          : profileImage
                      }
                    />
                  </Col>
                </Col>
              </Row>
            </Tooltip>
            <input
              id="image1"
              accept="image/*"
              type="file"
              onChange={handleUpload}
              style={{ display: 'none' }}
            />
          </div>
        </Form.Item>

        <div className="row">
          <div className="col-md-5">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">First Name</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'First Name is required',
                      },
                    ]}
                  >
                    <Input type={'text'} placeholder={'First name'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Email</label>
                </div>
              </div>
              <div className="col-md-8 mt-2">
                <div className="custom-form-input-wrap2 ">
                  <Form.Item name="email">
                    <input
                      type={'text'}
                      placeholder={'Email address'}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
                <a
                  className="underlined"
                  onClick={() => {
                    setEmailModalOpen(true);
                  }}
                >
                  {' '}
                  Change Email
                </a>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Studio Name</label>
                </div>
              </div>

              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Studio Name is required',
                      },
                    ]}
                    name="company_name"
                  >
                    <Input
                      type={'text'}
                      placeholder={'Enter Studio Name here'}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <div>
                  <a
                    onClick={() => {
                      const body = {
                        user: {
                          email: profileInfo?.email,
                        },
                      };
                      dispatch({
                        type: profileConstants.CHANGE_PASSWORD,
                        payload: body,
                        callback: response => {
                          if (response.status === 200) {
                            const successMessage =
                              response?.data?.meta?.message;
                            message.success(
                              `${successMessage} password change link sent to your email`,
                            );
                          }
                        },
                      });
                    }}
                    className="underlined"
                  >
                    Change Password
                  </a>
                </div>
              </div>
            </div>
            {/* Commented code will be needed in future */}
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Default Sort</label>
                </div>
              </div>

              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item name="default_sort">
                    <Select
                      style={{ width: 120 }}
                      options={sortByArray.map(item => ({
                        label: item.label,
                        value: item.value,
                      }))}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Last Name</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Last Name is required',
                      },
                    ]}
                  >
                    <Input type={'text'} placeholder={'Last name'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Phone</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="contact_input">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Phone is required.',
                      },
                    ]}
                    name="contact_no"
                  >
                    <InputPhone
                      country={'us'}
                      onlyCountries={['us']}
                      disableCountryCode
                      inputProps={{
                        required: true,
                        autoFocus: false,
                        focus: false,
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">SMS Alert</label>
                </div>
              </div>

              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item>
                    <Switch
                      checked={profileInfo?.leads_sms_enabled}
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={e => handleToggle(e)}
                    ></Switch>
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end">
                  <label htmlFor="">Studio Website</label>
                </div>
              </div>

              <div className="col-md-8">
                <div className="contact_input">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Studio website is required',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const fieldValue = getFieldValue('company_url');

                          if (Array.isArray(fieldValue) && value.length > 0) {
                            for (let domain of fieldValue) {
                              const trimmedDomain = domain.trim();
                              if (!trimmedDomain.endsWith('.com')) {
                                return Promise.reject(
                                  'Please enter a valid domain with .com extension',
                                );
                              }
                            }
                          } else {
                            return Promise.reject(
                              'Please enter at least one domain',
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    name={'company_url'}
                  >
                    <Select
                      mode="tags"
                      style={{ width: '100%', zIndex: '9999' }}
                      placeholder="Enter studio websites here"
                      // disabled={!isImpersonate}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-5">
            <div className="d-flex justify-content-end">
              <Button htmlType="submit" className="custom-green-btn me-5 px-5">
                Save
              </Button>
              <Button
                onClick={() => RedirectCheck(isImpersonate, path, '/', history)}
                htmlType="submit"
                className="custom-white-btn px-5"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  );
});
export default Profile;
