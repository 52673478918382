import { getReq, putReq } from 'config/Requests';
import { archivedLeadsActions as actions } from '.';
import { archivedLeadsConstants } from './constants';
import { handleError } from '../../../../../src/utils';
import { getAuth } from '../../../../../src/utils/Auth';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectisImpersonate, selectuserId } from 'app/slice/selectors';
function* fetchArchivedLeadsPageWise(obj) {
  const { pageNo, per_page } = obj.payload;
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const order = '';
  const field = '';
  try {
    yield put(actions.setLoading(true));
    let requestUrl = `/leads?is_archived=true&page=${pageNo}&per_page=${per_page}&order=${order}&field=${field} &status=${obj?.payload?.filters}`;
    if (obj?.payload?.is_search) {
      requestUrl = `/leads?is_archived=true&page=${pageNo}&per_page=${per_page}&full_name=${obj?.payload?.full_name}&status=${obj?.payload?.filters}`;
    }
    if (obj?.payload?.is_sort) {
      requestUrl = `/leads?is_archived=true&page=${pageNo}&per_page=${per_page}&order=${order}&field=${field}&full_name=${obj?.payload?.full_name}`;
    }
    const response = yield call(getReq, requestUrl, auth);
    yield put(
      actions.fetchArchivedLeadsSuccess({
        archivedLeads: response.data,
        pageNo: pageNo,
        per_page: per_page,
        is_search: obj?.payload?.is_search,
        is_sort: obj?.payload?.is_sort,
        order: obj?.payload?.order,
        is_delete: obj?.payload?.is_delete,
        filters: obj?.payload?.filters,
        is_filter: obj?.payload?.is_filter,
      }),
    );
  } catch (error) {
    yield put(actions.setLoading(false));
    handleError(error);
    yield put(actions.fetchArchivedLeadsFailed());
  }
}

function* revertLeadStatus(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { payload, callback } = obj;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(
      putReq,
      `/leads/${payload}/revert_lead`,
      {},
      auth,
    );
    callback(response);
    yield put(actions.setLoading(false));
  } catch (error) {
    handleError(error);
    // yield put(actions.clearNotes());
    yield put(actions.setLoading(false));
  }
}
export function* archivedLeadsSaga() {
  yield takeLatest(
    archivedLeadsConstants.FETCH_ARCHIVED_LEADS_PAGE_WISE,
    fetchArchivedLeadsPageWise,
  );
  yield takeLatest(archivedLeadsConstants.REVERT_LEAD_STATUS, revertLeadStatus);
}
