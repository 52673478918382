import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { leadDetailSaga } from './saga';
import { LeadDetailState } from './types';

export const initialState: LeadDetailState = {
  isLoading: false,
  lead: {},
  notes: [],
  error: '',
};

const slice = createSlice({
  name: 'leadDetail',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    fetchLeadSuccess(state, action: PayloadAction<any>) {
      const { lead } = action.payload;
      state.lead = lead;
      state.isLoading = false;
    },
    setNotes(state, action: PayloadAction<any>) {
      const { notes } = action.payload;
      state.notes = notes;
    },
    clearNotes(state) {
      state.notes = [];
    },
    fetchLeadFailed(state) {
      state.lead = {};
      state.isLoading = false;
    },
    clearLead(state) {
      state.lead = {};
    },
  },
});

export const { actions: leadDetailActions } = slice;

export const useLeadDetailSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: leadDetailSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLeadDetailSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
