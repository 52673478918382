/**
 *
 * SignIn
 *
 */
import { Form, Input, message } from 'antd';
import React, { memo, useEffect } from 'react';
import styled from 'styled-components/macro';
import Logo from '../../../../src/images/logo.png';
import './ForgotPassword.scss';
import { selectForgotError } from './slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordConstants } from './slice/constants';
import { useForgotPasswordSlice } from './slice';
import { forgotPasswordActions } from './slice';

interface Props {}

export const ForgotPassword = memo((props: Props) => {
  const [form] = Form.useForm();
  const showInvalidTokenMsg = window.location.search.includes('msg=true');
  const dispatch = useDispatch();
  useForgotPasswordSlice();
  const error = useSelector(selectForgotError);
  const onFinish = event => {
    const body = {
      user: {
        email: event.email,
      },
    };
    dispatch({
      type: forgotPasswordConstants.FORGOT_PASSWORD,
      payload: body,
      callback: response => {
        message.success(
          'Passward reset link sent on your email please check your email',
        );
      },
    });
  };
  useEffect(() => {
    return () => {
      dispatch(forgotPasswordActions.clearError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (showInvalidTokenMsg)
      message.error(
        'Invalid Reset password toekn. please check most recent mail or send new reset link.',
      );
  }, []);

  return (
    <Div className="sign-in-wrapper">
      <div className="register-form">
        <div className="row w-100">
          <div className="col-md-6">
            <div className="forgot-form">
              <div className="add-lead-table-wrap">
                <div>
                  <h1 className="main-heading">FORGOT PASSWORD</h1>
                </div>
                <div className="row">
                  <Form form={form} onFinish={onFinish}>
                    <div className="col-12 mb-5">
                      <div className="custom-form-input-wrap">
                        <label htmlFor="">Email</label>
                        <Form.Item
                          name={'email'}
                          rules={[
                            {
                              required: true,
                              message: 'Email is required',
                            },
                          ]}
                        >
                          <Input
                            type={'email'}
                            placeholder={'Type your email here'}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex justify-content-center">
                        <button type="submit" className="custom-green-btn px-5">
                          Send
                        </button>
                      </div>
                    </div>
                    {error?.response?.data?.messages.length > 0 &&
                    error?.response?.data?.messages[0] ? (
                      <div className="d-flex justify-content-center mt-2">
                        <span className="custom-error">
                          {error?.response?.data?.messages[0]}{' '}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div className="right-box">
              <div className="brand-logo">
                <div>
                  <img src={Logo} alt="" />
                </div>
              </div>
              <div className="logo-details">
                <p>
                  LiftOff Leads & SEO is an internet marketing company that
                  serves professional photographers who utilize the business
                  practices taught by Megan DiPiero Coaching.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div>
  );
});

const Div = styled.div``;
