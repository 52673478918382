import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.payment || initialState;

export const selectPayment = createSelector([selectSlice], state => state);

export const selectCoupon = createSelector(
  [selectSlice],
  state => state.coupon,
);
export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectError = createSelector([selectSlice], state => state.error);
export const selectSubscriptionError = createSelector(
  [selectSlice],
  state => state.subscriptionError,
);
export const selectClientSecret = createSelector(
  [selectSlice],
  state => state.client_secret,
);
export const selectIsPaymentSuccessFull = createSelector(
  [selectSlice],
  state => state.isPaymentSuccessfull,
);
