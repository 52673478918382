import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { formatPhone } from 'utils';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import { toTitlecase } from 'utils/StringUtils/stringUtils';
import { CustomSortIcon } from 'app/components/CustomSortIcon';
export const dataTableColoumns = (sortParams, setrecordId, setopenRevert) => [
  {
    title: ({ sortColumns }) =>
      CustomSortIcon('Date/time', 'created_at', sortColumns),
    dataIndex: 'created_at',
    key: 'created_at',
    render: date => {
      return <span>{moment(date).format('M/DD/YYYY hh:mm a')}</span>;
    },
    defaultSortOrder: sortParams.field === 'created_at' ? sortParams.order : '',
    sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
  },
  {
    title: ({ sortColumns }) =>
      CustomSortIcon('Name', 'last_name', sortColumns),
    dataIndex: 'last_name',
    key: 'last_name',
    defaultSortOrder: sortParams.field === 'last_name' ? sortParams.order : '',
    render: (text, record) => {
      let full_name = record?.full_name;
      if (full_name.length > 30) {
        full_name = record?.full_name.trim().substring(0, 30) + '...';
      } else {
        full_name = record?.full_name;
      }

      return (
        <Tooltip title={record?.full_name}>
          <div className="text-capitalize" style={{ whiteSpace: 'nowrap' }}>
            {' '}
            {full_name}
          </div>
        </Tooltip>
      );
    },
    sorter: (a, b) => a?.last_name.localeCompare(b?.last_name),
  },
  {
    title: ({ sortColumns }) => CustomSortIcon('Phone', 'phone', sortColumns),
    dataIndex: 'phone',
    key: 'phone',
    sorter: (a, b) => a.phone - b.phone,
    defaultSortOrder: sortParams.field === 'phone' ? sortParams.order : '',
    render: (value, record) => formatPhone(value, record),
  },
  {
    title: ({ sortColumns }) =>
      CustomSortIcon('Category', 'category', sortColumns),
    dataIndex: 'category',
    key: 'category',
    width: '10%',
    sorter: (a, b) => a?.category.localeCompare(b?.category),
    defaultSortOrder: sortParams.field === 'category' ? sortParams.order : '',
  },
  {
    title: 'Status',
    key: 'status',
    align: 'center',
    dataIndex: 'status',
    render: (status, record) => {
      return (
        <span
          style={{
            color: `${status === 'dead' ? 'red' : 'green'}`,
            fontSize: '18px',
            cursor: 'pointer',
          }}
          onClick={e => {
            e.stopPropagation();
            if (status === 'dead') {
              setrecordId(record?.id);
              setopenRevert(true);
            }
          }}
        >
          {toTitlecase(status?.replaceAll('_', ' '))}
        </span>
      );
    },
  },
  {
    title: 'Revenue',
    key: 'revenue',
    dataIndex: 'revenue',

    render: (text, record) => {
      if (record?.revenue !== '---' && record?.status !== 'dead')
        return formatMoney(record?.revenue, { precision: 2 });
      return '';
    },
  },
];
