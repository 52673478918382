import React, { useEffect } from 'react';
import { Spin } from 'antd';
const Logout = () => {
  useEffect(() => {
    setTimeout(
      () => (window.location.href = window.location.origin + '/sign-in'),
      1000,
    );
  }, []);

  return <Spin tip="Signing Out..." size="large" />;
};

export default Logout;
