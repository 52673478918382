import React from 'react';
import { Button } from 'antd';
import { CustomModal } from '../CustomModal';
import '../scss/_buttons.scss';
const ConfirmModal = ({
  isVisible,
  onCancel,
  title,
  message,
  onSubmit,
  okLabel = 'Confirm',
  cancelLabel = 'Cancel',
  isdelete = false,
  handleCancel,
}) => {
  return (
    <CustomModal
      title={title}
      isVisible={isVisible}
      handleCancel={handleCancel}
    >
      <div>
        <div className="modal-message">{message}</div>
        <div className="col-md-12">
          <div className="d-flex justify-content-end mt-5">
            <Button
              htmlType="submit"
              className="custom-white-btn me-4"
              onClick={onCancel}
            >
              {cancelLabel}
            </Button>
            <Button
              htmlType="submit"
              className={isdelete ? 'custom-red-btn' : 'custom-green-btn'}
              onClick={onSubmit}
            >
              {okLabel}
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
