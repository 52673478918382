import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { signInSaga } from './saga';
import { SignInState } from './types';

export const initialState: SignInState = {
  isLoading: false,
  signoutLoading: false,
  user: {},
  authToken: '',
  categories: [],
  error: '',
};

const slice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSignOutLoading(state, action: PayloadAction<boolean>) {
      state.signoutLoading = action.payload;
    },
    signInUserSuccess(state, action: PayloadAction<any>) {
      const { user } = action.payload;
      state.user = user;
      state.isLoading = false;
      state.error = '';
    },
    updateUser(state, action: PayloadAction<any>) {
      const { user } = action.payload;
      state.user = user;
    },
    signInUserFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.authToken = '';
      state.user = {};
      state.error = action.payload;
    },
    updateUserFailed(state) {
      state.isLoading = false;
      state.authToken = '';
      state.user = {};
    },
    signOutUserSuccess(state) {
      state.isLoading = false;
      state.authToken = '';
      state.user = {};
    },
    setCategories(state, action: PayloadAction<any>) {
      state.categories = action.payload;
      state.isLoading = false;
    },
    clearError(state) {
      state.error = '';
    },
  },
});

export const { actions: signInActions } = slice;

export const useSignInSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: signInSaga });
  return { actions: slice.actions };
};
