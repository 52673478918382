import { Spin } from 'antd';
import Cookies from 'js-cookie';
import AppLayout from '../AppLayout';
import { useAppSlice } from 'app/slice';
import React, { useEffect } from 'react';
import { constants } from 'app/slice/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { selectisLoading, selectuserId } from 'app/slice/selectors';
export const ImPersonateRoute = ({ component: Component, ...rest }) => {
  useAppSlice();
  const dispatch = useDispatch();
  const params: paramType = useParams();
  const isLoading = useSelector(selectisLoading);
  const storeUserId = useSelector(selectuserId);
  const adminAuth = Cookies.get(`${params?.userId}`);
  useEffect(() => {
    if (!storeUserId) {
      if (params?.userId) {
        if (Cookies.get(`${params?.userId}`)) {
          dispatch({
            type: constants.FETCH_TOKEN,
            payload: {
              ...params,
              isUserId: true,
              path: `/impersonate-home/${params?.userId}/${adminAuth}`,
            },
          });
        } else {
          dispatch({
            type: constants.FETCH_TOKEN,
            payload: { ...params, path: window.location.pathname },
          });
        }
      }
    }
  }, [params, storeUserId, dispatch, adminAuth]);

  if (storeUserId) {
    if (!Cookies.get(`${storeUserId}`)) {
      return <Redirect to="/sign-in" />;
    }
  }
  if (isLoading) {
    return (
      <Spin>
        <AppLayout>
          <Component />
        </AppLayout>
      </Spin>
    );
  }
  return (
    <AppLayout>
      {' '}
      <Component />
    </AppLayout>
  );
};
type paramType = { userId: string; adminAuth: string };
