import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.leadDetail || initialState;

export const selectLeadDetail = createSelector([selectSlice], state => state);
export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);
export const selectLead = createSelector([selectSlice], state => state.lead);

export const selectNotes = createSelector([selectSlice], state => state.notes);

export const selectError = createSelector([selectSlice], state => state.error);
