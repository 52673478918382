/**
 *
 * SubscriptionPlan
 *
 */
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import { useSubscriptionPlanSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import { selectIsLoading, selectSubscriptionPlans } from './slice/selectors';
import { subscriptionPlanConstants } from './slice/constants';
import AppHeader from 'app/components/HOC/AppLayout/AppHeader';
import SubscriptionImage from 'images/subscription.jpg';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
import './SubscriptionPlan.scss';
import { HybridPlan } from './hybrid_subscription_plan';
import { Spin } from 'antd';
interface Props {}

export const SubscriptionPlan = memo((props: Props) => {
  useSubscriptionPlanSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const subscriptionPlans = useSelector(selectSubscriptionPlans);
  const loading = useSelector(selectIsLoading);

  const userSubscriptionPlan = subscriptionPlans.filter(
    plan => plan.id === user.plan_id,
  )[0];
  const initAmount = userSubscriptionPlan?.attributes?.initial_amount;

  useEffect(() => {
    dispatch({
      type: subscriptionPlanConstants.FETCH_SUBSCRIPTION_PLAN,
    });
  }, [dispatch]);
  if (loading) {
    return (
      <Div className="main-wrapper">
        <Spin
          spinning={true}
          size="large"
          tip={'Fetching subscription plans...'}
        ></Spin>
      </Div>
    );
  }
  return (
    <>
      {initAmount > 0 ? (
        <>
          <HybridPlan subPlanId={userSubscriptionPlan?.id} />
        </>
      ) : (
        <Div className="main-wrapper">
          <AppHeader />
          <div className="row">
            <div
              className="col-md-6 mt-md-0 mt-5"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="image-wrapper">
                <img
                  src={SubscriptionImage}
                  alt=""
                  width="300px"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-6 text-align">
              <div>
                <div className="mt-5">
                  <h2 className="subscription-heading">Liftoff Leads & SEO</h2>
                  <h4>Monthly Membership</h4>
                </div>
                <div className="mt-5">
                  <h6>
                    {formatMoney(userSubscriptionPlan?.attributes?.amount, {
                      precision: 2,
                    })}{' '}
                    per month includes
                  </h6>
                  <ol className="subscription-list ps-3">
                    <li>
                      Geographic <b>exclusivity</b> for your market
                    </li>
                    <li>SEO enhancement for your website</li>
                    <li>
                      Custom Google Ads campaigns included (no additional fees!)
                    </li>
                    <li>
                      Headshot Mini Marathon<sup>TM</sup> embedded landing page
                    </li>
                    <li>
                      Mission Control Portal for lead and revenue tracking{' '}
                    </li>
                  </ol>
                </div>
                <div>
                  <button
                    onClick={() => {
                      history.push({
                        pathname: '/new-payment-method',
                        state: { subscriptionPlanId: userSubscriptionPlan?.id },
                      });
                    }}
                    className="custom-subscription-btn mb-3"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Div>
      )}
    </>
  );
});

const Div = styled.div``;
