import Cookies from 'js-cookie';
import { constants } from './constants';
import { postReq } from 'config/Requests';
import { appActions as actions } from '.';
import { call, put, takeLatest } from 'redux-saga/effects';
import { signInConstants } from 'app/pages/SignIn/slice/constants';

function* fetchToken(obj) {
  yield put(actions.setLoading(true));
  try {
    const { userId, adminAuth, path, isUserId } = obj?.payload;
    // yield put(actions.setLoadingTrue());
    if (isUserId) {
      yield put(
        actions.fetchTokenSuccess({
          id: userId,
          path: path,
        }),
      );
    } else {
      const response = yield call(
        postReq,
        `/users/${userId}/impersonate`,
        undefined,
        adminAuth,
      );
      Cookies.set(`${userId}`, `Bearer ${response?.data?.token}`);
      yield put(actions.setLoading(true));
      yield put(actions.fetchTokenSuccess({ id: userId, path: path }));
      yield put({ type: signInConstants.FETCH_CATEGORIES });
    }
  } catch (error) {
    yield put(actions.setLoading(false));
  }
}
function* clearVariables() {
  yield put(actions.clearimpersonate());
}
export function* appSaga() {
  yield takeLatest(constants.FETCH_TOKEN, fetchToken);
  yield takeLatest(constants.CLEAR_VARIABLES, clearVariables);
}
