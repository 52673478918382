import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appSaga } from './saga';
import { AppState } from './types';

export const initialState: AppState = {
  isRouteLoading: false,
  isImpersonate: false,
  userId: '',
  path: '',
  isModerator: false,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    fetchTokenSuccess(state, action: PayloadAction<any>) {
      state.isImpersonate = true;
      state.userId = action.payload?.id;
      state.path = action.payload?.path;
      state.isRouteLoading = false;
      state.isModerator = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isRouteLoading = action.payload;
    },

    clearimpersonate(state) {
      state.isImpersonate = false;
      state.userId = '';
    },
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appSaga });
  return { actions: slice.actions };
};
