import { signInActions as actions } from '.';
import { signInConstants } from './constants';
import { handleError } from '../../../../../src/utils';
import { getAuth } from '../../../../../src/utils/Auth';
import { deleteReq, getReq, postReq } from 'config/Requests';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectisImpersonate, selectuserId } from 'app/slice/selectors';
function* signInUserSaga(obj) {
  const { payload, callback } = obj;
  try {
    yield put(actions.setIsLoading(true));
    const response = yield call(postReq, '/auth/sign_in', payload);
    callback(response);
    yield put(
      actions.signInUserSuccess({
        user: {
          ...response?.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      }),
    );
  } catch (error: any) {
    yield put(actions.signInUserFailed(error));
  }
}
function* fetchUser(obj) {
  const { callback } = obj;
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setIsLoading(true));
    const response = yield call(getReq, '/users/authorized_user', auth);
    callback(response);
    yield put(
      actions.updateUser({
        user: {
          ...response?.data?.data?.attributes,
          id: response?.data?.data?.id,
          is_moderator: response?.data?.data?.attributes?.is_moderator
        },
      }),
    );
    if (response?.data?.data?.attributes?.subscribed || isimpersonate)
      yield put({ type: signInConstants.FETCH_CATEGORIES });
  } catch (error) {
    yield put(actions.updateUserFailed());
    handleError(error);
  }
  yield put(actions.setIsLoading(false));
}
function* fetchCategoties() {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setIsLoading(true));
    const response = yield call(getReq, '/user_categories', auth);
    yield put(actions.setCategories(response?.data?.data));
  } catch (error) {
    yield put(actions.setIsLoading(false));
    handleError(error);
  }
  yield put(actions.setIsLoading(false));
}

function* signOutUserSaga(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { callback } = obj;
  try {
    yield put(actions.setSignOutLoading(true));
    let requestUrl = isimpersonate
      ? '/users/stop_impersonating'
      : '/auth/sign_out';
    const response = yield call(
      requestUrl === '/auth/sign_out' ? deleteReq : postReq,
      requestUrl,
      null,
      auth,
    );

    callback(response);
    yield put(actions.signOutUserSuccess());

    yield put(actions.setSignOutLoading(false));
  } catch (error) {
    yield put(actions.setSignOutLoading(false));
    handleError(error);
  }
}

export function* signInSaga() {
  yield takeLatest(signInConstants.SIGN_IN_USER, signInUserSaga);
  yield takeLatest(signInConstants.FETCH_USER, fetchUser);
  yield takeLatest(signInConstants.SIGN_OUT_USER, signOutUserSaga);
  yield takeLatest(signInConstants.FETCH_CATEGORIES, fetchCategoties);
}
