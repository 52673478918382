import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.subscriptionHistory || initialState;

export const selectSubscriptionHistoryState = createSelector(
  [selectSlice],
  state => state,
);

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectError = createSelector([selectSlice], state => state.error);
export const selectSubscriptionHistory = createSelector(
  [selectSlice],
  state => state.subscriptionHistory,
);
export const selectMeta = createSelector([selectSlice], state => state.meta);
export const selectPageNo = createSelector(
  [selectSlice],
  state => state.pageNo,
);
export const selectCurrentCard = createSelector(
  [selectSlice],
  state => state.currentCard,
);
export const selectPlan = createSelector(
  [selectSlice],
  state => state.plan_amount,
);
export const selectUserSubscriptions = createSelector(
  [selectSlice],
  state => state.userSubscriptions,
);
export const selectImpersonatedUser = createSelector(
  [selectSlice],
  state => state.impersonated_user,
);

