import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { profileSaga } from './saga';
import { ProfileState } from './types';

export const initialState: ProfileState = {
  isLoading: false,
  profileInfo: {},
  severity: '',
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    fetchProfileInfoSuccess(state, action: PayloadAction<any>) {
      const { userInfo } = action.payload;
      state.profileInfo = { ...userInfo?.attributes, id: userInfo?.id };
      state.isLoading = false;
    },
    fetchProfileInfoFailed(state) {
      state.profileInfo = {};
      state.isLoading = false;
    },
    changePasswordSuccess(state, action) {
      const { severity } = action.payload;
      state.severity = severity;
      state.isLoading = false;
    },
    changePasswordFailed(state, action) {
      const { severity } = action.payload;
      state.isLoading = false;
      state.severity = severity;
    },
  },
});

export const { actions: profileActions } = slice;

export const useProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: profileSaga });
  return { actions: slice.actions };
};
