import React from 'react';
import AppLayout from '../AppLayout';
import { useSelector } from 'react-redux';
import { isLogin } from 'utils/Auth/index';
import { Route, Redirect } from 'react-router-dom';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { subscribed } = useSelector(selectUser);
  return (
    <Route
      {...rest}
      render={props => {
        if (isLogin(rest?.path) === 'notLoggedIn')
          return <Redirect to="/sign-in" />;
        else {
          if (
            subscribed === false &&
            rest?.path !== '/' &&
            rest?.path !== '/new-payment-method'
          ) {
            return <Redirect to="/" />;
          }
          return (
            <AppLayout>
              <Component {...props} />
            </AppLayout>
          );
        }
      }}
    />
  );
};
