import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.app || initialState;

export const selectApp = createSelector([selectSlice], state => state);

export const selectisLoading = createSelector(
  [selectSlice],
  state => state.isRouteLoading,
);
export const selectisImpersonate = createSelector(
  [selectSlice],
  state => state.isImpersonate,
);
export const selectuserId = createSelector(
  [selectSlice],
  state => state.userId,
);
export const selectisModerator = createSelector(
  [selectSlice],
  state => state.isModerator,
);
export const selectpath = createSelector([selectSlice], state => state.path);
