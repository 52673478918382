/**
 *
 * ArchivedLeads
 *
 */

import './ArchiveLeads.scss';
import { message, Spin } from 'antd';
import { BiSearch } from 'react-icons/bi';
import { RedirectCheck } from 'utils/Auth';
import FilterButton from '../Reports/Filter';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import { dataTableColoumns } from './Columns';
import { useDebounce } from 'utils/useDebounce';
import DataTable from 'app/components/DataTable';
import ConfirmModal from 'app/components/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { archivedLeadsConstants } from './slice/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { archivedLeadsActions, useArchivedLeadsSlice } from './slice';
import { selectSignOutLoading as selectSigninLoading } from 'app/pages/SignIn/slice/selectors';
import {
  selectisImpersonate,
  selectpath,
  selectuserId,
} from 'app/slice/selectors';
import {
  selectArchivedLeads,
  selectIsLoading,
  selectMeta,
  selectpageNo,
  selectperPage,
  selectsortParams,
} from './slice/selectors';
import { filterButtons } from 'utils/constants/constants';
export const ArchivedLeads = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  useArchivedLeadsSlice();
  const archivedLeads = useSelector(selectArchivedLeads);
  const isLoading = useSelector(selectIsLoading);
  const siginOutLoading = useSelector(selectSigninLoading);
  const meta = useSelector(selectMeta);
  const isImpersonate = useSelector(selectisImpersonate);
  const path = useSelector(selectpath);
  const userId = useSelector(selectuserId);
  const storePageNo = useSelector(selectpageNo);
  const perPage = useSelector(selectperPage);
  const [searchQuery, setsearchQuery] = useState<string>('');
  const [open, setopen] = useState(false);
  const [openRevert, setopenRevert] = useState(false);
  const [recordId, setrecordId] = useState('');
  const delayedQuery = useDebounce(searchQuery, 600);
  const sortParams = useSelector(selectsortParams);
  const hasmore = meta?.total_pages > storePageNo;

  useEffect(() => {
    if (delayedQuery === '') {
      dispatch({
        type: archivedLeadsConstants.FETCH_ARCHIVED_LEADS_PAGE_WISE,
        payload: {
          pageNo: 1,
          per_page: perPage,
          order: sortParams?.order,
          field: sortParams?.field,
          filters: sortParams?.filters,
        },
      });
    }

    // eslint-disable-next-line
  }, [dispatch, isImpersonate, delayedQuery]);
  useEffect(() => {
    if (delayedQuery) {
      dispatch({
        type: archivedLeadsConstants.FETCH_ARCHIVED_LEADS_PAGE_WISE,
        payload: {
          pageNo: 1,
          per_page: perPage,
          full_name: delayedQuery,
          is_search: true,
          order: sortParams?.order,
          field: sortParams.field,
          filters: sortParams?.filters,
        },
      });
    }

    // eslint-disable-next-line
  }, [dispatch, isImpersonate, delayedQuery]);
  const handleQuery = e => setsearchQuery(e.target.value);
  const handleSorting = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    dispatch(
      dispatch(
        archivedLeadsActions.setSortParams({
          order: order,
          field: field,
          filters: sortParams?.filters,
        }),
      ),
    );
  };
  const handleDelete = () => {
    dispatch({
      type: archivedLeadsConstants.DELETE_LEAD,
      payload: {
        recordId: recordId,
        pageNo: storePageNo,
        per_page: perPage,
        order: sortParams?.order,
        field: sortParams.field,
      },
    });
    setopen(false);
  };
  const handleSubmit = () => {
    setopenRevert(false);
    dispatch({
      type: archivedLeadsConstants.REVERT_LEAD_STATUS,
      payload: recordId,
      callback: response => {
        if (response.status === 200) {
          const successMessage = 'Lead ' + response?.data?.meta?.message;
          message.success(successMessage);
          RedirectCheck(isImpersonate, path, '/', history);
        }
      },
    });
  };
  const handleFilter = item => {
    dispatch({
      type: archivedLeadsConstants.FETCH_ARCHIVED_LEADS_PAGE_WISE,
      payload: {
        pageNo: 1,
        per_page: perPage,
        full_name: delayedQuery,
        order: sortParams?.order,
        field: sortParams.field,
        filters: item?.value,
        is_filter: true,
      },
    });
  };
  const handleNext = () => {
    dispatch({
      type: archivedLeadsConstants.FETCH_ARCHIVED_LEADS_PAGE_WISE,
      payload: {
        pageNo: storePageNo + 1,
        per_page: perPage,
        order: sortParams?.order,
        field: sortParams.field,
        filters: sortParams?.filters,
      },
    });
  };
  const handleRowClick = (row, event) => {
    const cellIndex = event.target.cellIndex;
    const disabledColumnsIndex = [4];
    if (
      sortParams?.filters === 'dead' &&
      disabledColumnsIndex.includes(cellIndex)
    ) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    RedirectCheck(
      isImpersonate,
      `/impersonate-lead-detail-archived/${row.id}/${userId}`,
      `/lead-detail-archived/${row.id}`,
      history,
    );
  };
  return (
    <Spin
      spinning={isLoading || siginOutLoading}
      size="large"
      tip={siginOutLoading ? 'Signing Out...' : 'Fetching Leads...'}
    >
      <ConfirmModal
        isVisible={open}
        handleCancel={() => setopen(false)}
        title="Delete Confirmation"
        onCancel={() => setopen(false)}
        isdelete
        message="Are you sure? you want to delete this lead?"
        onSubmit={handleDelete}
      />
      <ConfirmModal
        isVisible={openRevert}
        handleCancel={() => setopenRevert(false)}
        title="Revert Lead Confirmation"
        onCancel={() => setopenRevert(false)}
        isdelete
        message="Would you like to restore this lead to the active dashboard?"
        okLabel="Yes"
        cancelLabel="Cancel"
        onSubmit={handleSubmit}
      />
      <>
        <div className="row">
          <div className="col-6 d-flex align-items-center">
            <h3>Archives</h3>
          </div>

          <div className="col-2 col-lg-3 mt-3 d-flex pe-2 justify-content-end">
            <div className="d-flex justify-content-between">
              {filterButtons.map((item, index) => (
                <FilterButton
                  key={index}
                  label={item?.label}
                  onClick={() => handleFilter(item)}
                  filter={sortParams?.filters}
                  value={item?.value}
                />
              ))}
            </div>
          </div>
          <div className="col-4 col-lg-3 mb-4 mt-3">
            <div className=" search-bar-wrap">
              <span className="search-icon">
                {' '}
                <BiSearch />{' '}
              </span>
              <input
                type="text"
                className="custom-search-bar"
                placeholder="Search"
                onChange={handleQuery}
              />
            </div>
          </div>

          <InfiniteScroll
            dataLength={archivedLeads?.length}
            next={handleNext}
            hasMore={hasmore}
            loader={<></>}
            height="70vh"
            className="archive-wrapper"
          >
            <DataTable
              columns={dataTableColoumns(
                sortParams,
                setrecordId,
                setopenRevert,
              )}
              data={archivedLeads}
              pagination={false}
              onChange={handleSorting}
              onRowClick={handleRowClick}
              meta={meta}
              currentPageNo={storePageNo}
            />
          </InfiniteScroll>
        </div>
      </>
    </Spin>
  );
});
