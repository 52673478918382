import moment from 'moment';
import React from 'react';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import { LEAD_STATUS_COLOR } from 'utils/constants/constants';
import { useSelector } from 'react-redux';
import { selectisImpersonate } from 'app/slice/selectors';
interface Props {
  notes: any;
  userName: string;
  isUpdate: boolean;
}
const NotesContent = ({ notes, userName, isUpdate }: Props) => {
  const isImpersonate = useSelector(selectisImpersonate);

  const StatusName = ({ item, status }) => (
    <span
      style={{
        color: LEAD_STATUS_COLOR[status]?.color,
      }}
    >
      {LEAD_STATUS_COLOR[status]?.name}{' '}
    </span>
  );
  const StatusColorComp = ({ item }) => (
    <b className="text-capitalize">
      {item?.attributes?.lead_status?.old_status &&
      item?.attributes?.lead_status?.new_status ? (
        <>
          (
          <StatusName
            item={item}
            status={item?.attributes?.lead_status?.old_status}
          />
          {' --> '}
          <StatusName
            item={item}
            status={item?.attributes?.lead_status?.new_status}
          />
          )
        </>
      ) : item?.attributes?.lead_status?.new_status ? (
        <StatusName
          item={item}
          status={item?.attributes?.lead_status?.new_status}
        />
      ) : (
        ''
      )}
    </b>
  );
  const NoteRow = ({ item }) => {
    if (
      !item?.attributes?.notes &&
      !item?.attributes?.revenue &&
      !(
        item?.attributes?.lead_status?.old_status &&
        item?.attributes?.lead_status?.new_status
      ) &&
      !item?.attributes?.lead_status
    ) {
      return null;
    }
    return (
      <div className="col-12">
        <div className="row">
          <div className="col-md-3">
            <div className="date">
              {' '}
              {item?.attributes?.created_at
                ? moment(item?.attributes?.created_at).format(
                    'MM/DD/YYYY hh:mm a',
                  )
                : moment().format('MM/DD/YYYY hh:mm a')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail">
              {item?.attributes?.revenue
                ? formatMoney(item?.attributes?.revenue, { precision: 2 })
                : null}
              {isUpdate ? (
                <StatusColorComp item={item} />
              ) : item?.attributes?.lead_status ? (
                <b>{item?.attributes?.lead_status}</b>
              ) : (
                <span></span>
              )}
              <p
                style={{
                  color: item?.attributes?.username === 'system' ? 'green' : '',
                }}
              >
                {' '}
                {item?.attributes?.notes}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="name">{renderUserName(item)}</div>
          </div>
        </div>
      </div>
    );
  };
  const renderUserName = item => {
    if (!isUpdate && isImpersonate) {
      return 'admin';
    } else {
      if (item?.attributes?.username) {
        return item?.attributes?.username;
      } else {
        return userName;
      }
    }
  };
  return (
    <div
      className={
        isUpdate ? 'notes-content col-12 ps-5' : 'notes-content col-12'
      }
    >
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-3">
              <div className="heading">
                <h5>Date</h5>
              </div>
            </div>
            <div className="col-md-6">
              <div className="heading">
                <h5>Description</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div className="heading">
                <h5>Name</h5>
              </div>
            </div>
          </div>
        </div>
        {notes?.map((item: NotesType) => (
          <NoteRow item={item} />
        ))}
      </div>
    </div>
  );
};

type NotesType = {
  attributes: {
    lead_id: string;
    revenue: string;
    notes: string;
    created_at: Date;
    lead_status: { new_status: string; old_status: string } | null;
    username: string;
  };
};
export default NotesContent;
