import { handleError } from './../../../../utils/index';
import { getReq, putReq, patchReq } from 'config/Requests';
import { getAuth } from '../../../../../src/utils/Auth';
import { subscriptionHistoryActions as actions } from '.';
import { subscriptionHistoryConstants } from './constants';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  selectisImpersonate,
  selectisModerator,
  selectuserId,
} from 'app/slice/selectors';
import { notification } from 'antd';

function* getCurrentCard(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { payload } = obj;
  try {
    yield put(actions.setLoadingTrue());
    const response = yield call(
      getReq,
      `/cards/get_user_card?user_id=${payload}`,
      auth,
    );
    if (response.status === 200) {
      yield put(actions.setCurrentCard(response.data));
      yield put(actions.clearError());
    } else {
    }
    yield put(actions.setLoadingFalse());
  } catch (error) {
    yield put(actions.setLoadingFalse());
  }
}

function* getImpersonatedUser(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  // const { payload } = obj;
  try {
    yield put(actions.setLoadingTrue());
    if (isimpersonate) {
      const response = yield call(getReq, `/users/impersonated_user`, auth);
      if (response.status === 200) {
        yield put(actions.setImpersonatedUser(response.data));
      } else {
      }
    }
    yield put(actions.setLoadingFalse());
  } catch (error) {
    yield put(actions.setLoadingFalse());
  }
}

function* cancelSubscription(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  // const ismoddd = yield select(selectisModerator)
  let auth = getAuth(isimpersonate, userId);
  const {
    payload: { user_subscription, is_restart },
    successCallback,
  } = obj;
  try {
    yield put(actions.setLoadingTrue());
    let requestURL = is_restart
      ? `/user_subscriptions/restart_subscription`
      : `/user_subscriptions/cancel_subscription`;
    const params = { user_subscription: user_subscription };
    yield call(putReq, requestURL, params, auth);
    successCallback();
  } catch (error) {
    yield put(actions.setLoadingFalse());
    handleError(error);
  }
}

function* getSubscriptionHistoryPageWise(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const { pageNo, per_page } = obj.payload;
  try {
    yield put(actions.setLoadingTrue());
    const response = yield call(
      getReq,
      `/subscription_histories?page=${pageNo}&per_page=${per_page}`,
      auth,
    );
    if (response.status === 200) {
      const subscriptionHistory = response.data.data.map(history => {
        return {
          id: history.id,
          name: history.attributes.name,
          subscription_type: history.attributes.subscription_type,
          exp_month: history.attributes.exp_month,
          exp_year: history.attributes.exp_year,
          last4: history.attributes.last4,
          created_at: history.attributes.created_at,
          pm_id: history.attributes.pm_id,
          card_brand: history.attributes.card_brand,
          charged_amount: history.attributes.charged_amount,
          total_amount: history.attributes.plan_amount,
          discounted_amount: history.attributes.discounted_amount,
          service_period: history.attributes.service_period,
          plan_description: history.attributes.plan_description,
          coupon_code: history.attributes.coupon_code,
          to_date: history.attributes.to_date,
          status: history.attributes.status,
          expires_at: history.attributes.expires_at,
        };
      });
      yield put(actions.setSubscriptionHistory(subscriptionHistory ?? []));
      // console.log(subscriptionHistory);
      yield put(actions.pageNo(pageNo));
      yield put(actions.setSubscriptionHistoryMeta(response.data.meta ?? {}));
      yield put(actions.clearError());
    } else {
    }
    yield put(actions.setLoadingFalse());
  } catch (error) {
    yield put(actions.setLoadingFalse());
  }
}
function* getSubscriptionPlans(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);

  const USERID = obj.payload.userId;
  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setLoadingTrue());
    const response = yield call(getReq, `users/${USERID}/user_plan`, auth);
    if (response.status === 200) {
      const amount_cents = response?.data?.amount_cents;

      yield put(actions.setPlan(amount_cents));
    }
    yield put(actions.setLoadingFalse());
  } catch (error) {
    yield put(actions.setLoadingFalse());
  }
}
function* getUserSubscriptions(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);

  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setLoadingTrue());
    const response = yield call(getReq, `user_subscriptions`, auth);
    if (response.status === 200) {
      const userSubscriptions = response?.data?.data;

      yield put(actions.setUserSubscriptions(userSubscriptions ?? []));
    }
    yield put(actions.setLoadingFalse());
  } catch (error) {
    yield put(actions.setLoadingFalse());
  }
}

function* renewSubscription(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  const {
    payload: { user_subscription },
    successCallback,
  } = obj;
  try {
    yield put(actions.setLoadingTrue());
    let requestURL = `/user_subscriptions/renew_subscription`;
    const params = { user_subscription: user_subscription };
    yield call(patchReq, requestURL, params, auth);
    notification.success({
      message: 'Renewal Date Updated',
    });
    successCallback();
  } catch (error) {
    yield put(actions.setLoadingFalse());
    handleError(error);
  }
}

export function* subscriptionHistorySaga() {
  yield takeLatest(
    subscriptionHistoryConstants.GET_SUBSCRIPTION_HISTORY_PAGE_WISE,
    getSubscriptionHistoryPageWise,
  );
  yield takeLatest(
    subscriptionHistoryConstants.GET_CURRENT_CARD,
    getCurrentCard,
  );
  yield takeLatest(
    subscriptionHistoryConstants.CANCEL_SUBSCRIPTION,
    cancelSubscription,
  );
  yield takeLatest(
    subscriptionHistoryConstants.RENEW_SUBSCRIPTION,
    renewSubscription,
  );
  yield takeLatest(
    subscriptionHistoryConstants.GET_SUBSCRIPTION_PLAN,
    getSubscriptionPlans,
  );
  yield takeLatest(
    subscriptionHistoryConstants.GET_USER_SUBSCRIPTIONS,
    getUserSubscriptions,
  );
  yield takeLatest(
    subscriptionHistoryConstants.GET_IMPERSONATED_USER,
    getImpersonatedUser,
  );
}
