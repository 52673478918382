import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Spin } from 'antd';
import { CustomModal } from '../CustomModal';
import { DateRangePicker } from 'react-date-range';
import '../scss/_buttons.scss';
import CustomCheckBoxes from '../CheckList';
import { defaultCheckedList } from 'utils';
import { reportsConstants } from 'app/pages/Reports/slice/constants';
import {
  customDefaultRanges,
  renderStaticLabel,
} from 'app/pages/Reports/helper/helper';
import { selectExportLoading } from 'app/pages/Reports/slice/selectors';
import { DateRangeType } from 'app/pages/Reports/slice/types';
import { GrDocumentCsv } from 'react-icons/gr';
const Export = ({
  title,
  okLabel = 'Confirm',
  cancelLabel = 'Cancel',
  isdelete = false,
  storedDateRange,
}) => {
  const dispatch = useDispatch();
  const exportLoading = useSelector(selectExportLoading);
  const [dateRange, setDateRange] = useState<DateRangeType>(storedDateRange);
  const [open, setOpen] = useState<boolean>(false);
  const [filterValue, setfilterValue] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<string[]>(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState<boolean>(true);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [error, seterror] = useState<string>('');
  const [isExport, setIsExport] = useState<boolean>(false);
  const handleSelect = ranges => {
    setDateRange({
      date: [
        {
          startDate: new Date(ranges?.selection?.startDate),
          endDate: new Date(ranges?.selection?.endDate),
          key: 'selection',
        },
      ],
    });
  };
  const handleClosePicker = () => setOpen(false);
  const handleExport = e => {
    e.preventDefault();
    if (!checkAll && checkedList.length < 1) {
      seterror('Please select atleast 1 option from export status');
      return;
    }
    dispatch({
      type: reportsConstants.FETCH_EXPORT_LEAD,
      payload: {
        dateRange: dateRange?.date,
        status_list: checkedList,
        export_all: filterValue,
        setIsExport: setIsExport,
      },
    });
  };
  const onFilterChange = e => {
    setfilterValue(e.target.checked);
  };

  const handleCloseModal = () => {
    setIndeterminate(true);
    setCheckedList(defaultCheckedList);
    setfilterValue(false);
    setCheckAll(false);
    setIsExport(false);
  };
  const handleExportModal = e => {
    e.preventDefault();
    setIsExport(true);
  };
  return (
    <>
      <CustomModal
        title={title}
        isVisible={isExport}
        handleCancel={handleCloseModal}
        width="700px"
      >
        <Spin
          spinning={exportLoading}
          tip="Export to CSV in progress..."
          className="export-spinner"
        >
          <div className="custom-checkboxes">
            <div className="date-range-wrapper">
              <CustomModal
                title=""
                isVisible={open}
                handleCancel={handleClosePicker}
                width="600px"
              >
                <div className="d-flex date-range-picker">
                  <DateRangePicker
                    ranges={dateRange?.date}
                    onChange={handleSelect}
                    staticRanges={customDefaultRanges}
                    inputRanges={[]}
                    editableDateInputs={true}
                    rangeColors={['rgb(44 161 90)']}
                  />
                  <Button className="picker-btn" onClick={handleClosePicker}>
                    Apply
                  </Button>
                </div>
              </CustomModal>
            </div>
            <div>
              <div className="export-range mt-3">
                <span
                  className="date-range-text"
                  onClick={() => setOpen(true)}
                  style={{ width: '100%' }}
                >
                  Date range - {renderStaticLabel(dateRange)}
                </span>
              </div>
              <div className="mt-4">
                <Checkbox checked={filterValue} onChange={onFilterChange}>
                  Export All Leads Database
                </Checkbox>
              </div>
              <h6 className="mt-4">Export Status</h6>
              <div>
                <CustomCheckBoxes
                  checkedList={checkedList}
                  setCheckedList={setCheckedList}
                  indeterminate={indeterminate}
                  setIndeterminate={setIndeterminate}
                  checkAll={checkAll}
                  setCheckAll={setCheckAll}
                />
                {error && checkedList.length < 1 && (
                  <span className="error-msg">{error}</span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex justify-content-end mt-5">
                <Button
                  htmlType="submit"
                  className="custom-white-btn me-4"
                  onClick={handleCloseModal}
                >
                  {cancelLabel}
                </Button>

                <Button
                  htmlType="submit"
                  className={isdelete ? 'custom-red-btn' : 'custom-green-btn'}
                  onClick={handleExport}
                >
                  {okLabel}
                </Button>
              </div>
            </div>
          </div>
        </Spin>
      </CustomModal>

      <Button className="export-button " onClick={handleExportModal}>
        Export{' '}
        <span style={{ marginLeft: '1rem' }}>
          <GrDocumentCsv />
        </span>
      </Button>
    </>
  );
};

export default Export;
