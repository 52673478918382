import moment from 'moment';
import React, { message } from 'antd';
import axios, { AxiosError } from 'axios';
import formatMoney from 'accounting-js/lib/formatMoney.js';
export const firstCaptilize = str => str.charAt(0).toUpperCase() + str.slice(1);
export const disableFutureDates = current => {
  return current > moment(moment(), 'YYYY-MM-DD');
};
export const handleError = error => {
  if (axios.isAxiosError(error)) {
    const err = error as AxiosError;
    if (
      err?.response?.data?.messages.length > 0 &&
      err?.response?.data?.messages[0] ===
        'Reset password token has expired, please request a new one'
    ) {
      message.error(
        'Invalid password reset token. Please look for a more recent email.',
      );
      return;
    }
    if (
      err?.response?.data?.messages.length > 0 &&
      err?.response?.data?.messages[0] === 'Company url has already been taken'
    ) {
      message.error('Website has already been taken');
      return;
    }
    if (err?.response?.data?.message === 'You have not subscribed any plan') {
      return;
    }
    if (err?.response?.data?.state === 'not_registered') {
      const errorMessage =
        'No account exist with these credentials. Please create an account by signup ';
      message.error({
        content: <span>{errorMessage} </span>,
        className: 'custom-msg',
      });
      return;
    }
    if (err?.response?.data?.state === 'not_confirmed') {
      const errorMessage =
        'Your account has not yet been activated. Please contact the administrators ';
      message.error({
        content: (
          <span>
            {errorMessage}{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="mailto:info@liftoffleads.com"
            >
              here
            </a>
          </span>
        ),
        className: 'custom-msg',
      });
      return;
    }
    if (err?.response?.data?.message) {
      message.error(err?.response?.data?.message);
      return;
    }
    if (
      err?.response?.data?.messages.length > 0 &&
      err?.response?.data?.messages[0] === 'Email not found'
    ) {
      message.error({
        content: 'Invalid email address. Please register a new account.',
        className: 'custom-msg-forget',
      });

      return;
    }
    if (err?.response?.data?.error) {
      message.error(err?.response?.data?.error);
      return;
    } else {
      err?.response?.data?.messages?.forEach(m => {
        if (m === 'Reset password token is invalid') {
          message.error({
            content:
              'Invalid password reset token. Please look for a more recent email.',
            className: 'custom-msg-forget',
          });
          return;
        } else {
          message.error(m);
          return;
        }
      });
    }
  }
};
export const formatPhone = (value, record) => {
  const countries = ['US', 'CA'];
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (
    record?.country === 'other' ||
    record?.country === null ||
    record?.country === undefined
  ) {
    let country = 'US';
    if (countries.includes(country)) {
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
    return value;
  } else {
    if (countries.includes(record?.country)) {
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
    return value;
  }
};
export const formatCurrency = (value, ...rest) => {
  if (value || value === 0) return formatMoney(value, ...rest);
  else return '-';
};

export const handleStatusColor = (record, contactTiming) => {
  if (record?.status === 'booked') return 'ant-tag-geekblue';
  if (record?.status === 'dead') return 'ant-tag-red';

  const currentDate = moment();
  const contactTimingHours = contactTiming?.no_of_days * 24;

  let hours;
  if (record?.future_contact_date != null) {
    hours = moment
      .duration(currentDate.diff(moment(record?.future_contact_date)))
      .asHours();
    return hours <= 0
      ? record?.status === 'new_lead'
        ? 'ant-tag-green'
        : 'ant-tag-light-green'
      : hours < contactTimingHours
      ? 'ant-tag-yellow'
      : 'ant-tag-red';
  } else {
    hours = moment
      .duration(currentDate.diff(moment(record?.lead_status_date)))
      .asHours();
    return hours < contactTimingHours
      ? record?.status === 'new_lead'
        ? 'ant-tag-green'
        : 'ant-tag-light-green'
      : hours < contactTimingHours * 2
      ? 'ant-tag-yellow'
      : 'ant-tag-red';
  }
};

export const uniqueArray = result => {
  const ids = result.map(o => o.id);
  return result.filter(({ id }, index) => !ids.includes(id, index + 1));
};
export const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};
export const getDomainName = company_url => {
  const { hostname } = new URL(
    company_url.includes('http') ? company_url : 'https://' + company_url,
  );
  const domainArray = hostname.split(',').map(domain => domain.trim());
  return domainArray;
};

export const plainOptions = [
  'New Lead',
  '2nd contact',
  'Final Contact',
  'Booked',
  'Dead',
  'Completed',
];
export const defaultCheckedList = ['New Lead', 'Final Contact', 'Booked'];
export const countriesOptions = [
  {
    id: 'US',
    name: 'United States',
  },
  {
    id: 'CA',
    name: 'Canada',
  },
];
