import React from 'react';
import { Button } from 'antd';
const FilterButton = ({ label, onClick, filter, value }) => {
  return (
    <Button
      className={
        filter === value ? `btn-filter custom-btn-green` : `btn-filter`
      }
      style={{
        cursor: 'pointer !important',
        fontFamily: 'calibri-bold',
        marginRight: value !== 'yearly' ? '5px' : '',
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default FilterButton;
