import React from 'react';
import moment from 'moment';
import { formatCurrency } from 'utils';
import NoDataFound from 'images/No data-amico.svg';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { DATE_FORMAT } from 'utils/constants/constants';

const CustomTooltip = ({ active, payload }: any) => {
  if (payload) {
    return (
      <div className="custom-tooltip">
        <ul>
          <b>
            {payload.length > 0 && payload[0]?.payload?.month
              ? payload[0]?.payload?.month.replaceAll('-', '/')
              : payload[0]?.payload?.year
              ? payload[0]?.payload?.year
              : moment(payload[0]?.payload?.date).format(DATE_FORMAT)}
          </b>
        </ul>
        <ul>
          Leads Received:{' '}
          {payload.length > 0 && payload[0]?.payload?.total_leads}
        </ul>
        <ul
          style={{
            color:
              payload.length > 0 && payload[0]?.payload?.booked_leads
                ? '#136207'
                : '',
          }}
        >
          Booked Leads:{' '}
          {payload.length > 0 && payload[0]?.payload?.booked_leads}
        </ul>
        <ul
          style={{
            color:
              payload.length > 0 && payload[0]?.payload?.not_booked
                ? '#ff9f88'
                : '',
          }}
        >
          Not Booked: {payload.length > 0 && payload[0]?.payload?.not_booked}
        </ul>
        <ul>
          Leads Revenue:{' '}
          {formatCurrency(
            (payload.length > 0 && payload[0]?.payload?.lead_revenue) ?? 0,
            {
              precision: 2,
              symbol: '$',
            },
          )}
        </ul>
        <ul>
          New Leads: {payload.length > 0 && payload[0]?.payload?.new_leads}
        </ul>
        <ul>
          Completed Leads:{' '}
          {payload.length > 0 && payload[0]?.payload?.completed_leads}
        </ul>
        <ul>
          Dead Leads: {payload.length > 0 && payload[0]?.payload?.dead_leads}
        </ul>
        <ul>
          Conversion:{' '}
          {payload.length > 0 &&
            `${payload[0]?.payload?.conversion_percentage}%`}
        </ul>
      </div>
    );
  }
  return null;
};

const Chart = ({ data, filter, noDataChart }) => {
  const formatXAxis = tickItem => {
    if (filter === 'day') {
      return moment(tickItem).format('M/DD');
    }
    return tickItem;
  };

  if (noDataChart) {
    return <img src={NoDataFound} className="custom-not-data" alt="" />;
  }

  const maxValue = () => {
    return Math.max(...data.map(o => o.total_leads)) + 5;
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 50,
        }}
      >
        <XAxis
          dataKey="date"
          tickFormatter={formatXAxis}
          angle={-45}
          dy={filter === 'month' ? 20 : 15}
          dx={filter === 'month' ? -20 : -15}
        >
          <Label
            position="centerBottom"
            dy={50}
            value="Leads Received Date"
            className="chart-label"
          />
        </XAxis>
        <YAxis dataKey="total_leads" domain={[0, maxValue()]}>
          <Label
            angle={270}
            position="insideLeft"
            offset={10}
            value="Total Recieved Leads"
            className="chart-label"
          />
        </YAxis>
        <Tooltip
          cursor={{ fill: '#edede4' }}
          content={<CustomTooltip />}
          shared
        />
        <Bar dataKey="booked_leads" stackId="one" fill="#97f7bd" opacity=".6" />
        <Bar dataKey="not_booked" stackId="one" fill="#ffc0b3" opacity=".7" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
