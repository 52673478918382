/**
 *
 * VerificationPage
 *
 */
import { Button, message } from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSignUpSlice } from '../SignUp/slice';
import { signUpConstants } from '../SignUp/slice/constants';
import './VerificationPage.scss';
interface Props {}

export const VerificationPage = memo((props: Props) => {
  const dispatch = useDispatch();
  useSignUpSlice();
  const location: any = useLocation();
  const resendVerificationMail = () => {
    dispatch({
      type: signUpConstants.RESEND_CONFIRMATION,
      payload: location.state.enteredEmail,
      callback: respnse => {
        if (respnse.status === 200) {
          message.success(respnse.data.message);
        }
      },
    });
  };
  return (
    <Div className="main-container">
      <div className="content-container">
        <h3>Verification Mail has been sent please check your inbox </h3>
      </div>
      <Button onClick={resendVerificationMail}>Resend</Button>
    </Div>
  );
});

const Div = styled.div``;
