import moment from 'moment';
import './LeadsDetail.scss';
import { RedirectCheck } from 'utils/Auth';
import { disableFutureDates } from 'utils';
import { useLeadDetailSlice } from './slice';
import 'bootstrap/dist/css/bootstrap.min.css';
import NoteForm from 'app/components/NoteForm';
import '../../components/DataTable/DataTable.scss';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import InputPhone from 'app/components/InputPhone';
import ConfirmModal from 'app/components/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { leadDetailConstants } from './slice/constants';
import { useHistory, useParams } from 'react-router-dom';
import { CustomModal } from 'app/components/CustomModal';
import React, { memo, useEffect, useState } from 'react';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
import { SelectInput } from 'app/components/FormUI/SelectInput';
import { selectCategories } from 'app/pages/SignIn/slice/selectors';
import { Button, DatePicker, Form, Input, message, Spin } from 'antd';
import { archivedLeadsConstants } from '../ArchivedLeads/slice/constants';
import { selectIsLoading, selectLead, selectNotes } from './slice/selectors';
import {
  LEAD_STATUSES,
  PHONE_TYPES,
  DETAIL_DATE_FORMAT,
  Countries,
} from 'utils/constants/constants';
import {
  selectisImpersonate,
  selectpath,
  selectuserId,
} from 'app/slice/selectors';
import { selectSignOutLoading } from 'app/pages/SignIn/slice/selectors';
import { BackButton } from 'app/components/BackButton';
import NotesContent from 'app/components/NotesContent';

export const LeadsDetailsPage = memo(props => {
  useLeadDetailSlice();
  const [sourceAttributes, setsourceAttributes] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams<{ id }>();
  const { id } = param;
  const [form] = Form.useForm();
  const lead = useSelector(selectLead);
  const user = useSelector(selectUser);
  const notes = useSelector(selectNotes);
  const isLoading = useSelector(selectIsLoading);
  const signoutLoading = useSelector(selectSignOutLoading);
  const isImpersonate = useSelector(selectisImpersonate);
  const path = useSelector(selectpath);
  const userId = useSelector(selectuserId);
  const categories = useSelector(selectCategories);
  const [openRevert, setopenRevert] = useState<boolean>(false);
  const [countryCode, setcountryCode] = useState<string>('');
  const [open, setopen] = useState<boolean>(false);
  const [showAddNoteModal, setShowAddNoteModal] = useState<boolean>(false);
  const [showAddStatusNoteModal, setShowAddStatusNoteModal] =
    useState<boolean>(false);
  const [fetchLead, setFetchLead] = useState<boolean>(false);
  const [showRevenueModal, setShowRevenueModal] = useState<boolean>(false);
  const isDeadOrCompleted =
    (lead?.attributes?.status === 'dead' && lead?.attributes?.old_status) ||
    (lead?.attributes?.status === 'completed' && lead?.attributes?.old_status)
      ? true
      : false;
  const disabledStatus =
    lead?.attributes?.status === 'dead' ||
    lead?.attributes?.status === 'completed'
      ? true
      : false;
  const onFinish = event => {
    const attrs = {
      lead: {
        id: id,
        user_id: user?.id,
        first_name: event.first_name,
        last_name: event.last_name,
        email: event.email,
        user_category_id: event.category,
        status: event.status,
        notes: event.notes,
        street_address: event.street_address,
        street_address_2: event.street_address_2,
        city: event.city,
        state: event.state,
        postal_code: event.postal_code,
        country: event.country,
        phone_type: event.phone_type,
        phone: event.phone,
        phone_2_type: event.phone_2_type,
        phone_2: event.phone_2,
        leads_generate_date: event.leads_generate_date,
        revenue: event.revenue,
      },
    };
    dispatch({
      type: leadDetailConstants.UPDATE_LEAD,
      payload: attrs,
      callback: response => {
        if (response.status === 200) {
          const successMessage = 'Lead ' + response?.data?.meta?.message;
          message.success(successMessage);
          if (
            response.data.data.attributes.status === 'completed' ||
            response.data.data.attributes.status === 'dead'
          )
            RedirectCheck(
              isImpersonate,
              `/impersonate-archived-leads/${userId}`,
              '/archived-leads',
              history,
            );
        }
      },
    });
  };
  useEffect(() => {
    dispatch({
      type: leadDetailConstants.FETCH_LEAD,
      payload: id,
      callback: response => {
        if (response.status === 200) {
          const attributes = response.data.data.attributes;

          setsourceAttributes(response.data.data.attributes.source);
          form.setFieldsValue({
            first_name: attributes?.first_name,
            last_name: attributes?.last_name,
            street_address: attributes?.street_address,
            street_address_2: attributes?.street_address_2,
            city: attributes?.city,
            state: attributes?.state,
            postal_code: attributes?.postal_code,
            country: attributes?.country,
            phone_type: attributes?.phone_type,
            phone: attributes?.phone,
            phone_2_type: attributes?.phone_2_type,
            phone_2: attributes?.phone_2,
            email: attributes?.email,
            leads_generate_date: moment(attributes?.leads_generate_date),
            category: attributes?.user_category_id,
            status: attributes?.status,
            revenue: attributes?.revenue,
            notes: attributes?.notes,
            source: attributes?.source,
          });
          setcountryCode(attributes?.country);
        }
      },
    });
    dispatch({
      type: leadDetailConstants.FETCH_LEAD_NOTES,
      payload: id,
    });
    return () => {
      dispatch({
        type: leadDetailConstants.CLEAR_LEAD,
      });
    };
  }, [id, dispatch, form, isImpersonate, fetchLead]);

  const handleRevert = () => {
    setopenRevert(true);
  };
  const handleSubmit = () => {
    setopenRevert(false);
    dispatch({
      type: archivedLeadsConstants.REVERT_LEAD_STATUS,
      payload: id,
      callback: response => {
        if (response.status === 200) {
          const successMessage = 'Lead ' + response?.data?.meta?.message;
          message.success(successMessage);
          RedirectCheck(isImpersonate, path, '/', history);
        }
      },
    });
  };
  const isStatusDead = lead?.attributes?.status === 'dead';

  const handleDelete = () => {
    dispatch({
      type: leadDetailConstants.DELETE_LEAD,
      payload: {
        recordId: id,
        callback: response => {
          if (window.location.pathname.includes('lead-detail-archived')) {
            RedirectCheck(
              isImpersonate,
              `/impersonate-archived-leads/${userId}`,
              '/archived-leads',
              history,
            );
          } else {
            RedirectCheck(isImpersonate, path, '/', history);
          }
        },
      },
    });
    setopen(false);
  };
  const handleCallBack = (response, note) => {
    if (response.status === 200) {
      setFetchLead(!fetchLead);
      const successMessage = 'Lead ' + response?.data?.meta?.message;
      message.success(successMessage);
      const attrs = {
        lead_revenue: {
          lead_id: id,
          notes: note,
          revenue: null,
          lead_status: {
            old_status: response?.data?.data?.attributes?.old_status,
            new_status: form.getFieldValue('status'),
          },
        },
      };
      dispatch({
        type: leadDetailConstants.ADD_LEAD_NOTES,
        payload: attrs,
        callback: response => {
          if (response.status === 200) {
            dispatch({
              type: leadDetailConstants.FETCH_LEAD_NOTES,
              payload: id,
            });
            message.success('Note ' + response?.data?.meta?.message);
          }
        },
      });
      if (
        response.data.data.attributes.status === 'completed' ||
        response.data.data.attributes.status === 'dead'
      )
        RedirectCheck(
          isImpersonate,
          `/impersonate-archived-leads/${userId}`,
          '/archived-leads',
          history,
        );
    }
  };
  const handleSubmitNote = note => {
    setShowAddStatusNoteModal(false);
    const attrs = {
      lead: {
        id: id,
        status: form.getFieldValue('status'),
      },
    };
    dispatch({
      type: leadDetailConstants.UPDATE_LEAD,
      payload: attrs,
      callback: response => handleCallBack(response, note),
      errorCallback: () => {
        form.setFieldsValue({
          status: lead?.attributes?.status,
        });
      },
    });
  };

  const handleCancelNote = () => {
    form.setFieldsValue({
      status: lead?.attributes?.status,
    });
    setShowAddStatusNoteModal(false);
  };

  const leadName =
    lead?.attributes?.first_name + ' ' + lead?.attributes?.last_name;
  const isAddRevenue =
    lead?.attributes?.status === 'booked' ||
    lead?.attributes?.status === 'completed';
  return (
    <Spin
      spinning={isLoading || signoutLoading}
      size="large"
      tip={signoutLoading ? 'Signing Out...' : 'Fetching Lead...'}
    >
      <ConfirmModal
        isVisible={openRevert}
        handleCancel={() => setopenRevert(false)}
        title="Revert Lead Confirmation"
        onCancel={() => setopenRevert(false)}
        isdelete
        message="Would you like to restore this lead to the active dashboard?"
        okLabel="Yes"
        cancelLabel="Cancel"
        onSubmit={handleSubmit}
      />
      <ConfirmModal
        isVisible={open}
        handleCancel={() => setopen(false)}
        title="Delete Confirmation"
        onCancel={() => setopen(false)}
        isdelete
        message="Are you sure? you want to delete this lead?"
        onSubmit={handleDelete}
      />
      <>
        <div className="lead-title ">
          {lead?.attributes && <h2>{leadName}</h2>}
          <span>
            <BackButton
              isImpersonate={isImpersonate}
              userId={userId}
              path={path}
            />
          </span>
        </div>

        <Form form={form} onFinish={onFinish}>
          <div className="row">
            {showAddStatusNoteModal && (
              <CustomModal
                title="Add Note"
                isVisible={showAddStatusNoteModal}
                handleCancel={handleCancelNote}
              >
                <NoteForm
                  addRevenue={false}
                  setShowModal={setShowAddStatusNoteModal}
                  handleCancel={handleCancelNote}
                  leadId={id}
                  onSubmit={handleSubmitNote}
                />
              </CustomModal>
            )}
            <div className="col-md-5">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 custom-required text-end">
                    <label htmlFor="">First Name</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'First Name is required',
                        },
                      ]}
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={'First name'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 custom-required text-end">
                    <label htmlFor="">Last Name</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Last Name is required',
                        },
                      ]}
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={'Last name'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">Street Address 1</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      name="street_address"
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={'Street address 1'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">Street Address 2</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      name="street_address_2"
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={'Street address 2'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">City</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      name="city"
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={'city'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">
                      {' '}
                      {countryCode === 'CA' ? 'Province' : 'State'}
                    </label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      name="state"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const isWord = /^[a-zA-z.]+$/;
                            if (isWord.test(value) && value)
                              return Promise.resolve();
                            else if (!isWord.test(value) && value)
                              return Promise.reject(
                                new Error('Characters and "." only'),
                              );
                            else return Promise.resolve();
                          },
                        }),
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={
                          countryCode === 'CA' ? 'Province' : 'state'
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">
                      {' '}
                      {countryCode === 'US' ? 'Zip Code' : 'Postal Code'}
                    </label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      name="postal_code"
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={
                          countryCode === 'US' ? 'Zip code' : 'Postal code'
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">Country</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      name="country"
                    >
                      <SelectInput
                        disabled={isStatusDead}
                        options={Countries}
                        onChange={value => setcountryCode(value)}
                        placeholder="Please select a country"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-5 d-flex justify-content-between mt-3">
                  <Button
                    onClick={() => {
                      setopen(true);
                    }}
                    className="custom-white-btn lead-detail-delete"
                  >
                    Delete
                  </Button>
                  <Button
                    htmlType="submit"
                    className="custom-green-btn lead-detail-submit "
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 custom-required text-end">
                    <label htmlFor="">Phone 1</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="custom-form-input-wrap2">
                        <Form.Item
                          name="phone_type"
                          rules={[
                            {
                              required: true,
                              message: 'Type required',
                            },
                          ]}
                        >
                          <SelectInput
                            disabled={isStatusDead}
                            value={form.getFieldValue('phone_type')}
                            options={PHONE_TYPES}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="custom-form-input-wrap2">
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              type: 'string',
                              min: 10,
                              max: 10,
                              message: 'must be exactly 10 digits',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const isInteger = /^[0-9]+$/;
                                if (isInteger.test(value))
                                  return Promise.resolve();
                                else
                                  return Promise.reject(
                                    new Error('Must only contain digits'),
                                  );
                              },
                            }),
                            {
                              required: true,
                              message: 'Phone number is required',
                            },
                          ]}
                        >
                          <InputPhone
                            disabled={isStatusDead}
                            country={'us'}
                            onlyCountries={['us']}
                            disableCountryCode
                            inputProps={{
                              required: true,
                              autoFocus: false,
                              focus: false,
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">Phone 2</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="custom-form-input-wrap2">
                        <Form.Item
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          name="phone_2_type"
                        >
                          <SelectInput
                            disabled={isStatusDead}
                            options={PHONE_TYPES}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="custom-form-input-wrap2">
                        <Form.Item
                          name="phone_2"
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const isInteger = /^[0-9]+$/;
                                if (isInteger.test(value) && value)
                                  return Promise.resolve();
                                else if (!isInteger.test(value) && value)
                                  return Promise.reject(
                                    new Error('Must only contain digits'),
                                  );
                                else return Promise.resolve();
                              },
                            }),
                            {
                              required: false,
                            },
                          ]}
                        >
                          <InputPhone
                            disabled={isStatusDead}
                            country={'us'}
                            onlyCountries={['us']}
                            disableCountryCode
                            inputProps={{
                              autoFocus: false,
                              focus: false,
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 custom-required text-end">
                    <label htmlFor="">Email</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Email is required',
                        },
                      ]}
                    >
                      <Input
                        disabled={isStatusDead}
                        type={'text'}
                        placeholder={'Email address'}
                      />
                    </Form.Item>
                    {isStatusDead ? (
                      <span className="email-icon-disabled">
                        <BsFillEnvelopeFill />
                      </span>
                    ) : (
                      <a href={`mailto:${lead?.attributes?.email}`}>
                        <span className="email-icon">
                          <BsFillEnvelopeFill />
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {sourceAttributes !== null && (
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="custom-form-input-wrap2 custom-required text-end">
                      <label htmlFor="">Source</label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="custom-form-input-wrap2">
                      <Form.Item name="source">
                        <Input disabled={true} type={'text'} placeholder={''} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              )}

              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 custom-required text-end">
                    <label htmlFor="">Date</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      name="leads_generate_date"
                    >
                      <DatePicker
                        format={DETAIL_DATE_FORMAT}
                        placeholder={'Select'}
                        disabled={isStatusDead}
                        disabledDate={disableFutureDates}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 custom-required text-end">
                    <label htmlFor="">Category</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Category is required.',
                        },
                      ]}
                      name="category"
                    >
                      <SelectInput
                        disabled={isStatusDead}
                        options={categories}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 custom-required text-end">
                    <label htmlFor="">Status</label>
                  </div>
                </div>
                <div className="col-md-8 d-flex justify-content-between ">
                  <div
                    className="custom-form-input-wrap2"
                    style={{ width: !isDeadOrCompleted ? '100%' : '80%' }}
                  >
                    <Form.Item
                      name="status"
                      rules={[
                        {
                          required: true,
                          message: 'Status is required',
                        },
                      ]}
                    >
                      <SelectInput
                        onChange={() => {
                          setShowAddStatusNoteModal(true);
                        }}
                        disabled={disabledStatus}
                        options={
                          lead?.attributes?.status === 'booked' &&
                          lead?.attributes?.revenue > 0
                            ? LEAD_STATUSES.filter(
                                (item, index) =>
                                  index >=
                                  LEAD_STATUSES.findIndex(
                                    item =>
                                      item?.id === lead?.attributes?.status,
                                  ),
                              )
                            : LEAD_STATUSES
                        }
                      />
                    </Form.Item>
                  </div>
                  <div onClick={handleRevert} className="revert-icon mt-2">
                    {isDeadOrCompleted && (
                      <Button className="custom-white-btn">Revert</Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="custom-form-input-wrap2 text-end">
                    <label htmlFor="">Revenue</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-form-input-wrap2">
                    <Form.Item
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      name="revenue"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>
                          {formatMoney(lead?.attributes?.revenue, {
                            precision: 2,
                          })}
                        </h6>
                        <CustomModal
                          title="Add Revenue"
                          isVisible={showRevenueModal}
                          handleCancel={() => setShowRevenueModal(false)}
                        >
                          <NoteForm
                            addRevenue={true}
                            setShowModal={setShowRevenueModal}
                            leadId={id}
                            key={Math.floor(Math.random() * 1000)}
                          />
                        </CustomModal>
                        {isAddRevenue && (
                          <button
                            onClick={e => {
                              e.preventDefault();
                              setShowRevenueModal(true);
                            }}
                            className="custom-white-btn"
                          >
                            Add Revenue
                          </button>
                        )}
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2"></div>

          <div className="notes-wrapper">
            <div className="head-bar">
              <h4>Notes</h4>
              <div>
                <CustomModal
                  title="Add Note"
                  isVisible={showAddNoteModal}
                  handleCancel={() => setShowAddNoteModal(false)}
                >
                  <NoteForm
                    addRevenue={false}
                    setShowModal={setShowAddNoteModal}
                    leadId={id}
                    addNote
                    key={Math.floor(Math.random() * 1000)}
                  />
                </CustomModal>
                <button
                  onClick={e => {
                    e.preventDefault();
                    setShowAddNoteModal(true);
                  }}
                  className="custom-white-btn"
                >
                  Add Notes
                </button>
              </div>
            </div>
          </div>
          <NotesContent userName={user?.first_name} notes={notes} isUpdate />
        </Form>
      </>
    </Spin>
  );
});
