export const LEAD_STATUSES = [
  { id: 'new_lead', name: 'New Lead', className: 'dropdown-option-green' },
  {
    id: 'second_contacted',
    name: '2nd contact',
    className: 'dropdown-option-green',
  },
  {
    id: 'third_contacted',
    name: '3rd contact',
    className: 'dropdown-option-green',
  },
  {
    id: 'fourth_contacted',
    name: '4th contact',
    className: 'dropdown-option-green',
  },
  {
    id: 'fifth_contacted',
    name: '5th contact',
    className: 'dropdown-option-green',
  },
  {
    id: 'final_contact',
    name: 'Final Contact',
    className: 'dropdown-option-green',
  },
  { id: 'booked', name: 'Booked', className: 'dropdown-option-geekblue' },
  { id: 'dead', name: 'Dead', className: 'dropdown-option-red' },
  { id: 'completed', name: 'Completed', className: 'dropdown-option-geekblue' },
];
export const LEAD_STATUS_COLOR = {
  new_lead: { id: 'new_lead', name: 'New Lead', color: '#00722D' },
  second_contacted: {
    id: 'second_contacted',
    name: '2nd contact',
    color: '#91bc3d',
  },
  third_contacted: {
    id: 'third_contacted',
    name: '3rd contact',
    color: '#91bc3d',
  },
  fourth_contacted: {
    id: 'fourth_contacted',
    name: '4th contact',
    color: '#91bc3d',
  },
  fifth_contacted: {
    id: 'fifth_contacted',
    name: '5th contact',
    color: '#91bc3d',
  },
  final_contact: {
    id: 'final_contact',
    name: 'Final Contact',
    color: '#91bc3d',
  },
  booked: { id: 'booked', name: 'Booked', color: '#369DF2' },
  dead: { id: 'dead', name: 'Dead', color: 'red' },
  completed: { id: 'completed', name: 'Completed', color: 'green' },
};

export const PHONE_TYPES = [
  { id: 0, name: 'Home' },
  { id: 1, name: 'Mobile' },
  { id: 2, name: 'Work' },
];

export const USER_LOGIN_STATES = {
  NOT_ACTIVATED: 'not_activated',
  NOT_REGISTERED: 'not_registered',
  NOT_CONFIRMED: 'not_confirmed',
};

export const CURRENT_WEBSITE_CHOICES = [
  { id: 0, name: 'Squarespace' },
  { id: 1, name: 'WordPress' },
  { id: 2, name: 'Showit' },
  { id: 3, name: 'PhotoBiz' },
  { id: 4, name: 'Wix' },
  { id: 5, name: 'Other' },
];
export const COURSES_OWNED_CHOICES = [
  { key: 'Level Up', value: 0 },
  { key: 'Next Level', value: 1 },
  { key: 'The $3000 Headshot', value: 2 },
  { key: 'Wall Art Revolution', value: 3 },
];
export const GENRES_CHOICES = [
  { key: 'Headshots', value: 0 },
  { key: 'Seniors', value: 1 },
  { key: 'Newborn/Maternity', value: 2 },
  { key: 'General', value: 3 },
  { key: 'Boudoir', value: 4 },
  { key: 'Pets', value: 5 },
  { key: 'Weddings', value: 6 },
  { key: 'Other', value: 7 },
];
export const UPDATE_STATUS_OPTIONS = {
  new_lead: [
    { name: 'contacted', value: 'second_contacted' },
    { name: 'booked', value: 'booked' },
    { name: 'dead', value: 'dead' },
  ],
  second_contacted: [
    { name: 'contacted', value: 'third_contacted' },
    { name: 'booked', value: 'booked' },
    { name: 'dead', value: 'dead' },
  ],
  third_contacted: [
    { name: 'contacted', value: 'fourth_contacted' },
    { name: 'booked', value: 'booked' },
    { name: 'dead', value: 'dead' },
  ],
  fourth_contacted: [
    { name: 'contacted', value: 'fifth_contacted' },
    { name: 'booked', value: 'booked' },
    { name: 'dead', value: 'dead' },
  ],
  fifth_contacted: [
    { name: 'contacted', value: 'final_contact' },
    { name: 'booked', value: 'booked' },
    { name: 'dead', value: 'dead' },
  ],
  final_contact: [
    { name: 'booked', value: 'booked' },
    { name: 'dead', value: 'dead' },
  ],
  booked: [
    { name: 'second_contacted', value: 'second_contacted' },
    { name: 'final_Contact', value: 'final_contact' },
    { name: 'completed', value: 'completed' },
    { name: 'cancelled', value: 'dead' },
  ],
};
export const DATE_FORMAT = 'M/DD/YYYY';
export const DATE_RANGE_FORMAT = 'M/dd/yyyy';
export const API_DATE_RANGE_FORMAT = 'yyyy/M/dd';
export const DETAIL_DATE_FORMAT = 'M-DD-YYYY';
export const MISSION_STATEMENT =
  'Payment Successful! Your future monthly payments will be processed on this calendar date. You can change your payment type or subscription status in the profile section. Press any key to proceed to the Mission Control dashboard.';

export const filterButtons = [
  { label: 'Completed', value: 'completed' },
  { label: 'Dead', value: 'dead' },
];

export const Countries = [
  { name: 'United States', id: 'US' },
  { name: 'Canada', id: 'CA' },
  { name: 'Other', id: 'other' },
];

export const STATES = {
  US: [
    {
      name: 'Alabama',
      id: 'AL',
    },
    {
      name: 'Alaska',
      id: 'AK',
    },
    {
      name: 'American Samoa',
      id: 'AS',
    },
    {
      name: 'Arizona',
      id: 'AZ',
    },
    {
      name: 'Arkansas',
      id: 'AR',
    },
    {
      name: 'California',
      id: 'CA',
    },
    {
      name: 'Colorado',
      id: 'CO',
    },
    {
      name: 'Connecticut',
      id: 'CT',
    },
    {
      name: 'Delaware',
      id: 'DE',
    },
    {
      name: 'District Of Columbia',
      id: 'DC',
    },
    {
      name: 'Federated States Of Micronesia',
      id: 'FM',
    },
    {
      name: 'Florida',
      id: 'FL',
    },
    {
      name: 'Georgia',
      id: 'GA',
    },
    {
      name: 'Guam',
      id: 'GU',
    },
    {
      name: 'Hawaii',
      id: 'HI',
    },
    {
      name: 'Idaho',
      id: 'ID',
    },
    {
      name: 'Illinois',
      id: 'IL',
    },
    {
      name: 'Indiana',
      id: 'IN',
    },
    {
      name: 'Iowa',
      id: 'IA',
    },
    {
      name: 'Kansas',
      id: 'KS',
    },
    {
      name: 'Kentucky',
      id: 'KY',
    },
    {
      name: 'Louisiana',
      id: 'LA',
    },
    {
      name: 'Maine',
      id: 'ME',
    },
    {
      name: 'Marshall Islands',
      id: 'MH',
    },
    {
      name: 'Maryland',
      id: 'MD',
    },
    {
      name: 'Massachusetts',
      id: 'MA',
    },
    {
      name: 'Michigan',
      id: 'MI',
    },
    {
      name: 'Minnesota',
      id: 'MN',
    },
    {
      name: 'Mississippi',
      id: 'MS',
    },
    {
      name: 'Missouri',
      id: 'MO',
    },
    {
      name: 'Montana',
      id: 'MT',
    },
    {
      name: 'Nebraska',
      id: 'NE',
    },
    {
      name: 'Nevada',
      id: 'NV',
    },
    {
      name: 'New Hampshire',
      id: 'NH',
    },
    {
      name: 'New Jersey',
      id: 'NJ',
    },
    {
      name: 'New Mexico',
      id: 'NM',
    },
    {
      name: 'New York',
      id: 'NY',
    },
    {
      name: 'North Carolina',
      id: 'NC',
    },
    {
      name: 'North Dakota',
      id: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      id: 'MP',
    },
    {
      name: 'Ohio',
      id: 'OH',
    },
    {
      name: 'Oklahoma',
      id: 'OK',
    },
    {
      name: 'Oregon',
      id: 'OR',
    },
    {
      name: 'Palau',
      id: 'PW',
    },
    {
      name: 'Pennsylvania',
      id: 'PA',
    },
    {
      name: 'Puerto Rico',
      id: 'PR',
    },
    {
      name: 'Rhode Island',
      id: 'RI',
    },
    {
      name: 'South Carolina',
      id: 'SC',
    },
    {
      name: 'South Dakota',
      id: 'SD',
    },
    {
      name: 'Tennessee',
      id: 'TN',
    },
    {
      name: 'Texas',
      id: 'TX',
    },
    {
      name: 'Utah',
      id: 'UT',
    },
    {
      name: 'Vermont',
      id: 'VT',
    },
    {
      name: 'Virgin Islands',
      id: 'VI',
    },
    {
      name: 'Virginia',
      id: 'VA',
    },
    {
      name: 'Washington',
      id: 'WA',
    },
    {
      name: 'West Virginia',
      id: 'WV',
    },
    {
      name: 'Wisconsin',
      id: 'WI',
    },
    {
      name: 'Wyoming',
      id: 'WY',
    },
  ],
  CA: [
    {
      name: 'Alberta',
      id: 'AB',
    },
    {
      name: 'British Columbia',
      id: 'BC',
    },
    {
      name: 'Manitoba',
      id: 'MB',
    },
    {
      name: 'New Brunswick',
      id: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      id: 'NL',
    },
    {
      name: 'Northwest Territories',
      id: 'NT',
    },
    {
      name: 'Nova Scotia',
      id: 'NS',
    },
    {
      name: 'Nunavut',
      id: 'NU',
    },
    {
      name: 'Ontario',
      id: 'ON',
    },
    {
      name: 'Prince Edward Island',
      id: 'PE',
    },
    {
      name: 'Quebec',
      id: 'QC',
    },
    {
      name: 'Saskatchewan',
      id: 'SK',
    },
    {
      name: 'Yukon Territory',
      id: 'YT',
    },
  ],
};
