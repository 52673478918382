export const paymentFormConstants = {
  APPLY_COUPON: 'APPLY_COUPON',
  CLEAR_COUPON_ERROR: 'CLEAR_COUPON_ERROR',
  SET_SUBSCRIPTION_ERROR: 'SET_SUBSCRIPTION_ERROR',
  CLEAR_SUBSCRIPTION_ERROR: 'CLEAR_SUBSCRIPTION_ERROR',
  SUBSCRIBE_USER: 'SUBSCRIBE_USER',
  FETCH_CLIENT_SECRET: 'FETCH_CLIENT_SECRET',
  CLEAR_CLIENT_SECRET: 'CLEAR_CLIENT_SECRET',
  UPDATE_USER_CARD: 'UPDATE_USER_CARD',
  CARD_DECLINE: 'CARD_DECLINE',
};
