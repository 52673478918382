import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.subscriptionPlan || initialState;

export const selectSubscriptionPlans = createSelector(
  [selectSlice],
  state => state.subscriptionPlans,
);
export const selectSubscriptionPlan = createSelector(
  [selectSlice],
  state => state.subscriptionPlan,
);
export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);
