export const subscriptionHistoryConstants = {
  GET_SUBSCRIPTION_HISTORY: 'GET_SUBSCRIPTION_HISTORY',
  GET_SUBSCRIPTION_HISTORY_PAGE_WISE: 'GET_SUBSCRIPTION_HISTORY_PAGE_WISE',
  GET_CURRENT_CARD: 'GET_CURRENT_CARD',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  RENEW_SUBSCRIPTION: 'RENEW_SUBSCRIPTION',
  GET_SUBSCRIPTION_PLAN: 'GET_SUBSCRIPTION_PLAN',
  GET_USER_SUBSCRIPTIONS: 'GET_USER_SUBSCRIPTIONS',
  GET_IMPERSONATED_USER: 'GET_IMPERSONATED_USER',
};
