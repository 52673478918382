import moment from 'moment';
import React from 'react';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import MasterCard from 'images/master-card.png';
import VisaCard from 'images/Visa.png';
import Paypal from 'images/Paypal.png';
import DiscoverLogo from 'images/Discover-logo.png';
import { toTitlecase } from 'utils/StringUtils/stringUtils';

export const getCardBrandIcon = type => {
  switch (type) {
    case 'visa':
      return (
        <img
          style={{ width: '42px', height: '12px' }}
          src={VisaCard}
          alt={`Visa Card`}
        />
      );
    case 'mastercard':
      return (
        <img
          style={{ width: '42px', height: '20px' }}
          src={MasterCard}
          alt={`Master Card`}
        />
      );
    case 'paypal':
      return (
        <img
          style={{ width: '42px', height: '25px' }}
          src={Paypal}
          alt={`Paypal`}
        />
      );
    case 'discover_logo':
      return (
        <img
          style={{ width: '42px', height: '20px' }}
          src={DiscoverLogo}
          alt={`Discover Logo`}
        />
      );
    default:
      return (
        <img
          style={{ width: '62px', height: '25px' }}
          src={''}
          alt={toTitlecase(type)}
        />
      );
  }
};
export const dataTableColoumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: date => date && moment(date).format('M/DD/YYYY hh:mm a'),
    key: 'created_at',
  },
  {
    title: 'Description',
    dataIndex: 'plan_description',
    render: data => data.substring(0, 35) + '...',
    key: 'plan_description',
  },
  {
    title: 'Service Period',
    dataIndex: 'service_period',
    align: 'center',
    render: (date, row) =>
      `${moment(row.from_date).format('M/DD/YYYY hh:mm a') || ''} - ${
        moment(row.to_date).format('M/DD/YYYY hh:mm a') || ''
      }`,
    key: 'service_period',
  },
  {
    title: 'Payment Method',
    dataIndex: 'subscription_type',
    render: (data, row) => {
      return (
        <div>
          <span>
            {getCardBrandIcon(row.card_brand)}
            **** **** **** {row.last4}
          </span>
        </div>
      );
    },
    key: 'subscription_type',
  },
  {
    title: 'Coupon Code',
    align: 'center',
    dataIndex: 'coupon_code',
    key: 'coupon_code',
  },
  {
    title: '',
    align: 'center',
    render: discounted_amount => {
      if (discounted_amount && parseFloat(discounted_amount) !== 0)
        return `(${formatMoney(discounted_amount, { precision: 2 })} off)`;
      return '';
    },
    dataIndex: 'discounted_amount',
    key: 'discounted_amount',
  },
  {
    title: 'Total',
    align: 'center',
    render: (charged_amount, row) =>
      `${formatMoney(charged_amount, { precision: 2 })}`,
    dataIndex: 'charged_amount',
    key: 'charged_amount',
  },
];
