import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { paymentSaga } from './saga';
import { PaymentState } from './types';

export const initialState: PaymentState = {
  coupon: {
    amount: '',
    discounted_amount: '',
    amount_after_discount: '',
    coupon_id: '',
  },
  isLoading: false,
  error: '',
  client_secret: '',
  subscriptionError: '',
  isPaymentSuccessfull: false,
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = '';
    },
    setSubscriptionError(state, action: PayloadAction<string>) {
      state.subscriptionError = action.payload;
    },
    clearSubscriptionError(state) {
      state.subscriptionError = '';
    },
    setSubscribeUserSuccess(state, action: PayloadAction<boolean>) {
      state.isPaymentSuccessfull = action.payload;
    },
    applyCouponSuccess(state, action: PayloadAction<any>) {
      const { coupon } = action.payload;
      state.coupon = coupon.data;
      state.isLoading = false;
    },
    fetchClientKeySuccess(state, action: PayloadAction<any>) {
      const { client_secret } = action.payload;
      state.client_secret = client_secret;
      state.isLoading = false;
    },
    clearClientSecret(state) {
      state.client_secret = '';
    },
    applyCouponFailed(state) {
      state.coupon = {
        amount: '',
        discounted_amount: '',
        amount_after_discount: '',
        coupon_id: '',
      };
      state.isLoading = false;
    },
  },
});

export const { actions: paymentActions } = slice;

export const usePaymentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: paymentSaga });
  return { actions: slice.actions };
};
