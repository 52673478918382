import { initialState } from '.';
import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: RootState) => state.reports || initialState;

export const selectReports = createSelector([selectSlice], state => state);

export const selectisLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);
export const selectgraphData = createSelector(
  [selectSlice],
  state => state.graphData,
);
export const selecttotalData = createSelector(
  [selectSlice],
  state => state.totalStatsData,
);
export const selectfilter = createSelector(
  [selectSlice],
  state => state.filter,
);
export const selectDateRange = createSelector(
  [selectSlice],
  state => state.dateRange,
);
export const selectExportLoading = createSelector(
  [selectSlice],
  state => state.exportLoading,
);
