/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import root app
import { App } from 'app';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import { configureAppStore } from 'store/configureStore';
// Initialize languages
import './locales/i18n';
// import { Elements } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js';
import './style.scss';

// const stripePromise = loadStripe("pk_live_51K4zS8J2r18l1a4QxNdTA92IKWeiQnpx6PQSjVCARU5CoAJVKKdvKdBblhh9nzVQJEwjSdIgVPYRd73kXPQo2TaF00W9pgBHt2");
const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    {/* <Elements stripe={stripePromise}> */}
    <React.StrictMode>
      <App />
    </React.StrictMode>
    {/* </Elements> */}
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
