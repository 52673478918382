import React from 'react';
import { Checkbox } from 'antd';
import { plainOptions } from 'utils';

const CheckboxGroup = Checkbox.Group;

const CustomCheckBoxes = ({
  checkedList,
  setCheckedList,
  indeterminate,
  setIndeterminate,
  checkAll,
  setCheckAll,
}) => {
  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div className="custom-list-checkbox d-flex justify-content-center">
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        All
      </Checkbox>
      <CheckboxGroup
        options={plainOptions}
        value={checkedList}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomCheckBoxes;
