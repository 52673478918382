import { DATE_RANGE_FORMAT } from 'utils/constants/constants';
import { endOfDay, format, isSameDay, startOfYear, subMonths } from 'date-fns';
export const renderStaticLabel = dateRange => {
  let label =
    format(dateRange.date[0].startDate, DATE_RANGE_FORMAT) +
    ' - ' +
    format(dateRange.date[0].endDate, DATE_RANGE_FORMAT);
  if (
    format(dateRange.date[0]?.startDate, DATE_RANGE_FORMAT) ===
      format(
        endOfDay(new Date().setDate(new Date().getDate() - 29)),
        DATE_RANGE_FORMAT,
      ) &&
    format(dateRange.date[0]?.endDate, DATE_RANGE_FORMAT) ===
      format(endOfDay(new Date()), DATE_RANGE_FORMAT)
  ) {
    label = 'Last 30 Days';
    return label;
  } else if (
    format(dateRange.date[0]?.startDate, DATE_RANGE_FORMAT) ===
      format(subMonths(new Date(), 3), DATE_RANGE_FORMAT) &&
    format(dateRange.date[0]?.endDate, DATE_RANGE_FORMAT) ===
      format(endOfDay(new Date()), DATE_RANGE_FORMAT)
  ) {
    label = 'Last 3 Months';
    return label;
  } else if (
    format(dateRange.date[0]?.startDate, DATE_RANGE_FORMAT) ===
      format(startOfYear(new Date()), DATE_RANGE_FORMAT) &&
    format(dateRange.date[0]?.endDate, DATE_RANGE_FORMAT) ===
      format(endOfDay(new Date()), DATE_RANGE_FORMAT)
  ) {
    label = 'This Year';
    return label;
  }
  return label;
};

export const customDefaultRanges = [
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 29),
      endDate: endOfDay(new Date()),
    }),
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
  {
    label: 'Last 3 Months',
    range: () => ({
      startDate: subMonths(new Date(), 3),
      endDate: endOfDay(new Date()),
    }),
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfDay(new Date()),
    }),
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
];
