export const leadsContants = {
  FETCH_LEADS: 'FETCH_LEADS',
  CLEAR_LEADS: 'CLEAR_LEADS',
  FETCH_LEADS_PAGE_WISE: 'FETCH_LEADS_PAGE_WISE',
  POST_LEAD: 'POST_LEAD',
  FETCH_USER_CATEGORY: 'FETCH_USER_CATEGORY',
  UPDATE_STATUS_LEAD: 'UPDATE_STATUS_LEAD',
  DELETE_LEAD: 'HomePage/DELETE_LEAD',
  ADD_LEAD_REVENUE: 'HomePage/ADD_LEAD_REVENUE',
  FETCH_CONTACT_TIMING: 'FETCH_CONTACT_TIMING',
};
