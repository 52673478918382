/**
 *
 * Table
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { Table, Empty } from 'antd';
import { Paginator } from '../Paginator';
import PropTypes, { object } from 'prop-types';
import './DataTable.scss';

const DataTable = ({
  columns,
  data,
  pagination = false,
  onRowClick,
  paginationHandler,
  meta,
  currentPageNo = 1,
  onChange,
}) => {
  return (
    <Div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={onChange}
        sortDirections={['descend', 'ascend', 'descend']}
        locale={{
          emptyText: (
            <Empty
              style={{
                marginTop: '25%',
              }}
              description="No leads yet!"
            />
          ),
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event: React.MouseEvent) => {
              onRowClick && onRowClick(record, event);
            }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {}, // mouse leave row
          };
        }}
      />
      {pagination && (
        <div className="mt-5">
          <Paginator
            total={meta?.total_count ?? 0}
            defaultCurrent={currentPageNo}
            onchange={(page, pageSize) => paginationHandler(page, pageSize)}
          />
        </div>
      )}
    </Div>
  );
};

const Div = styled.div``;

export default DataTable;

DataTable.defaultProps = {};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(object),
  pagination: PropTypes.bool,
  onRowClick: PropTypes.func,
  paginationHandler: () => 0,
  onChange: () => 0,
};
