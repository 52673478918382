import { message } from 'antd';
import { profileActions as actions } from '.';
import { profileConstants } from './constants';
import { postReq, putReq, deleteReq } from 'config/Requests';
import { handleError } from '../../../../../src/utils';
import { getAuth } from '../../../../../src/utils/Auth';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectisImpersonate, selectuserId } from 'app/slice/selectors';
import { signInConstants } from './../../SignIn/slice/constants';
function* updateProfileInfo(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setLoading(true));
    yield call(
      putReq,
      `/users/${obj?.payload?.id}`,
      { user: obj?.payload },
      auth,
    );
    yield put({
      type: signInConstants.FETCH_USER,
      callback: response => null,
    });
    message.success('Profile Information Updated Successfully');
    yield put(actions.setLoading(false));
  } catch (error) {
    handleError(error);
    yield put(actions.setLoading(false));
  }
}
function* changePassword(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);

  const { payload, callback } = obj;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(postReq, '/auth/password', payload, auth);
    callback(response);
    yield put(actions.changePasswordSuccess({ severity: response.severity }));
  } catch (error) {
    handleError(error);
    yield put(actions.changePasswordFailed({ severity: 'faliure' }));
  }
}

function* deleteProfilePicture(obj) {
  const isimpersonate = yield select(selectisImpersonate);
  const userId = yield select(selectuserId);
  let auth = getAuth(isimpersonate, userId);
  try {
    yield put(actions.setLoading(true));
    yield call(deleteReq, `users/${obj?.payload?.id}/remove_photo`, null, auth);
    yield put({
      type: signInConstants.FETCH_USER,
      callback: response => null,
    });
    message.success('Profile Picture Deleted Successfully');
  } catch (error) {
    handleError(error);
    yield put(actions.changePasswordFailed({ severity: 'faliure' }));
  }
}

export function* profileSaga() {
  yield takeLatest(profileConstants.UPDATE_PROFILE_INFO, updateProfileInfo);
  yield takeLatest(profileConstants.CHANGE_PASSWORD, changePassword);
  yield takeLatest(
    profileConstants.DELETE_PROFILE_PICTURE,
    deleteProfilePicture,
  );
}
