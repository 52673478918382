/**
 *
 * AppHeader
 *
 */
import './AppHeader.scss';
import Cookies from 'js-cookie';
import { Avatar, message } from 'antd';
import { RedirectCheck } from 'utils/Auth';
import { FaAngleDown } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import { constants } from 'app/slice/constants';
import Logo from '../../../../../images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useSignInSlice } from 'app/pages/SignIn/slice';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
import { leadsContants } from 'app/pages/HomePage/slice/constants';
import { signInConstants } from 'app/pages/SignIn/slice/constants';
import {
  selectisImpersonate,
  selectpath,
  selectuserId,
} from 'app/slice/selectors';

interface Props {}
const AppHeader = memo((props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useSignInSlice();
  const user = useSelector(selectUser);
  const userId = useSelector(selectuserId);
  const path = useSelector(selectpath);
  const isImpersonate = useSelector(selectisImpersonate);
  const partnerUserIds = process.env.REACT_APP_PARTNER_USER_IDS;
  const isFreeForLife = partnerUserIds?.split(',').includes(userId);
  const SignOut = () => {
    dispatch({
      type: signInConstants.SIGN_OUT_USER,
      callback: response => {
        if (response.status === 200) {
          if (isImpersonate) {
            Cookies.remove(`${userId}`);
            dispatch({ type: constants.CLEAR_VARIABLES });
          } else {
            Cookies.remove('authToken');
            
          }
          dispatch({
            type: leadsContants.CLEAR_LEADS,
          });
          message.success('User has been logged out');
          history.push('/sign-in');
        }
      },
    });
  };
  useEffect(() => {
    const authToken = Cookies.get('authToken');
    if (isImpersonate) {
      dispatch({
        type: signInConstants.FETCH_USER,
        callback: response => null,
      });
    } else {
      if (authToken) {
        dispatch({
          type: signInConstants.FETCH_USER,
          callback: response => null,
        });
      }
    }
  }, [dispatch, isImpersonate]);
  const isSubscribed = user?.subscribed;
  return (
    <div className="header">
      <div className="logo">
        <img
          src={Logo}
          alt=""
          onClick={() => {
            RedirectCheck(isImpersonate, path, '/', history);
          }}
        />
      </div>
      <div className="nav-wrapper">
        <ul className="nav justify-content-end">
          {!isSubscribed && !isImpersonate ? null : (
            <>
              {' '}
              <div className="header-nav-links-wrapper d-flex">
                <li className="nav-item">
                  <li
                    className="nav-link active"
                    aria-current="page"
                    onClick={() =>
                      RedirectCheck(
                        isImpersonate,
                        `/impersonate-reports/${userId}`,
                        '/reports/',
                        history,
                      )
                    }
                  >
                    Reports
                  </li>
                </li>
                <li
                  className="nav-item"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    RedirectCheck(
                      isImpersonate,
                      `/impersonate-archived-leads/${userId}`,
                      '/archived-leads/',
                      history,
                    )
                  }
                >
                  Archives
                </li>
              </div>
            </>
          )}
          <li className="nav-item ps-4">
            {isImpersonate ? (
              <>
                {' '}
                <div className="user-dropdown">
                  <Avatar
                    src={user.image_url ? user.image_url : ''}
                    className="img-cover"
                  >
                    A
                  </Avatar>
                  <li className="user-dropdown .user-dropdown-wrapper">
                    <b className="ps-2 nav-item">Admin </b>
                    <span>
                      <FaAngleDown />
                    </span>
                  </li>
                  <div className="user-dropdown-wrapper">
                    <ul className="user-dropdown-list">
                      <li className="custom-li-first">
                        <b>{user?.first_name + ' ' + user?.last_name}</b>
                      </li>
                      <li
                        className="custom-li"
                        onClick={() =>
                          RedirectCheck(
                            isImpersonate,
                            `/impersonate/user-profile/${userId}`,
                            `/user-profile/${user?.id}`,
                            history,
                          )
                        }
                      >
                        My Profile
                      </li>
                      <li
                        className="custom-li hider"
                        onClick={() =>
                          RedirectCheck(
                            isImpersonate,
                            `/impersonate-reports/${userId}`,
                            '/reports/',
                            history,
                          )
                        }
                      >
                        Reports
                      </li>
                      <li
                        className="custom-li hider"
                        onClick={() =>
                          RedirectCheck(
                            isImpersonate,
                            `/impersonate-archived-leads/${userId}`,
                            '/archived-leads/',
                            history,
                          )
                        }
                      >
                        Archives
                      </li>
                      <li
                        className="custom-li"
                        onClick={() =>
                          history.push(`/subscription-history/${userId}`)
                        }
                      >
                        {' '}
                        Subscription History
                      </li>
                      <li className="custom-li" onClick={SignOut}>
                        {' '}
                        Sign Out
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <div className="user-dropdown">
                <div>
                  <Avatar
                    src={user.image_url ? user.image_url : ''}
                    className="img-cover"
                  >
                    {user?.first_name ? user?.first_name.charAt(0) : ''}
                  </Avatar>
                </div>
                <span>
                  <FaAngleDown />
                </span>
                <div className="user-dropdown-wrapper">
                  <ul className="user-dropdown-list">
                    <li
                      style={{
                        borderBottom: '1px solid #9ea09f9d',
                        pointerEvents: 'none',
                      }}
                    >
                      <b style={{ fontSize: '1.2rem' }}>
                        {user?.first_name + ' ' + user?.last_name}
                      </b>
                    </li>
                    {!isSubscribed ? null : (
                      <>
                        {' '}
                        <li
                          className="custom-li"
                          onClick={() =>
                            history.push(`/user-profile/${user?.id}`)
                          }
                        >
                          My Profile
                        </li>
                        <li
                          className="custom-li hider"
                          onClick={() => history.push(`/reports`)}
                        >
                          Reports
                        </li>
                        <li
                          className="custom-li hider"
                          onClick={() =>
                            RedirectCheck(
                              isImpersonate,
                              `/impersonate-archived-leads/${userId}`,
                              '/archived-leads/',
                              history,
                            )
                          }
                        >
                          Archives
                        </li>
                        <li
                          onClick={() => {
                            if (!isFreeForLife) {
                              history.push(`/subscription-history/${userId}`);
                            }
                          }}
                          className="custom-li"
                          style={{
                            color: isFreeForLife ? 'gray' : 'inherit',
                            pointerEvents: isFreeForLife ? 'none' : 'auto',
                          }}
                        >
                          Subscription History
                        </li>
                      </>
                    )}
                    <li onClick={SignOut} className="custom-li">
                      {' '}
                      Sign Out
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
});

export default AppHeader;
