import Cookies from 'js-cookie';
import { paymentActions as actions } from '.';
import { postReq, getReq, putReq } from 'config/Requests';
import { paymentFormConstants } from './constants';
import { AxiosError } from 'axios';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { selectUser } from 'app/pages/SignIn/slice/selectors';
import { message } from 'antd';
function* applyCoupon(obj) {
  const auth = Cookies.get('authToken');
  const { coupon_code, plan_id } = obj?.payload?.coupon;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(
      getReq,
      `/coupons/coupon_code_discount?coupon_code=${coupon_code}&plan_id=${plan_id}`,
      auth,
    );
    message.success('Coupon applied successfully.');
    yield put(
      actions.applyCouponSuccess({
        coupon: response.data,
      }),
    );
    put(actions.clearError());
  } catch (error) {
    const errorResponse = error as AxiosError;
    yield put(actions.setError(errorResponse?.response?.data?.message));
    yield put(actions.applyCouponFailed());
  }
}

function* fetchClientSecret(obj) {
  const auth = Cookies.get('authToken');
  const { callback } = obj;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(getReq, `stripe/get_client_secret`, auth);
    yield put(
      actions.fetchClientKeySuccess({
        client_secret: response.data.client_secret,
      }),
    );
    yield put(actions.setLoading(false));
    yield put(actions.clearSubscriptionError());
    if (callback) callback();
  } catch (error) {
    const errorResponse = error as AxiosError;
    yield put(
      actions.setSubscriptionError(errorResponse?.response?.data?.message),
    );
    yield put(actions.applyCouponFailed());
  }
}

function* subscribeUser(obj) {
  const auth = Cookies.get('authToken');
  const { payload, callback } = obj;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(postReq, `/user_subscriptions`, payload, auth);
    yield put(actions.setLoading(false));
    put(actions.clearError());
    put(actions.clearSubscriptionError());
    callback(response);
  } catch (error) {
    const errorResponse = error as AxiosError;
    yield put(
      actions.setSubscriptionError(errorResponse?.response?.data?.message),
    );
    yield put(actions.applyCouponFailed());
  }
}
function* updateUserCard(obj) {
  const auth = Cookies.get('authToken');
  const { payload, callback } = obj;
  try {
    yield put(actions.setLoading(true));
    const response = yield call(
      putReq,
      `/cards/update_user_card`,
      payload,
      auth,
    );
    put(actions.clearError());
    put(actions.clearSubscriptionError());
    if (callback) callback(response);
  } catch (error) {
    const errorResponse = error as AxiosError;
    yield put(
      actions.setSubscriptionError(errorResponse?.response?.data?.message),
    );
    yield put(actions.applyCouponFailed());
  }
}

function* clearCouponError() {
  yield put(actions.clearError());
}
function* clearSubscriptionError() {
  yield put(actions.clearSubscriptionError());
}

function* setSubscriptionError(obj) {
  const { payload } = obj;
  yield put(actions.setSubscriptionError(payload));
}
function* clearClientSecret() {
  yield put(actions.clearClientSecret());
}
function* cardDecline(obj) {
  const user = yield select(selectUser);
  const userId = user.id;
  const auth = Cookies.get('authToken');
  yield call(postReq, `users/${userId}/card_declined`, null, auth);
}

export function* paymentSaga() {
  yield takeLatest(paymentFormConstants.APPLY_COUPON, applyCoupon);
  yield takeLatest(paymentFormConstants.CLEAR_COUPON_ERROR, clearCouponError);
  yield takeLatest(paymentFormConstants.CLEAR_CLIENT_SECRET, clearClientSecret);
  yield takeLatest(
    paymentFormConstants.SET_SUBSCRIPTION_ERROR,
    setSubscriptionError,
  );
  yield takeLatest(
    paymentFormConstants.CLEAR_SUBSCRIPTION_ERROR,
    clearSubscriptionError,
  );
  yield takeLatest(paymentFormConstants.SUBSCRIBE_USER, subscribeUser);
  yield takeLatest(paymentFormConstants.FETCH_CLIENT_SECRET, fetchClientSecret);
  yield takeLatest(paymentFormConstants.UPDATE_USER_CARD, updateUserCard);
  yield takeLatest(paymentFormConstants.CARD_DECLINE, cardDecline);
}
