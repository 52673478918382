/**
 *
 * PaymentForm
 *
 */
import React, { memo, useEffect, useState, useRef, useMemo } from 'react';
import { Form, Button, Spin } from 'antd';
import { Input, message } from 'antd';
import { Radio } from 'antd';
import MasterCard from '../../../images/master-card.png';
import VisaCard from '../../../images/Visa.png';
import DiscoverLogo from '../../../images/Discover-logo.png';
import AmericanExpress from '../../../images/American_Express.png';
import { selectSubscriptionPlan } from '../SubscriptionPlan/slice/selectors';
import { useHistory } from 'react-router-dom';
import { useSubscriptionPlanSlice } from '../SubscriptionPlan/slice';
import { subscriptionPlanConstants } from '../SubscriptionPlan/slice/constants';
import { useSelector, useDispatch } from 'react-redux';
import { paymentFormConstants } from './slice/constants';

import {
  selectCoupon,
  selectError,
  selectClientSecret,
  selectIsLoading,
} from './slice/selectors';
import { signInConstants } from 'app/pages/SignIn/slice/constants';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import { usePaymentSlice } from './slice';
import './PaymentForm.scss';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { paymentActions } from './slice';
import { selectPlan } from '../SubscriptionHistory/slice/selectors';
interface Props {}

export const PaymentForm = memo((props: Props) => {
  useSubscriptionPlanSlice();
  usePaymentSlice();
  const [form] = Form.useForm();
  const [couponForm] = Form.useForm();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const history = useHistory<any>();
  const couponError = useSelector(selectError);
  const planAmount = useSelector(selectPlan);
  const [initialAmount, setInitialAmount] = useState(0);
  const coupon = useSelector(selectCoupon);
  const clientSecret = useSelector(selectClientSecret);
  const isLoading = useSelector(selectIsLoading);
  const subscriptionPlan = useSelector(selectSubscriptionPlan);
  const [stripeLoading, setStripeLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [fetchStripeClientSecret, setFetchStripeClientSecret] =
    useState<boolean>(true);
  const subscriptionPlanId = history?.location?.state?.subscriptionPlanId ?? '';
  const appearance: any = useMemo(() => {
    return {
      theme: 'stripe',
    };
  }, []);

  // Pass the appearance object to the Elements instance
  let elements = useRef<StripeElements | undefined>(undefined);
  useEffect(() => {
    const loaderBlock = document.getElementById('loader');
    if (clientSecret.length > 0) {
      elements.current = stripe?.elements({
        clientSecret: clientSecret,
        appearance: appearance,
        loader: 'auto',
      });
      const paymentElement = elements?.current?.create('payment');
      paymentElement?.mount('#payment-element');
      document.getElementById('payment-element')!.style.display = 'block';
      loaderBlock!.style.display = 'none';
    } else if (clientSecret.length === 0) {
      loaderBlock!.style.display = 'flex';
    }
    setStripeLoading(false);
  }, [clientSecret, appearance, stripe]);

  useEffect(() => {
    form.setFieldsValue({
      subscription_type: 0,
      card_holder: '',
      card_number: '',
      card_expiry: '',
      cvc: '',
      discount_code: '',
    });
    return () => {
      dispatch({
        type: paymentFormConstants.CLEAR_COUPON_ERROR,
      });
    };
  }, [form, dispatch]);

  useEffect(() => {
    if (fetchStripeClientSecret) {
      dispatch({
        type: paymentFormConstants.FETCH_CLIENT_SECRET,
        callback: () => setStripeLoading(false),
      });
      return () => {
        dispatch({
          type: paymentFormConstants.CLEAR_CLIENT_SECRET,
        });
      };
    }
    setFetchStripeClientSecret(false);
  }, [fetchStripeClientSecret, dispatch]);

  useEffect(() => {
    if (subscriptionPlanId) {
      dispatch({
        type: subscriptionPlanConstants.FETCH_SUBSCRIPTION_PLAN_BY_ID,
        payload: subscriptionPlanId,
      });
    }
  }, [dispatch, subscriptionPlanId]);
  useEffect(() => {
    if (subscriptionPlan) {
      setInitialAmount(subscriptionPlan?.attributes?.initial_amount);
    }
  }, [subscriptionPlan]);
  const subscribeUser = intentKey => {
    const attrs = {
      user_subscription: {
        coupon_id: coupon.coupon_id.length > 0 ? coupon.coupon_id : null,
        plan_id: subscriptionPlanId,
        setup_intent_id: intentKey,
      },
    };
    dispatch({
      type: paymentFormConstants.SUBSCRIBE_USER,
      payload: attrs,
      callback: response => {
        if (response.status === 200) {
          dispatch({
            type: signInConstants.FETCH_USER,
            callback: response => null,
          });
          dispatch(paymentActions.setSubscribeUserSuccess(true));
          history.push('/');
        }
      },
    });
  };

  const onStripeCardSubmit = async event => {
    setStripeLoading(true);
    event.preventDefault();
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements: { ...elements?.current } as StripeElements,
      confirmParams: {
        return_url: 'https://portal.liftoffleads.com/new-payment-method',
      },
      redirect: 'if_required',
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      message.error(error.message);
      dispatch({
        type: paymentFormConstants.CARD_DECLINE,
      });
      setErrorMessage(error.message);
    } else {
      setErrorMessage('');
      if (setupIntent?.status === 'succeeded') {
        subscribeUser(setupIntent?.id);
        message.success('Card added successfully.');
      }
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setStripeLoading(false);
  };

  const couponFormFinish = event => {
    dispatch({
      type: paymentFormConstants.APPLY_COUPON,
      payload: {
        coupon: {
          coupon_code: event.discount_code,
          plan_id: subscriptionPlanId,
        },
      },
    });
    return event.preventDefault;
  };
  // const onPaymentTypeChange = e => {
  //   if (e.target.value === 0) {
  //     setFetchStripeClientSecret(true);
  //   }
  //   form.setFieldsValue({
  //     subscription_type: e.target.value,
  //   });
  // };

  const DiscountMenu = () => {
    if (coupon?.discounted_amount) {
      return (
        <>
          {' '}
          <div className="detail-box">
            <div className="title">Discount:</div>
            <div className="amount">
              -
              {formatMoney(coupon?.discounted_amount ?? 0, {
                precision: 2,
              })}
            </div>
          </div>
          <div className="detail-box total">
            <div className="title">Total</div>
            <div className="amount">
              {formatMoney(
                coupon?.amount_after_discount
                  ? coupon?.amount_after_discount
                  : planAmount / 100,
                { precision: 2 },
              )}
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Spin
      spinning={stripeLoading === true ? stripeLoading : isLoading ?? false}
      size="large"
      tip="Loading...."
    >
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <Form form={form}>
                <Form.Item
                  name="subscription_type"
                  rules={[
                    {
                      required: true,
                      message: 'Payment type required',
                    },
                  ]}
                >
                  <Radio.Group value={form.getFieldValue('subscription_type')}>
                    <Radio value={0}>
                      Credit or Debit card
                      <span className="img-wrapper">
                        <img src={MasterCard} alt="Master card" />
                        <img src={VisaCard} alt="visa card" />
                        <img src={DiscoverLogo} alt="discover logo" />
                        <img src={AmericanExpress} alt="american express" />
                      </span>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
              <form onSubmit={onStripeCardSubmit}>
                <div
                  style={{ minHeight: '350px', display: 'none' }}
                  id="payment-element"
                ></div>
                <div
                  style={{
                    minHeight: '350px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'none',
                  }}
                  id="loader"
                >
                  <Spin spinning={true} size="large" tip="Loading...."></Spin>
                </div>
                <button className="custom-green-btn  align-center pay-now-btn">
                  Pay Now{' '}
                  {formatMoney(
                    coupon?.amount_after_discount
                      ? coupon?.amount_after_discount
                      : initialAmount > 1
                      ? initialAmount
                      : subscriptionPlan?.attributes?.amount,
                    { precision: 2 },
                  )}
                </button>
                {/* Show error message to your customers */}
                {errorMessage && (
                  <div className="mt-2" style={{ color: 'red' }}>
                    {errorMessage}
                  </div>
                )}
              </form>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <Form form={couponForm} onFinish={couponFormFinish}>
                <p>Payment Summary</p>
                <div className="payment-summary-wrap">
                  <div className="detail-box">
                    {initialAmount > 0 ? (
                      <div className="row">
                        <div className="row">
                          <div className=" title ">Account Setup</div>
                        </div>
                        <div className="row">
                          <div className="  title">
                            (Monthly charge of $1,495 begins after 60 days)
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="title">Monthly Subscription</div>
                    )}

                    <div className="amount">
                      {formatMoney(
                        initialAmount > 0
                          ? initialAmount
                          : subscriptionPlan?.attributes?.amount ?? 0,
                        {
                          precision: 2,
                        },
                      )}
                    </div>
                  </div>
                  {DiscountMenu()}
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="custom-form-input-wrap2 my-4">
                      <label htmlFor="">Discount Code</label>
                      <Form.Item
                        name="discount_code"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value?.length >= 1) return Promise.resolve();
                              else
                                return Promise.reject(
                                  new Error('Invalid coupon length.'),
                                );
                            },
                          }),
                        ]}
                      >
                        <Input
                          onChange={e => {
                            if (couponError.length > 0)
                              dispatch({
                                type: paymentFormConstants.CLEAR_COUPON_ERROR,
                              });
                          }}
                          type={'string'}
                        />
                      </Form.Item>
                      <div className="text-danger">{couponError}</div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-end py-4 submit-height">
                    <Button
                      htmlType="submit"
                      className="custom-green-btn px-5 w-100"
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    </Spin>
  );
});
