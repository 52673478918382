import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { subscriptionPlanSaga } from './saga';
import { SubscriptionPlanState } from './types';

export const initialState: SubscriptionPlanState = {
  isLoading: false,
  subscriptionPlans: [],
  subscriptionPlan: {
    attributes: {
      amount: '',
      description: '',
      price_id: '',
      title: '',
      initial_amount: 0,
      stripe_plan_ids: [],
    },
    id: '',
    type: '',
  },
  error: '',
};

const slice = createSlice({
  name: 'subscriptionPlan',
  initialState,
  reducers: {
    setLoadingTrue(state) {
      state.isLoading = true;
    },
    setLoadingFalse(state) {
      state.isLoading = false;
    },
    fetchSubscriptionPlansSuccess(state, action: PayloadAction<any>) {
      const { subscriptionPlans } = action.payload;
      state.subscriptionPlans = subscriptionPlans.data;
    },
    fetchSubscriptionPlanSuccess(state, action: PayloadAction<any>) {
      const { subscriptionPlan } = action.payload;
      state.subscriptionPlan = subscriptionPlan.data;
    },
    fetchSubscriptionPlansFailed(state) {
      state.subscriptionPlan = {
        attributes: {
          amount: '',
          description: '',
          price_id: '',
          title: '',
          initial_amount: 0,
          stripe_plan_ids: [],
        },
        id: '',
        type: '',
      };
    },
    fetchSubscriptionPlanFailed(state) {
      state.subscriptionPlan = {
        attributes: {
          amount: '',
          description: '',
          price_id: '',
          initial_amount: 0,
          title: '',
          stripe_plan_ids: [],
        },
        id: '',
        type: '',
      };
    },
  },
});

export const { actions: subscriptionPlanActions } = slice;

export const useSubscriptionPlanSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: subscriptionPlanSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSubscriptionPlanSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
